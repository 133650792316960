import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Joi from 'joi';
import { CopyIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { isValidCron } from 'cron-validator';
import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';
import _startCase from 'lodash/startCase';
import _camelCase from 'lodash/camelCase';
import { cloneDeep, isEmpty } from 'lodash';
import { Flex } from '@chakra-ui/react';
import CommunicationTemplateCommonTable from '../CommunicationTemplates/CommunicationTemplateCommonTable';
import { checkForV2 } from '../../utils/helper';
import {
  ACTION_TYPE_VALUE,
  MANUAL_TRIGGERS,
  MANUAL_TRIGGERS_V1,
  MANUAL_TRIGGER_TYPES,
  NOTIFY_ON_TYPES_VALUE,
  TIME_TRIGGERS,
  TIME_TRIGGER_TYPES,
  TRIGGER_TYPES,
  approvalOptionsValues,
} from '../DynamicRenderer/customEventAndTriggers/customEventAndTriggers.constant';
import CustomSwitch from '../../components/Switch/Switch';
import axiosInstance from '../../utils/axios/axios';
import { getHierarchyById } from '../../components/dynamicExpressionWithTags/dynamicExpressionWithTags.helper';
import { showToastMessage } from '../TicketTemplateBuilder/utils/templateAPIUtils';
import {
  convertImgDataToSrc,
  parseCustomMentionToAPI,
  parseRichTextMentionToAPI,
} from '../CommunicationTemplates/utills';
import { removeDuplicateElement } from '../TicketMetaFields/LinkedMetaFieldDrawer/utils';

export const eventColumns = ({ onEdit, onDelete, onClone, tabType }) => [
  {
    col_name: 'event_name',
    type: 'string',
  },
  {
    col_name: 'trigger',
    type: 'string',
    cellRenderer: ({ rowData }) => _startCase(_camelCase(rowData?.trigger)),
  },
  {
    col_name: 'created_by',
    type: 'string',
  },
  {
    col_name: 'last_edit_by',
    type: 'string',
  },
  {
    col_name: 'actions',
    type: 'actions',
    cellRenderer: ({ rowData }) => (
      <div className="flex gap-[10px] justify-center">
        <EditIcon
          className="cursor-pointer"
          color="#94A3B8"
          onClick={() => onEdit(rowData, tabType)}
        />
        <DeleteIcon
          className="cursor-pointer"
          color="#94A3B8"
          onClick={() => onDelete(rowData, tabType)}
        />
        <CopyIcon
          className="cursor-pointer"
          color="#94A3B8"
          onClick={() => onClone(rowData, tabType)}
        />
      </div>
    ),
  },
];

const stringRequiredValidationWithKey = (key) =>
  Joi.string()
    .required()
    .messages({
      'string.empty': `${key} is required`,
      'any.required': `${key} is required`,
    });
const numberRequiredValidationWithKey = (key, min = 1, max = 99) =>
  Joi.number()
    .min(min)
    .max(max)
    .required()
    .messages({
      'number.base': `${key} must be a number`,
      'any.required': `${key} is required`,
      'number.min': `${key} must be at least ${min}`,
      'number.max': `${key} must be at most ${max}`,
    });

export const eventActionTabList = [
  {
    id: 'event_templates',
    title: 'Events',
    content: <CommunicationTemplateCommonTable />,
    tabType: 'EVENT',
  },
  {
    id: 'action_responses',
    title: 'Actions',
    content: <CommunicationTemplateCommonTable />,
    tabType: 'ACTION',
  },
];
export const getTriggerEvent = (value) => {
  if (!checkForV2()) {
    return MANUAL_TRIGGERS_V1;
  }
  if (value === TRIGGER_TYPES[0].value) {
    return MANUAL_TRIGGERS.filter((item) => item.value !== 'actor_assigned');
  }
  return TIME_TRIGGERS;
};

export const isCronValid = (value) =>
  isValidCron(value, { seconds: true, allowBlankDay: true });

export const TemplateEventValidations = Joi.object({
  event_name: Joi.string().required().messages({
    'string.empty': 'Event name is required',
    'any.required': 'Event name is required.',
  }),
  description: Joi.string().required().messages({
    'string.empty': 'Description is required',
    'any.required': 'Description is required.',
  }),
  trigger: Joi.string().required().messages({
    'string.empty': 'Trigger is required',
    'any.required': 'Trigger is required.',
  }),
  trigger_type: Joi.string().required().messages({
    'string.empty': 'Trigger type is required',
    'any.required': 'Trigger type is required.',
  }),
  field: Joi.alternatives().conditional('trigger', {
    is: MANUAL_TRIGGER_TYPES.FIELD_CHANGE,
    then: Joi.array().items(
      Joi.object({
        label: Joi.string().required(),
        value: Joi.string().required(),
      })
    ).required(),
    otherwise: Joi.any(),
  }),
  transition_name: Joi.alternatives().conditional('trigger', {
    is: MANUAL_TRIGGER_TYPES.ISSUE_TRANSITIONED,
    then: Joi.array().min(1).items(Joi.string()).messages({
      'string.empty': 'Transition name is required',
      'any.required': 'Transition name is required.',
    }), // stringRequiredValidationWithKey('Transition name'),
    otherwise: Joi.any(),
  }),
  event: Joi.alternatives().conditional('trigger', {
    is: TIME_TRIGGER_TYPES.RELATIVE_ITEM,
    then: Joi.string().allow('', null),
    otherwise: Joi.any(),
  }),
  amount_of_time: Joi.alternatives().conditional('trigger', {
    is: TIME_TRIGGER_TYPES.RELATIVE_ITEM,
    then: numberRequiredValidationWithKey('Amount of time', 1, 100000),
    otherwise: Joi.any(),
  }),
  start_time: Joi.alternatives().conditional('trigger', {
    is: TIME_TRIGGER_TYPES.SPECIFIC_HOURS_OF_DAY,
    then: stringRequiredValidationWithKey('Start time'),
    otherwise: Joi.any(),
  }),
  actor_field: Joi.alternatives().conditional('trigger', {
    is: MANUAL_TRIGGER_TYPES.ACTOR_ASSIGNED,
    then: stringRequiredValidationWithKey('Actor field'),
    otherwise: Joi.any(),
  }),
  assignment_strategy: Joi.alternatives().conditional('trigger', {
    is: MANUAL_TRIGGER_TYPES.ACTOR_ASSIGNED,
    then: stringRequiredValidationWithKey('Assignment Strategy'),
    otherwise: Joi.any(),
  }),
  cron: Joi.alternatives().conditional('trigger', {
    is: TIME_TRIGGER_TYPES.CRON,
    then: stringRequiredValidationWithKey('Cron expression').custom(
      (value, helpers) => {
        if (!isCronValid(value)) {
          return helpers.error('any.invalid', {
            message: 'Cron expression must have a valid value',
          });
        }
        return value;
      },
      'uniqueName'
    ),
    otherwise: Joi.any(),
  }),
  sla: Joi.alternatives().conditional('trigger', {
    is: Joi.string().valid(
      TIME_TRIGGER_TYPES.SLA,
      TIME_TRIGGER_TYPES.SLA_RELATIVE_TIME
    ),
    then: Joi.object({
      label: Joi.string().required(),
      value: Joi.string().required(),
    })
      .required()
      .messages({
        'any.required': 'SLA is required',
      }),
    otherwise: Joi.any(),
  }),
  metricPercentage: Joi.alternatives().conditional('trigger', {
    is: TIME_TRIGGER_TYPES.SLA_RELATIVE_TIME,
    then: numberRequiredValidationWithKey('Metric percentage'),
    otherwise: Joi.any(),
  }),
  ticket_conditions: Joi.alternatives().conditional('trigger_type', {
    is: 'time_triggers',
    then: Joi.allow(null),
    otherwise: Joi.object({
      and: Joi.array().messages({
        'array.min': 'Select at least one ticket conditions.',
        'any.required': 'Select at least one ticket conditions.',
      }),
    }),
  }),
  user_conditions: Joi.object()
    .keys({
      org: Joi.array().items(Joi.string()),
      roles: Joi.array().items(Joi.string()),
      teams: Joi.array().items(Joi.string()),
      users: Joi.array().items(Joi.string()),
      actors: Joi.array().items(Joi.string()),
      groups: Joi.array().items(Joi.string()),
      queues: Joi.array().items(Joi.string()),
    })
    .custom((value, helpers) => {
      // Check if at least one key in the object has a value
      if(! (value && Object.keys(value).length> 0)) return value
      const hasValue = Object.values(value).some((val) => val.length > 0);
      if (!hasValue) {
        return helpers.error('any.required', {
          message: 'At least one key must have a value',
        });
      }
      return value;
    }).min(0).allow(null),
  wasApproved: Joi.boolean(),
}).unknown();

export const createPayloadForEvent = (event) => {
  const defaultProperties = {
    created_by: localStorage.getItem('userEmail'),
  };
  if (isEmpty(event?.id)) {
    return cloneDeep({
      ...event,
      ...defaultProperties,
      last_edit_by: localStorage.getItem('userEmail'),
      id: uuidv4(),
    });
  }
  return cloneDeep({
    ...event,
    ...defaultProperties,
    last_edit_by: localStorage.getItem('userEmail'),
  });
};

export const addEventInEventList = (newEvent, eventList = []) => {
  if (eventList.length === 0) {
    return [newEvent];
  }
  const existingEventIndex = eventList.findIndex(
    (event) => event.id === newEvent.id
  );

  if (existingEventIndex !== -1) {
    const updatedEventList = [...eventList];
    updatedEventList.splice(existingEventIndex, 1, newEvent);
    return updatedEventList;
  }
  return [...eventList, newEvent];
};
export const processErrorWithMessage = (errors, index = 0) => {
  const errorKeys = {};
  errors?.forEach((item) => {
    const key = item.path[index];
    errorKeys[key] = item.message;
  });
  return errorKeys;
};

export const processErrorWithNestedMessage = (errors) => {
  const errorKeys = {};
  errors?.forEach((item) => {
    if (item?.context?.key) {
      const {key} = item.context;
      errorKeys[key] = item.message;
    }
  });
  return errorKeys;
};

const dynamicCellRenderer = ({ column, rowData }) => {
  // if (column.col_name === 'triggers') {
  //   return `${_get(rowData, `trigger_type`, 'N/A')} > ${_get(
  //     rowData,
  //     `trigger`,
  //     'N/A'
  //   )}`;
  // }
  if (column.col_name === 'action') {
    const actionToPerformCount = rowData?.action_to_perform?.length;
    if (actionToPerformCount > 1) {
      return `${_startCase(
        _camelCase(rowData.action_to_perform[0]?.action_type)
      )}, +${actionToPerformCount - 1}`;
    }
    if (actionToPerformCount > 0) {
      return `${_startCase(
        _camelCase(rowData.action_to_perform[0]?.action_type)
      )}`;
    }
    return `N/A`;
  }
  if (column.col_name === 'update_info') {
    const updateInfo = rowData?.update_info;
    if (updateInfo) {
      return updateInfo;
    }
    return `ADMIN`;
  }
  return rowData[column.col_name];
};
export const actionColumns = ({
  onEdit,
  onDelete,
  onClone,
  tabType,
  onSwitchChange,
}) => [
  {
    col_name: 'action_name',
    type: 'string',
  },
  // {
  //   col_name: 'triggers',
  //   type: 'string',
  //   cellRenderer: ({ rowData }) =>
  //     _startCase(_camelCase(rowData.trigger)) || `N/A`,
  // },
  {
    col_name: 'action',
    type: 'actions',
    cellRenderer: dynamicCellRenderer,
    // align: 'center'
  },
  {
    col_name: 'Enable/Disable',
    type: 'actions',
    cellRenderer: ({ rowData }) => (
      <Flex justifyContent="center" gap="5px">
        <CustomSwitch
          isChecked={rowData?.enabled || false}
          onChange={(e) => {
            if (!rowData?.action_to_perform?.length) {
              showToastMessage({
                title: '',
                description: `Please add action type to enable this Action`,
                status: 'error',
              });
              return;
            }
            e.stopPropagation();
            e.preventDefault();
            onSwitchChange(e.target.checked, rowData);
          }}
        />
      </Flex>
    ),
    textAsItIs: true,
    align: 'center',
  },
  {
    col_name: 'actions',
    type: 'actions',
    cellRenderer: ({ rowData }) => (
      <div className="flex gap-[10px] justify-center">
        <EditIcon
          className="cursor-pointer"
          color="#94A3B8"
          onClick={() => onEdit(rowData, tabType)}
        />
        <DeleteIcon
          className="cursor-pointer"
          color="#94A3B8"
          onClick={() => onDelete(rowData, tabType)}
        />
        <CopyIcon
          className="cursor-pointer"
          color="#94A3B8"
          onClick={() => onClone(rowData, tabType)}
        />
      </div>
    ),
  },
];

export const getRelatedTemplateIds = (relations, currentTemplateId) => {
  const templateIds = relations.map((relation) =>
    relation.template_id_left === currentTemplateId
      ? relation.template_id_right
      : relation.template_id_left
  );

  return Array.from(new Set([...templateIds, currentTemplateId]));
};

export const getTemplateById = async (id) => {
  const response = await axiosInstance.get(
    `/templates/list?$top=1&$skip=0&$select=name,id,fields,workflow,field_hierarchy_map&$filter=id eq ${id}`
  );
  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const getTemplatesById = async (ids) => {
  const filters = ids.map((id) => `id eq ${id}`).join(' or ');

  const response = await axiosInstance.get(
    `/templates/list?$select=name,id,fields,workflow,actors&$filter=${filters}`
  );
  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const templateActionValidations = Joi.object({
  id: Joi.string(),
  description: Joi.string(),
  enabled: Joi.boolean(),
  action_name: Joi.string().required().messages({
    'string.empty': 'Action name is required',
    'any.required': 'Action name is required.',
  }),
  listening_conditions: Joi.object({
    and: Joi.array().messages({
      'array.min': 'Select at least one listing conditions.',
      'any.required': 'Select at least one listing conditions.',
    }),
  }),
  wasApproved: Joi.boolean(),
  event_conditions: Joi.any(),
  user_conditions: Joi.object()
    .keys({
      org: Joi.array().items(Joi.string()),
      roles: Joi.array().items(Joi.string()),
      teams: Joi.array().items(Joi.string()),
      users: Joi.array().items(Joi.string()),
      actors: Joi.array().items(Joi.string()),
      groups: Joi.array().items(Joi.string()),
      queues: Joi.array().items(Joi.string()),
    })
    .custom((value, helpers) => {
      // if(! (value && Object.keys(value).length> 0)) return value
      // Check if at least one key in the object has a value
      const hasValue = Object.values(value).some((val) => val.length > 0);
      if (!hasValue) {
        return helpers.error('any.required', {
          message: 'At least one user condition is required',
        });
      }
      return value;
    }).required()
}).unknown();

const fieldValidation = Joi.array()
  .items(
    Joi.object({
      field: Joi.object({
        value: Joi.string().required(),
        label: Joi.string().required(),
      }).required(),
      value: Joi.alternatives().try(
        Joi.array().items(Joi.string()),
        Joi.string(),
        Joi.number(),
        Joi.boolean(),
        Joi.object(),
        Joi.any().valid(null)
      ).messages({
        'string.empty': 'value is required',
        'any.required': 'value is required',
      }),
      userAttr: Joi.string()
    }).required().messages({
      'string.empty': 'fields is required.',
      'any.required': 'fields is required.',
    })
  );

const actionTypeValidationForAction = Joi.object()
  .keys({
    action_type: Joi.string().required(),
    from: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.SEND_EMAIL,
      then: Joi.object({
        aliasIsChecked: Joi.boolean().optional(),
        alias: Joi.string().valid('tenant_default_data', 'queue_or_actor', 'others').required(),
        actor: Joi.when('alias', {
          is: 'queue_or_actor',
          then: Joi.string(),
          otherwise: Joi.forbidden(),
        }),
        queue: Joi.when('alias', {
          is: 'queue_or_actor',
          then: Joi.string(),
          otherwise: Joi.forbidden(),
        }),
        other: Joi.when('alias', {
          is: 'others',
          then: Joi.string().required(),
          otherwise: Joi.forbidden(),
        })
      }).when(Joi.object({ alias: Joi.valid('queue_or_actor') }).unknown(), {
        then: Joi.object().or('actor', 'queue'),
      })
    }),
    to: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.SEND_EMAIL,
      then: Joi.string(),
      otherwise: Joi.any(),
    }).messages({
      'string.empty': 'To is required',
      'any.required': 'To is required.',
    }),
    subject: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.SEND_EMAIL,
      then: Joi.string(),
      otherwise: Joi.any(),
    }).messages({
      'string.empty': 'Subject is required',
      'any.required': 'Subject is required.',
    }),
    send_as: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.SEND_EMAIL,
      then: Joi.string(),
      otherwise: Joi.any(),
    }),
    // template: Joi.alternatives().conditional('action_type', {
    //   is: ACTION_TYPE_VALUE.SEND_EMAIL,
    //   then: Joi.string().required().messages({
    //     'string.empty': 'Email template is required',
    //     'any.required': 'Email template is required.',
    //   }),
    //   otherwise: Joi.any(),
    // }),
    payload: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.TICKET_AUTOMATION,
      then: Joi.string().custom((value, helpers) => {
        try {
          value = value.replace(/(\r\n|\n|\r|\t)/gm, '');
          JSON.parse(value);
          return value;
        } catch (e) {
          return helpers.error('any.invalid', {
            message: 'Invalid JSON',
          });
        }
      }),
      otherwise: Joi.any(),
    }),
    flowName: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.TICKET_AUTOMATION,
      then: Joi.string().required().messages({
        'string.empty': 'Flow name is required',
        'any.required': 'Flow name is required.',
      }),
      otherwise: Joi.any(),
    }),
    notify_on: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.NOTIFY_ON_TEAM_OR_SLACK,
      then: Joi.array().min(1).required().messages({
        'string.empty': 'Notify on is required',
        'any.required': 'Notify on is required.',
      }),
      otherwise: Joi.any(),
    }),
    audience: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.NOTIFY_ON_TEAM_OR_SLACK,
      then: Joi.string().required().messages({
        'string.empty': 'Audience is required',
        'any.required': 'Audience is required.',
      }),
      otherwise: Joi.any(),
    }),
    teams_Payload: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.NOTIFY_ON_TEAM_OR_SLACK,
      then: Joi.string().custom((value, helpers) => {
        try {
          value = value.replace(/(\r\n|\n|\r|\t)/gm, '');
          JSON.parse(value);
          return value;
        } catch (e) {
          return helpers.error('any.invalid', {
            message: 'Teams notification should be JSON format',
          });
        }
      }),
      otherwise: Joi.any(),
    }),
    slack_Payload: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.NOTIFY_ON_TEAM_OR_SLACK,
      then: Joi.string().custom((value, helpers) => {
        try {
          value = value.replace(/(\r\n|\n|\r|\t)/gm, '');
          JSON.parse(value);
          return value;
        } catch (e) {
          return helpers.error('any.invalid', {
            message: 'Slack notification should be JSON format',
          });
        }
      }).required().messages({
        'string.empty':'This field is required',
        'string.uri': 'Enter a valid URL',
        'any.required':'Slack field is required.',
        'any.invalid':'Slack field is in invalid format, enter the value in JSON format .'
      }),
      otherwise: Joi.any(),
    }),
    create_issue: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.CREATE_ISSUES,
      then: fieldValidation.messages({
        'any.required': 'Create issue is required',
      }),
      otherwise: Joi.any(),
    }),
    team_id: Joi.alternatives().conditional('notify_on', {
      is: NOTIFY_ON_TYPES_VALUE.TEAMS,
      then: Joi.string().required().messages({
        'string.empty': 'Team id is required',
        'any.required': 'Team id is required',
      }),
      otherwise: Joi.any(),
    }),
    script_type: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.RUN_SCRIPT,
      then: Joi.object({
        value: Joi.string().required(),
        label: Joi.string().required(),
      }).required(),
      otherwise: Joi.any(),
    }),
    script_to_run: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.RUN_SCRIPT,
      then: stringRequiredValidationWithKey('Script to run'),
      otherwise: Joi.any(),
    }),
    url: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.TRIGGER_WEBHOOK,
      then: Joi.string().uri().required().messages({
        'string.empty': 'This field is required',
        'string.uri': 'Enter a valid URL',
        'any.required': 'This field is required.',
      }),
      otherwise: Joi.any(),
    }),
    request_type: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.TRIGGER_WEBHOOK,
      then: stringRequiredValidationWithKey('Request type'),
      otherwise: Joi.any(),
    }),
    insert_data: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.TRIGGER_WEBHOOK,
      then: stringRequiredValidationWithKey('Insert data'),
      otherwise: Joi.any(),
    }),
    update_record: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.UPDATE_RECORDS,
      then: fieldValidation.messages({
        'any.required': 'Create issue is required',
      }),
      otherwise: Joi.any(),
    }),
    queue_field: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.QUEUE_ASSIGNMENT,
      then: Joi.object({
        value: Joi.string().required(),
        label: Joi.string().required(),
      }).messages({
        'string.empty': 'Queue field is required',
        'any.required': 'Queue field is required',
      }),
      otherwise: Joi.any(),
    }),
    actor_field: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.ACTOR_ASSIGNMENT,
      then: Joi.object({
        value: Joi.string().required(),
        label: Joi.string().required(),
      }).messages({
        'string.empty': 'Actor field is required',
        'any.required': 'Actor field is required',
      }),
      otherwise: Joi.any(),
    }),
    assignment_strategy: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.ACTOR_ASSIGNMENT,
      then: stringRequiredValidationWithKey('Assignment Strategy'),
      otherwise: Joi.any(),
    }),
    type_of_approval: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW,
      then: Joi.string().messages({
        'any.required': 'Type of approval is required',
      }),
      otherwise: Joi.any(),
    }),
    note_type:Joi.alternatives().conditional('action_type',{
      is: ACTION_TYPE_VALUE.ADD_NOTE,
      then: stringRequiredValidationWithKey('Note Type'),
      otherwise: Joi.any()
    }),
    message: Joi.alternatives().conditional('action_type',{
      is: ACTION_TYPE_VALUE.ADD_NOTE,
      then: stringRequiredValidationWithKey('Note'),
      otherwise: Joi.any()
    }),
    levels: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW,
      then: Joi.alternatives().conditional('type_of_approval', {
        is: approvalOptionsValues.heirarchical,
        then: Joi.number().required().messages({
          'number.base': 'levels field must be a number',
          'any.required': 'levels is required',
        }),
        otherwise: Joi.any(),
      }),
      otherwise: Joi.any(),
    }),
    flat_permitted_roles: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW,
      then: Joi.alternatives().conditional('type_of_approval', {
        is: approvalOptionsValues.flat,
        then: Joi.object({
          roles: Joi.array().min(1),
        }).required(),
        otherwise: Joi.any(),
      }),
      otherwise: Joi.any(),
    }),
    hierarchical_permitted_roles: Joi.alternatives().conditional(
      'action_type',
      {
        is: ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW,
        then: Joi.alternatives().conditional('type_of_approval', {
          is: approvalOptionsValues.heirarchical,
          then: Joi.object({
            roles: Joi.array().min(1),
          }).required(),
          otherwise: Joi.any(),
        }),
        otherwise: Joi.any(),
      }
    ),
  })
  .unknown();

const eventConfigValidationForAction = Joi.object()
  .keys({
    listening_conditions: Joi.object({
      and: Joi.array().items(),
    }),
    linked_item_type: Joi.string().required(),
  })
  .unknown();

export const validateCompleteActionData = templateActionValidations.append({
  action_to_perform: Joi.array().items(actionTypeValidationForAction),
  eventConfig: Joi.array().items(eventConfigValidationForAction),
});

const getEmailPayload = (value, sendAs) => {
  if (sendAs === 'html') {
    return value ? parseCustomMentionToAPI(value) : '';
  }

  return value ? parseRichTextMentionToAPI(value) : '';
};

export const createPayloadAction = (actionState, selectedTemplate) => {
  const payload = _cloneDeep(actionState);
  payload.id = actionState?.id || uuidv4();
  payload.user_conditions = actionState.user_conditions || {};
  payload.action_to_perform = _map(actionState?.action_to_perform, (atpMain) => {
    const atp = { ...atpMain };
    if (atp.temp) delete atp.temp;
    atp.id = atp?.id || uuidv4();
    atp.action_type = atp && atp.action_type ? atp?.action_type : '';

    // Encoding the email template
    if (atp.action_type === ACTION_TYPE_VALUE.SEND_EMAIL) {
      atp.template = getEmailPayload(atp.template, atp.send_as);
      atp.template = atp.template ? convertImgDataToSrc(atp.template) : '';
      // atp.template = atp.template ? atp.template.replace(/<br>/gm, '\n') : '';
      atp.cc = atp.cc ? parseCustomMentionToAPI(atp.cc) : '';
      atp.bcc = atp.bcc ? parseCustomMentionToAPI(atp.bcc) : '';
      atp.subject = atp.subject ? parseCustomMentionToAPI(atp.subject) : '';
      atp.signature = getEmailPayload(atp.signature, atp.send_as);
      atp.to = atp.to ? parseCustomMentionToAPI(atp.to) : '';
      atp.send_as = atp.send_as ?? 'text';
      // if (atp?.from?.alias === 'others' || atp?.from?.alias === 'tenant_default_data') {
      //   delete atp.from.actor;
      //   delete atp.from.queue;
      // }
      // if (atp?.from?.alias === 'queue_or_actor' || atp?.from?.alias === 'tenant_default_data') {
      //   delete atp.from.other;
      // }

      if(atp?.from?.alias === 'actor' || atp?.from?.alias === 'queue') {
        atp.from = {
          ...atp.from,
          alias : 'queue_or_actor'
        }
      } else if(atp?.from?.alias === 'custom') {
        atp.from = {
          ...atp.from,
          alias : 'others'
        }
      }
      if (!atp?.from?.alias) {
        atp.from = {
          alias: 'tenant_default_data',
        };
      }
      if (atp?.from) {
        for (const [key, value] of Object.entries(atp.from)) {
          if (!value) {
            delete atp.from[key];
          }
        }
      }
    }

    if (atp.action_type === ACTION_TYPE_VALUE.ADD_NOTE) {
      atp.message = atp.message ? parseRichTextMentionToAPI(atp.message) : '';
    }

    if (atp.action_type === ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW) {
      atp.type_of_approval = atp?.type_of_approval || '';
      if (atp?.type_of_approval === approvalOptionsValues.flat) {
        delete atp?.levels;
        delete atp?.hierarchical_permitted_roles;
      }
      if (atp?.type_of_approval === approvalOptionsValues.heirarchical) {
        delete atp?.number_of_approvals_required;
        delete atp?.flat_permitted_roles;
      }
    }
    if (atp.action_type === ACTION_TYPE_VALUE.QUEUE_ASSIGNMENT) {
      atp.queue_field = atp?.queue_field || '';
    }
    if (atp.action_type === ACTION_TYPE_VALUE.ACTOR_ASSIGNMENT) {
      atp.actor_field = atp?.actor_field || '';
      atp.assignment_strategy = atp?.assignment_strategy || '';
    }
    if (atp.action_type === ACTION_TYPE_VALUE.CREATE_ISSUES) {
      if (atp?.relationship === 'none') {
        delete atp?.relationship;
      }

      atp.create_issue = atp.create_issue?.map((ci) => {
        delete ci.attr;
        delete ci.static;
        delete ci.userAttrVal;
        delete ci.disabled;
        delete ci.showEditor;
        delete ci.hasUserAttr;

        if (ci.ejs) {
          const regex = /\$\{([\s\S]+?)\}/g;
          ci.value =ci.value ?parseRichTextMentionToAPI(ci.value.replace(regex,(match, code) => `<%= ${code.trim()} %>`)) : '';
          delete ci.ejs;
        }
        return ci;
      });
    }
    if (atp.action_type === ACTION_TYPE_VALUE.UPDATE_RECORDS) {
      if (atp?.relationship === 'none') {
        delete atp?.relationship;
      }

      atp.update_record = atp.update_record?.map((ci) => {
        delete ci.attr;
        delete ci.static;
        delete ci.userAttrVal;
        delete ci.disabled;
        delete ci.showEditor;
        delete ci.hasUserAttr;

        if (ci.ejs) {
          const regex = /\$\{([\s\S]+?)\}/g;
          ci.value = ci.value ? parseRichTextMentionToAPI(ci.value.replace( regex, (match, code) => `<%= ${code.trim()} %>`)) : '';
          delete ci.ejs;
        }
        return ci;
      });
    }

    if (atp.action_type === ACTION_TYPE_VALUE.TICKET_AUTOMATION) {
      if (atp?.payload) {
        let jsonPayload = atp?.payload || '';
        if (typeof jsonPayload === 'string') {
          jsonPayload = parseCustomMentionToAPI(jsonPayload || '');
          atp.payload = jsonPayload;
        }
      }
      if (atp?.flowName && typeof (atp?.flowName) === 'object') {
        atp.flowName = atp?.flowName?.value;
      }
    }

    if (atp?.action_type === ACTION_TYPE_VALUE.NOTIFY_ON_TEAM_OR_SLACK) {
      if (atp?.audience) {
        atp.audience = atp.audience ? parseCustomMentionToAPI(atp.audience) : '';
      }
      if (atp?.teams_Payload) {
        atp.teams_Payload = atp.teams_Payload ? parseCustomMentionToAPI(atp.teams_Payload) : '';
      }
      if (atp?.slack_Payload) {
        atp.slack_Payload = atp.slack_Payload ? parseCustomMentionToAPI(atp.slack_Payload) : '';
      }
    }

    // atp.signature = atp && atp.signature ? atp?.signature?.value : '';
    atp.notify_on = atp && atp.notify_on ? atp?.notify_on : '';
    atp.status = atp && atp.status ? atp?.status : '';
    atp.issue_type = atp && atp.issue_type ? atp?.issue_type : '';
    atp.script_type = atp && atp.script_type ? atp?.script_type : '';
    atp.update_record =
      atp && atp.update_record
        ? _map(
          atp.update_record,
          (ur) =>
          // ur.field = ur?.field?.value;
            ur
        )
        : null;
    return atp;
  });
  payload.eventConfig = (actionState?.eventConfig || []).map((ec) => ({
    ...ec,
    linked_item_type: ec?.linked_item_type,
    linked_item_relation: ec?.linked_item_type === selectedTemplate.id ? 'self' : undefined,
  }));
  return payload;
};

const attachHierarchyObject = (
  fieldDefaultComponentdata,
  defaultFieldCallback
) => {
  if (
    fieldDefaultComponentdata &&
    fieldDefaultComponentdata.fieldHierarchyMap &&
    fieldDefaultComponentdata.fieldHierarchyMap.heirachyListId
  ) {
    getHierarchyById(
      fieldDefaultComponentdata.fieldHierarchyMap.heirachyListId,
      (hierarchy) => {
        defaultFieldCallback({
          ...fieldDefaultComponentdata,
          hierarchyObject: hierarchy,
        });
      }
    );
  } else {
    defaultFieldCallback({
      ...fieldDefaultComponentdata,
      hierarchyObject: null,
    });
  }
};

export const getTransformedChildTemplatesFieldsForUI = (
  currentTemplate,
  defaultFieldCallback
) => {
  getTemplateById(currentTemplate?.id).then((templates) => {
    if (templates?.count === 1) {
      const template = templates?.rows[0];

      if (typeof defaultFieldCallback === 'function') {
        const fieldList = template?.fields || [];
        const workflowStatusList =
          template?.workflow?.workflow_status
            ?.filter((item) => item.id !== 'start_1')
            .map((ele) => ({ value: ele.id, label: ele.data.label })) || [];

        const fieldDefaultComponentdata = {
          fieldList,
          actors: template?.actors || [],
          workflowStatusList,
          fieldHierarchyMap: template?.field_hierarchy_map || [],
        };
        attachHierarchyObject(fieldDefaultComponentdata, defaultFieldCallback);
      }
    }
  });
};

export const isEventConfigValid = (eventConfig) => {
  if (eventConfig.length > 1) {
    return true;
  }

  if (eventConfig.length === 1) {
    if (eventConfig[0].listened_event) {
      return true;
    }

    return false;
  }

  return false;
};

export const createTemplateIdMapForRelation = (
  relations,
  allTemplateMap,
  currentTemplateId,
) => {
  const templateIdMapping = [];

  relations.forEach((relation) => {
    const { template_id_left: leftTemplate } = relation;
    const { template_id_right: rightTemplate } = relation;

    // Check if either template_id matches the currentTemplateId
    if (leftTemplate === currentTemplateId && rightTemplate !== currentTemplateId) {
      templateIdMapping.push({ value: rightTemplate, label: allTemplateMap[rightTemplate] });
    } else if (rightTemplate === currentTemplateId && leftTemplate !== currentTemplateId) {
      templateIdMapping.push({ value: leftTemplate, label: allTemplateMap[leftTemplate] });
    } else if (leftTemplate === rightTemplate && leftTemplate === currentTemplateId) {
      templateIdMapping.push({ value: currentTemplateId, label: 'Self' });
    }
  });

  return removeDuplicateElement(templateIdMapping, 'value');
};
