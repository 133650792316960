import React from 'react';

import { ArrowBackIcon, EditIcon } from '@chakra-ui/icons';

import { Tooltip } from '@chakra-ui/react';
import TableColumnText from '../../components/Text/TableColumnText';

import { deleteRenderer } from '../DynamicRenderer/MetricsAndSLA/Metrics/utils';

export const OFFER_TYPES = {
  OFFER: 'offer',
  TEMPLATE: 'template',
};

export const OFFER_TYPES_OPTIONS = [
  {
    label: 'Offer',
    value: OFFER_TYPES.OFFER,
  },
  {
    label: 'Template',
    value: OFFER_TYPES.TEMPLATE,
  },
];

export const FORM_MAP = Object.freeze({
  NAME: 'name',
  ITEM_TYPE: 'item_type',
  OFFER_TYPE: 'offer_type',
  DESCRIPTION: 'description',
  THUMBNAIL: 'thumbnail',
  AUDIENCES: 'audiences',
  INTENT_GROUPS: 'intent_groups',
  FIELD_ATTRIBUTES: 'field_attributes',
});

export const enrichDataForEdit = (data, templateId, templateName) => {
  const editData = {
    id: data?.id || '',
    [FORM_MAP.NAME]: data?.[FORM_MAP.NAME] || templateName,
    [FORM_MAP.ITEM_TYPE]: data?.[FORM_MAP.ITEM_TYPE] || templateId,
    [FORM_MAP.OFFER_TYPE]: data?.[FORM_MAP.OFFER_TYPE] || OFFER_TYPES.OFFER,
    [FORM_MAP.DESCRIPTION]: data?.[FORM_MAP.DESCRIPTION] || '',
    [FORM_MAP.THUMBNAIL]: data?.[FORM_MAP.THUMBNAIL] || '',
    [FORM_MAP.AUDIENCES]: data?.[FORM_MAP.AUDIENCES] || [],
    [FORM_MAP.INTENT_GROUPS]: data?.[FORM_MAP.INTENT_GROUPS] || [],
    [FORM_MAP.FIELD_ATTRIBUTES]: data?.[FORM_MAP.FIELD_ATTRIBUTES] || [],
  };

  return editData;
};

export const getTitle = ({ title, onCancel, hideBackIcon }) => (
  <div className={!hideBackIcon && 'pb-2'} data-testid="TitleEditContainerDiv">
    <div
      className="flex items-center gap-1"
      data-testid="TitleEditArrowIconDiv"
    >
      {!hideBackIcon && (
        <ArrowBackIcon
          className={`
          cursor-pointer
          border
          border-[#E2E8F0]
          p-1
          rounded-md
        `}
          h="32px"
          w="32px"
          onClick={onCancel}
        />
      )}
      <p className="font-semibold text-base" data-testid="TitleTextP">
        {title}
      </p>
    </div>
  </div>
);

export const columns = ({ onEditClick, onDelete, audienceMap ,showWarning}) => {
  const cols = [
    {
      name: FORM_MAP.NAME,
      col_name: FORM_MAP.NAME,
      cellRenderer: ({ rowData }) => (
        <Tooltip
          hasArrow
          label={rowData[FORM_MAP.NAME]}
          placement="bottom"
          data-testid="offerViewTooltip"
        >
          <span className="ellipsis" style={{ maxWidth: '250px' }}>
            {' '}
            {rowData[FORM_MAP.NAME]}
          </span>
        </Tooltip>
      ),
    },
    {
      name: FORM_MAP.AUDIENCES,
      col_name: FORM_MAP.AUDIENCES,
      cellRenderer: ({ rowData }) => {
        const audience = rowData?.[FORM_MAP.AUDIENCES];
        if (!Array.isArray(audience)) {
          return null;
        }

        const columnText = audience
          .map((a) => audienceMap[a || ''] || '')
          .filter((a) => !!a)
          .join(', ');

        return <TableColumnText text={columnText} />;
      },
    },
    {
      name: 'Actions',
      col_name: 'Actions',
      cellRenderer: ({ rowData }) => (
        <div className="flex gap-2">
          <EditIcon
            color="#94A3B8"
            data-testid={`offers-${rowData.name}-edit`}
            className="cursor-pointer"
            onClick={() => {
              onEditClick(rowData);
            }}
          />
          {rowData.offer_type === 'offer'
            ? deleteRenderer({
                title: 'Delete this Offer',
                description: 'Are you sure you want to delete this offer?',
                onConfirm: () => {
                  showWarning(rowData)
                  //onDelete(rowData);
                },
                dataTestId: `offers-${rowData.name}-delete`,
              })
            : null}
        </div>
      ),
    },
  ];

  return cols;
};
