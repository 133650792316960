/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable indent */
/* eslint-disable no-unused-expressions */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty, find, isNumber } from 'lodash';
import { useParams } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import CustomToast from '../../../components/Notification/Notification';
import FieldContent from './FieldContent';
import {
  LABELS,
  FIELD_SLUG,
  FIELD_VALIDATE_KEY,
  SPECIAL_FIELDS_DROPDOWN,
  ACTION_LABELS,
} from '../../../utils/enums/labels';
import { FieldTypes, SettingsTabTypes } from '../../../utils/enums/types';
import { BLACK, RULE_CARD_BORDER, WHITE } from '../../../utils/enums/colors';
import AdvancedNumberFields from '../utils/AdvancedNumberFields';
import baseSettings from '../utils/baseTextSetting';
import advancedTextFields from '../utils/advanceTextSetting';
import advancedTextAreaFields from '../utils/advanceTextAreaSetting';
import { DateBaseSettings, AdvanceDateFields } from '../utils/DateSetting';
import {
  advancedRichTextFields,
  richTextBaseSettings,
} from '../utils/RichTextSetting';
import {
  addFieldInFieldsetsUsingCrud,
  addFieldInMasterFields,
  updateFieldInMasterFields,
  getDefaultValue,
} from '../utils/helper';
import { isDuplicatesLabelValue, validateDropDownOptions } from './helper';

import {
  BooleanBasicSetting,
  advancedBoolField,
} from '../utils/BooleanSetting';
import {
  AdvancedMediaField,
  isAttachmentCountValid,
  isMediaSizeValid,
  mediaBasicSetting,
} from '../utils/MediaSetting';

import { AdvanceTagSeeting, BasicTagSetting } from '../utils/TagsSetting';
import baseMailSetting from '../utils/baseMailSetting';
import baseNumberSetting from '../utils/baseNumberSetting';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import {
  BASE_SETTINGS_KEYS,
  ADVANCED_SETTINGS_KEYS,
  TEMPLATE_ROLE_SETTINGS,
} from '../utils/constants';
import {
  AdvanceDropdownSeeting,
  BasicDropdownSetting,
} from '../utils/dropdownSetting';
import baseLabelSetting from '../utils/baseLabelSetting';
import advanceLabelSetting from '../utils/advanceLabelSetting';
import { getFieldLabels } from '../utils/checkForUniqueLabels';
import {
  patchDataForTemplate,
  patchDataForTemplateWithPath,
  showToastMessage,
} from '../utils/templateAPIUtils';
import { getTenantData } from '../../../helpers/tenantUrl';
import CustomCheckBox from '../../../components/CheckBox/CheckBox';
// import { data } from 'autoprefixer';
import {
  DROPDOWN_TYPES,
  TAG_TYPES,
} from '../../TenantFilters/filters.constants';
import { ACTOR_TYPE } from '../../../utils/enums/selectOption';
import {
  PrimaryCustomButton,
  SecondaryCustomButton,
} from '../../../components/Button/PrimarySecondaryButton';
import { validateSlaMetricFieldCannotHaveSameName } from '../../DynamicRenderer/duplicateMetricSlaFieldsVerificationHelper';
import { setActiveModal } from '../../../redux/ModalReducer/ModalSlice';

const SETTINGS_TABS = [
  SettingsTabTypes.BASIC,
  SettingsTabTypes.ADVANCED,
  SettingsTabTypes.ROLE,
];
const SETTINGS_TABS_LABEL_MAP = {
  [SettingsTabTypes.BASIC]: LABELS.TITLE.BASIC_SETTINGS,
  [SettingsTabTypes.ADVANCED]: LABELS.TITLE.ADVANCED_SETTINGS,
  [SettingsTabTypes.ROLE]: LABELS.TITLE.ROLE_SETTINGS,
};

const CreateEditFieldModal = ({
  type,
  fieldData = {},
  edit = false,
  isFieldModel,
  setIsfieldModel,
  setIsAddField,
}) => {
  const { addToast } = CustomToast();
  const { id: templateId } = useParams();

  const setFieldsData = useCallback(
    (fieldsData) => {
      const tempObj = {
        type,
        ...fieldsData,
        ...fieldsData.type_based_attributes,
        ...fieldsData.permissions,
        ...fieldsData.type_based_attributes?.dependency,
        ...fieldsData.type_based_attributes?.collection,
        ...fieldsData.type_based_attributes?.list_map,
        [BASE_SETTINGS_KEYS.FIELD_NAME_MAPPING]:
          fieldsData?.[BASE_SETTINGS_KEYS.FIELD_NAME_MAPPING],
        filterField:
          fieldsData?.type_based_attributes?.dependency?.filterField ||
          'parentName',
        editors: isEmpty(fieldData?.permissions?.editors)
          ? { org: [getTenantData()?.tenantId] }
          : fieldData.permissions.editors,
        viewers: isEmpty(fieldData?.permissions?.viewers)
          ? { org: [getTenantData()?.tenantId] }
          : fieldData.permissions.viewers,
      };
      delete tempObj.type_based_attributes;
      delete tempObj.permissions;
      if (!fieldsData?.[BASE_SETTINGS_KEYS.FIELD_NAME_MAPPING]) {
        delete tempObj?.[BASE_SETTINGS_KEYS.FIELD_NAME_MAPPING];
      }

      if (type === 'MEDIA') {
        if (!isNumber(tempObj[BASE_SETTINGS_KEYS.MEDIA_SIZE])) {
          tempObj[BASE_SETTINGS_KEYS.MEDIA_SIZE] = 4;
        }
        if (tempObj[BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT] === 'INFINITY') {
          tempObj[BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT] = '0';
        }
        if (!isNumber(tempObj[BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT])) {
          tempObj[BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT] = '0';
        }
        // if (!isAttachmentCountValid(tempObj[BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT])) {
        //   tempObj[BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT] = 1;
        // }
      }
      if (tempObj[BASE_SETTINGS_KEYS.IS_MULTI] === true && edit === false) {
        tempObj[ADVANCED_SETTINGS_KEYS.INCLUDE_IN_REPORT] = true;
      }
      if (type === 'RICH_TEXT') {
        if (
          tempObj[BASE_SETTINGS_KEYS.IS_ADD_ATTACHMENT] === undefined ||
          tempObj[BASE_SETTINGS_KEYS.IS_ADD_ATTACHMENT] === false
        ) {
          tempObj[BASE_SETTINGS_KEYS.IS_ADD_ATTACHMENT] = false;
        }
        // if (!isNumber(tempObj[BASE_SETTINGS_KEYS.MEDIA_SIZE])) {
        //   tempObj[BASE_SETTINGS_KEYS.MEDIA_SIZE] = 4;
        // }
        // if (!isNumber(tempObj[BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT])) {
        //   tempObj[BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT] = 10;
        // }
      }

      if (type === FieldTypes.DATE) {
        if (
          tempObj[BASE_SETTINGS_KEYS.IS_ALLOW_TIME_FIELD] === undefined ||
          tempObj[BASE_SETTINGS_KEYS.IS_ALLOW_TIME_FIELD] === false
        ) {
          tempObj[BASE_SETTINGS_KEYS.IS_ALLOW_TIME_FIELD] = false;
        }

        tempObj[BASE_SETTINGS_KEYS.VALIDATIONS] = fieldsData?.validations || {
          defaultValidations: {},
          conditionalValidations: [],
        };
      }
      return tempObj;
    },
    [type]
  );
  const setCollectionsData = useCallback(
    (fieldsData) => {
      if (type === FieldTypes.DROPDOWN || type === FieldTypes.TAGS) {
        const tempObj = {
          ...fieldsData.type_based_attributes?.collection,
        };
        return tempObj;
      }
      return {};
    },
    [type]
  );
  const [title, setTitle] = useState('');
  const [apiCall, setApiCall] = useState('');
  const [fields, setFields] = useState(setFieldsData(fieldData));
  const [isActive, setIsActive] = useState(false);
  const [isError, setIsError] = useState({});
  const [baseData, setBaseData] = useState([]);
  const [teamsDetail, setTeamsDetail] = useState({
    tags: [],
    selectedTeam: '',
    selectedQueue: '',
  });
  // const [fieldsetData, setFieldsetData] = useState([]);
  const [fieldAdvData, setFieldAdvData] = useState([]);
  const [activeSetting, setActiveSetting] = useState(SettingsTabTypes.BASIC);
  const [collectionData, setCollectionData] = useState(
    setCollectionsData(fieldData)
  );
  const [isLoadingForApiCall, setIsLoadingForApiCall] = useState(false);
  const [isCreateFieldInMaster, setIsCreateFieldInMaster] = useState(false);
  // eslint-disable-next-line camelcase
  const {
    ticket_template_fields_list_view,
    fields_expanded_list,
    fieldsets_fields_list_view,
    selectedTemplate,
  } = useSelector((state) => state.viewState);
  const { editFieldModal } = useSelector((state) => state.viewState);
  const dispatch = useDispatch();
  const name = selectedTemplate?.v1_name;

  const isActiveButton = useMemo(() => isActive, [isActive]);
  const existingTemplateFields = useMemo(() => {
    const uniqueFields = Array.from(
      getFieldLabels(ticket_template_fields_list_view?.data)
    );

    const uniqueFieldMap = uniqueFields.reduce((prev, curr) => {
      prev[curr?.toLowerCase()] = true;
      return prev;
    }, {});

    return {
      fieldMap: uniqueFieldMap,
    };
  }, [ticket_template_fields_list_view?.data]);

  const checkIfSpecialWorkflowExists = useMemo(
    () => () => {
      let workflowData = [];
      workflowData = find(
        ticket_template_fields_list_view?.data,
        (field) =>
          field?.type_based_attributes?.collection?.collection_type ===
            DROPDOWN_TYPES.SPECIAL_FIELD &&
          field?.type_based_attributes?.collection?.specialField ===
            SPECIAL_FIELDS_DROPDOWN[0].value
      );
      return workflowData;
    },
    [ticket_template_fields_list_view?.data]
  );

  // const collectionTypeText =
  //   collectionData?.collection_type === DROPDOWN_TYPES.STATIC
  //  ? DROPDOWN_TYPES.STATIC : DROPDOWN_TYPES.DYNAMIC;
  // helper function to enrich fields
  const isNumberToInifinityCheck = (value) => !isNaN(parseFloat(value)) && isFinite(value);
  const convertToInfinity = (maxNumber) => {
    if (!isNumberToInifinityCheck(maxNumber) || parseInt(maxNumber, 10) === 0) {
      return 'INFINITY';
    }
    return parseInt(maxNumber, 10);
  };
  const enrichFieldData = () => {
    const dataObj = {
      id: fields?.id || uuidv4(),
      type,
      [BASE_SETTINGS_KEYS.IS_PRIMARY_MEDIA_FIELD]:
        fields?.[BASE_SETTINGS_KEYS.IS_PRIMARY_MEDIA_FIELD],
      [BASE_SETTINGS_KEYS.IS_ADD_ATTACHMENT]:
        fields?.[BASE_SETTINGS_KEYS.IS_ADD_ATTACHMENT],
      [BASE_SETTINGS_KEYS.LABEL]: fields?.[BASE_SETTINGS_KEYS.LABEL].replace(/\s+/g, ' ').trim(),
      [BASE_SETTINGS_KEYS.SLUG]: fields?.[BASE_SETTINGS_KEYS.SLUG].replace(/\s+/g, ' ').trim(),
      [BASE_SETTINGS_KEYS.DEFAULTFIELDBOT]:
        fields?.[BASE_SETTINGS_KEYS.DEFAULTFIELDBOT],
      [BASE_SETTINGS_KEYS.DESCRIPTION]:
        fields?.[BASE_SETTINGS_KEYS.DESCRIPTION],
      [BASE_SETTINGS_KEYS.VALUES]: fields?.[BASE_SETTINGS_KEYS.VALUES],
      [BASE_SETTINGS_KEYS.DEFAULT_VALUE]: getDefaultValue(
        fields?.[BASE_SETTINGS_KEYS.DEFAULT_VALUE]
      ),
      [BASE_SETTINGS_KEYS.SCOPE]:
        fields?.[BASE_SETTINGS_KEYS.SCOPE] || 'TICKET',
      display: true,
      [BASE_SETTINGS_KEYS.IS_ALLOW_TIME_FIELD]:
        fields?.[BASE_SETTINGS_KEYS.IS_ALLOW_TIME_FIELD],
      [BASE_SETTINGS_KEYS.IS_RESTRICTED]: fields?.[BASE_SETTINGS_KEYS.IS_RESTRICTED] || false,
      [BASE_SETTINGS_KEYS.VALIDATIONS]: fields?.[BASE_SETTINGS_KEYS.VALIDATIONS] || {},
      type_based_attributes: {
        [ADVANCED_SETTINGS_KEYS.MAX_ROW]:
          fields?.[ADVANCED_SETTINGS_KEYS.MAX_ROW],
        [ADVANCED_SETTINGS_KEYS.MIN_ROW]:
          fields?.[ADVANCED_SETTINGS_KEYS.MIN_ROW],
        [ADVANCED_SETTINGS_KEYS.MAX_LENGTH]:
          fields?.[ADVANCED_SETTINGS_KEYS.MAX_LENGTH],
        [ADVANCED_SETTINGS_KEYS.MIN_LENGTH]:
          fields?.[ADVANCED_SETTINGS_KEYS.MIN_LENGTH],
        [ADVANCED_SETTINGS_KEYS.HINT_TEXT]:
          fields?.[ADVANCED_SETTINGS_KEYS.HINT_TEXT],
        [ADVANCED_SETTINGS_KEYS.READ_ONLY]:
          fields?.[ADVANCED_SETTINGS_KEYS.READ_ONLY],
        [BASE_SETTINGS_KEYS.MEDIA_TYPE]:
          fields?.[BASE_SETTINGS_KEYS.MEDIA_TYPE],
        [ADVANCED_SETTINGS_KEYS.REQUIRED]:
          fields?.[ADVANCED_SETTINGS_KEYS.REQUIRED] === undefined
            ? true
            : fields?.[ADVANCED_SETTINGS_KEYS.REQUIRED],
        [ADVANCED_SETTINGS_KEYS.HIDDEN]:
          fields?.[ADVANCED_SETTINGS_KEYS.HIDDEN],
        [ADVANCED_SETTINGS_KEYS.PLACEHOLDER]:
          fields?.[ADVANCED_SETTINGS_KEYS.PLACEHOLDER],
        [ADVANCED_SETTINGS_KEYS.SEARCHABLE]:
          fields?.[ADVANCED_SETTINGS_KEYS.SEARCHABLE] === undefined
            ? true
            : fields?.[ADVANCED_SETTINGS_KEYS.SEARCHABLE],
        [ADVANCED_SETTINGS_KEYS.INCLUDE_IN_REPORT]:
          fields?.[ADVANCED_SETTINGS_KEYS.INCLUDE_IN_REPORT] === undefined
            ? true
            : fields?.[ADVANCED_SETTINGS_KEYS.INCLUDE_IN_REPORT],
        [ADVANCED_SETTINGS_KEYS.REGEX_PATTERN]:
          fields?.[ADVANCED_SETTINGS_KEYS.REGEX_PATTERN],
        [ADVANCED_SETTINGS_KEYS.UNIQUE]:
          fields?.[ADVANCED_SETTINGS_KEYS.UNIQUE],
        [ADVANCED_SETTINGS_KEYS.REPORTABLE]:
          fields?.[ADVANCED_SETTINGS_KEYS.REPORTABLE] === undefined
            ? true
            : fields?.[ADVANCED_SETTINGS_KEYS.REPORTABLE],
        [ADVANCED_SETTINGS_KEYS.DATE_FORMAT]:
          fields?.[ADVANCED_SETTINGS_KEYS.DATE_FORMAT],
        [ADVANCED_SETTINGS_KEYS.METRICS]:
          fields?.[ADVANCED_SETTINGS_KEYS.METRICS],
        [ADVANCED_SETTINGS_KEYS.LOCALE]:
          fields?.[ADVANCED_SETTINGS_KEYS.LOCALE],
        [ADVANCED_SETTINGS_KEYS.SELECT_LOCALE]:
          fields?.[ADVANCED_SETTINGS_KEYS.SELECT_LOCALE],
        [ADVANCED_SETTINGS_KEYS.NUMBER_FORMAT]:
          fields?.[ADVANCED_SETTINGS_KEYS.NUMBER_FORMAT],
        dependency: {
          parentOf: fields?.parentOf || undefined,
          filterField: fields?.filterField || 'parentName',
        },
        collection: {
          // [LABELS.FIELD_PROPERTIES.STATIC]:
          //   collectionData?.collection_type === DROPDOWN_TYPES.STATIC
          //     ? fields?.[LABELS.FIELD_PROPERTIES.STATIC]
          //     : fields?.[LABELS.FIELD_PROPERTIES.DYNAMIC],
          [BASE_SETTINGS_KEYS.STATIC_VALUES]: [
            ...(collectionData?.[BASE_SETTINGS_KEYS.STATIC_VALUES] || []),
          ].map((item) => ({ ...item, rank: parseInt(item.rank, 10) })),
          // fields?.[BASE_SETTINGS_KEYS.STATIC_VALUES],
          // [BASE_SETTINGS_KEYS.DYNAMIC_VALUES]:
          //   fields?.[BASE_SETTINGS_KEYS.DYNAMIC_VALUES],
          labelPath: fields?.collection?.labelPath, // fixed
          valuePath: fields?.collection?.valuePath, // fixed
          apiEndpoint: fields?.apiEndpoint,
          [BASE_SETTINGS_KEYS.DEFAULT_VALUE]:
            fields?.[BASE_SETTINGS_KEYS.DEFAULT_VALUE],
          collection_type: collectionData?.collection_type,
          isMulti: collectionData?.isMulti || false,
          [BASE_SETTINGS_KEYS.DYNAMIC_CURL]:
            fields?.[BASE_SETTINGS_KEYS.DYNAMIC_CURL],
          // eslint-disable-next-line no-nested-ternary
          [BASE_SETTINGS_KEYS.SPECIAL_FIELD]: !isEmpty(
            fields?.[BASE_SETTINGS_KEYS.SPECIAL_FIELD]
          )
            ? fields?.[BASE_SETTINGS_KEYS.SPECIAL_FIELD]
            : isEmpty(checkIfSpecialWorkflowExists())
            ? SPECIAL_FIELDS_DROPDOWN[0].value
            : SPECIAL_FIELDS_DROPDOWN[1].value,
          [BASE_SETTINGS_KEYS.IS_WHITELIST]:
            fields?.[BASE_SETTINGS_KEYS.IS_WHITELIST] || false,
          [BASE_SETTINGS_KEYS.LIST]: fields?.[BASE_SETTINGS_KEYS.LIST] || [],
          [BASE_SETTINGS_KEYS.ACTOR_TYPE]:
            collectionData?.[BASE_SETTINGS_KEYS.ACTOR_TYPE] ||
            ACTOR_TYPE[0].value,
          [BASE_SETTINGS_KEYS.DEPENDENT_TYPE]:
            collectionData?.[BASE_SETTINGS_KEYS.DEPENDENT_TYPE],
        },
        list_map: {
          [BASE_SETTINGS_KEYS.KEY]: fields?.[BASE_SETTINGS_KEYS.KEY],
          [BASE_SETTINGS_KEYS.VALUE]: fields?.[BASE_SETTINGS_KEYS.VALUE],
        },
        [BASE_SETTINGS_KEYS.API_URL]: fields?.[BASE_SETTINGS_KEYS.API_URL],
        [BASE_SETTINGS_KEYS.NAME]: fields?.[BASE_SETTINGS_KEYS.NAME],
        [BASE_SETTINGS_KEYS.MODULE_NAME]:
          fields?.[BASE_SETTINGS_KEYS.MODULE_NAME],
        [BASE_SETTINGS_KEYS.OFFSET_VALUE]:
          fields?.[BASE_SETTINGS_KEYS.OFFSET_VALUE],
        [BASE_SETTINGS_KEYS.MEDIA_SIZE]:
          parseInt(fields?.[BASE_SETTINGS_KEYS.MEDIA_SIZE], 10) || 0,
        [BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT]: convertToInfinity(
          fields?.[BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT]
        ),
      },
      permissions: {
        [TEMPLATE_ROLE_SETTINGS.VIEWERS]: fields[
          TEMPLATE_ROLE_SETTINGS.VIEWERS
        ] || { org: [getTenantData()?.tenantId] },
        [TEMPLATE_ROLE_SETTINGS.RESTRICTREAD]:
          fields[TEMPLATE_ROLE_SETTINGS.RESTRICTREAD] || {},
        [TEMPLATE_ROLE_SETTINGS.EDITORS]: fields[
          TEMPLATE_ROLE_SETTINGS.EDITORS
        ] || { org: [getTenantData()?.tenantId] },
        [TEMPLATE_ROLE_SETTINGS.EXPRESSION]:
          fields[TEMPLATE_ROLE_SETTINGS.EXPRESSION] || [],
        [TEMPLATE_ROLE_SETTINGS.ENABLED_EXPRESSION]:
          fields[TEMPLATE_ROLE_SETTINGS.ENABLED_EXPRESSION] || [],
        [TEMPLATE_ROLE_SETTINGS.REQUIRED_EXPRESSION]:
          fields[TEMPLATE_ROLE_SETTINGS.REQUIRED_EXPRESSION] || [],
      },
      tenant_id: fields?.tenant_id || getTenantData()?.id,
      [BASE_SETTINGS_KEYS.FIELD_NAME_MAPPING]:
        fields?.[BASE_SETTINGS_KEYS.FIELD_NAME_MAPPING],
    };

    const types = [FieldTypes.DROPDOWN, FieldTypes.TAGS];

    if (!types.includes(dataObj.type)) {
      delete dataObj.type_based_attributes.collection;
    } else if (
      dataObj?.type_based_attributes?.collection?.collection_type ===
      DROPDOWN_TYPES.STATIC
    ) {
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.DYNAMIC_VALUES
      ];
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.DYNAMIC_CURL
      ];
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.SPECIAL_FIELD
      ];
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.IS_WHITELIST
      ];
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.LIST
      ];
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.ACTOR_TYPE
      ];
    } else if (
      dataObj?.type_based_attributes?.collection?.collection_type ===
      DROPDOWN_TYPES.DYNAMIC
    ) {
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.STATIC_VALUES
      ];
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.SPECIAL_FIELD
      ];
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.IS_WHITELIST
      ];
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.LIST
      ];
    } else if (
      dataObj?.type_based_attributes?.collection?.collection_type ===
      DROPDOWN_TYPES.DEPENDENT
    ) {
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.STATIC_VALUES
      ];
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.SPECIAL_FIELD
      ];
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.IS_WHITELIST
      ];
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.LIST
      ];
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.ACTOR_TYPE
      ];
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.DEFAULT_VALUE
      ];
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.IS_MULTI
      ];
    } else {
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.DYNAMIC_VALUES
      ];
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.DYNAMIC_CURL
      ];
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.STATIC_VALUES
      ];
      delete dataObj?.type_based_attributes?.collection?.isMulti;
    }
    if (
      dataObj?.type_based_attributes?.collection?.collection_type !==
      DROPDOWN_TYPES.DEPENDENT
    ) {
      delete dataObj?.type_based_attributes?.collection?.[
        BASE_SETTINGS_KEYS.DEPENDENT_TYPE
      ];
    }
    if (FieldTypes.LABEL !== dataObj.type) {
      delete dataObj?.type_based_attributes?.list_map;
      delete dataObj?.type_based_attributes?.module_name;
    }
    if (FieldTypes.DATE !== dataObj.type) {
      delete dataObj?.type_based_attributes?.[BASE_SETTINGS_KEYS.OFFSET_VALUE];
    }
    if (FieldTypes.RICH_TEXT !== dataObj.type) {
      delete dataObj.type_based_attributes?.include_in_report;
    }
    if (FieldTypes.MEDIA === dataObj.type) {
      delete dataObj?.type_based_attributes?.[
        ADVANCED_SETTINGS_KEYS.REPORTABLE
      ];
      delete dataObj?.type_based_attributes?.[
        ADVANCED_SETTINGS_KEYS.SEARCHABLE
      ];
    }
    if (FieldTypes.RICH_TEXT === dataObj.type) {
      delete dataObj?.type_based_attributes?.[
        ADVANCED_SETTINGS_KEYS.REPORTABLE
      ];
    }
    if (FieldTypes.MEDIA !== dataObj.type) {
      delete dataObj?.type_based_attributes?.[BASE_SETTINGS_KEYS.MEDIA_SIZE];
      delete dataObj?.type_based_attributes?.[
        BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT
      ];
      delete dataObj?.type_based_attributes[BASE_SETTINGS_KEYS.MEDIA_TYPE];
    }
    if (
      (dataObj[BASE_SETTINGS_KEYS.IS_ADD_ATTACHMENT] === false ||
        dataObj[BASE_SETTINGS_KEYS.IS_ADD_ATTACHMENT] === undefined) &&
      FieldTypes.MEDIA !== dataObj.type
    ) {
      delete dataObj?.type_based_attributes?.[BASE_SETTINGS_KEYS.MEDIA_SIZE];
      delete dataObj?.type_based_attributes?.[
        BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT
      ];
      delete dataObj?.type_based_attributes[BASE_SETTINGS_KEYS.MEDIA_TYPE];
    }
    if (FieldTypes.RICH_TEXT !== dataObj.type) {
      delete dataObj[BASE_SETTINGS_KEYS.IS_ADD_ATTACHMENT];
    }
    if (FieldTypes.MEDIA !== dataObj.type) {
      delete dataObj[BASE_SETTINGS_KEYS.IS_PRIMARY_MEDIA_FIELD];
    }
    if (FieldTypes.DATE !== dataObj.type) {
      delete dataObj[BASE_SETTINGS_KEYS.IS_ALLOW_TIME_FIELD];
    }
    if (
      dataObj?.type_based_attributes?.collection?.collection_type ===
        DROPDOWN_TYPES.STATIC &&
      dataObj?.type_based_attributes?.collection?.isMulti
    ) {
      // delete dataObj.type_based_attributes?.is_reportable;
    }
    if (
      dataObj?.type_based_attributes?.collection?.collection_type ===
        DROPDOWN_TYPES.STATIC &&
      dataObj?.type_based_attributes?.collection?.isMulti === false
    ) {
      delete dataObj.type_based_attributes?.include_in_report;
    }
    if (
      dataObj?.type_based_attributes?.collection?.collection_type ===
        DROPDOWN_TYPES.SPECIAL_FIELD
    ) {
      delete dataObj.type_based_attributes?.include_in_report;
    }
    return dataObj;
  };
  // event handler for updating object
  const enrichFieldDataInTemplates = () => {
    const enrichdata = enrichFieldData();
    const cleanPermissionData = (data, keysToCheck) => {
      keysToCheck.forEach((key) => {
        if (data.permissions[key] && typeof data.permissions[key] === "object") {
          const { and } = data.permissions[key];
          if (Array.isArray(and) && and.length > 0) {
            and.forEach(item => {
              if (item.or && Array.isArray(item.or) && item.or.length === 0) {
                data.permissions[key] = {};
              }
            });
          }
        }
      });
    };
    const keysToCheck = ["enableExpression", "expression", "requiredExpression"];
    cleanPermissionData(enrichdata, keysToCheck);
    if (isEmpty(enrichdata[BASE_SETTINGS_KEYS.DESCRIPTION])) {
      delete enrichdata[BASE_SETTINGS_KEYS.DESCRIPTION];
    }
    if (
      enrichdata?.type_based_attributes?.collection?.collection_type ===
      DROPDOWN_TYPES.STATIC
    ) {
      delete enrichdata?.type_based_attributes?.collection?.dynamicValues;
      delete enrichdata?.type_based_attributes?.collection?.dynamicCurl;
    }
    if (
      enrichdata?.type_based_attributes?.collection?.collection_type ===
      DROPDOWN_TYPES.DYNAMIC
    ) {
      delete enrichdata?.type_based_attributes?.collection?.staticValues;
    }

    return enrichdata;
  };

  // Add fileds in template
  const addNewFieldInTemplate = async () => {
    const newData = [
      ...ticket_template_fields_list_view.data,
      enrichFieldDataInTemplates(),
    ];

    const res = await patchDataForTemplateWithPath({
      id: templateId,
      data: {
        path: 'fields',
        operation: 'create',
        data: {
          ...enrichFieldDataInTemplates(),
        },
      },
      key: 'fields',
      defaultErrorMessage: 'Failed to create a field in template',
      successMessage: 'Successfully created a field in template',
    });

    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'ticket_template_fields_list_view',
          value: {
            ...ticket_template_fields_list_view,
            data: res?.response?.data?.fields || [],
          },
        })
      );
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
    }
    setIsLoadingForApiCall(false);
    setIsfieldModel(false);
  };
  const showMessageForMasterList = (err) => {
    if (err.includes('SequelizeUniqueConstraintError')) {
      return 'The field name already exists in the master list.';
    }
    return err;
  };
  const enrichFieldDataInFields = () => {
    const enrichdata = enrichFieldData();
    delete enrichdata.id;
    delete enrichdata?.type_based_attributes?.dependency;
    delete enrichdata?.permissions?.[TEMPLATE_ROLE_SETTINGS.EXPRESSION];
    const types = [FieldTypes.DROPDOWN, FieldTypes.TAGS];

    if (!types.includes(enrichdata.type)) {
      delete enrichdata.type_based_attributes.collection;
    }
    if (types.includes(enrichdata.type)) {
      delete enrichdata[BASE_SETTINGS_KEYS.DEFAULT_VALUE];
    }
    if (isEmpty(enrichdata[BASE_SETTINGS_KEYS.DESCRIPTION])) {
      delete enrichdata[BASE_SETTINGS_KEYS.DESCRIPTION];
    }
    if (enrichdata.type === FieldTypes.MEDIA) {
      delete enrichdata[BASE_SETTINGS_KEYS.IS_PRIMARY_MEDIA_FIELD];
    }
    if (
      enrichdata?.type_based_attributes?.collection?.collection_type ===
      DROPDOWN_TYPES.STATIC
    ) {
      delete enrichdata?.type_based_attributes?.collection?.dynamicValues;
      delete enrichdata?.type_based_attributes?.collection?.dynamicCurl;
    }
    if (
      enrichdata?.type_based_attributes?.collection?.collection_type ===
      DROPDOWN_TYPES.DYNAMIC
    ) {
      delete enrichdata?.type_based_attributes?.collection?.staticValues;
    }
    return enrichdata;
  };

  const enrichFieldDataInFieldsets = () => {
    const enrichdata = enrichFieldData();
    if (type === 'TEXT') {
      delete enrichdata.type_based_attributes.collection;
    }
    if (typeof enrichdata.id === 'string') {
      delete enrichdata.id;
      Object.assign(enrichdata, { id: Math.floor(Math.random() * 100) });
    }
    return enrichdata;
  };

  const addNewFieldInFields = async () => {
    try {
      const newData = { ...enrichFieldDataInFields() };
      if ('isRestricted' in newData) {
        delete newData?.isRestricted;
      }
      if ('validations' in newData) {
        delete newData?.validations;
      }
      const res = await addFieldInMasterFields(
        newData,
        'Failed to create a new field'
      );
      if (!res.error) {
        dispatch(
          updateViewState({
            stateKey: 'fields_expanded_list',
            value: {
              ...fields_expanded_list,
              data: [...(fields_expanded_list?.data || []), res.response],
              count: (fields_expanded_list?.count || 0) + 1,
            },
          })
        );
        addToast({
          title: 'Success',
          message: 'Successfully created a new field',
          type: 'success',
        });
      } else if (res.error.includes('SequelizeUniqueConstraintError')) {
        addToast({
          title: 'Duplicate Field Name',
          message: showMessageForMasterList(res.error),
          type: 'info',
        });
      } else {
        addToast({
          title: 'Something went wrong',
          message: res.error,
          type: 'error',
        });
      }
    } catch (error) {
      addToast({
        title: 'Unexpected Error',
        message: error.message || 'An unexpected error occurred',
        type: 'error',
      });
    } finally {
      setIsLoadingForApiCall(false);
      setIsfieldModel(false);
    }
  };
  // Add fileds in fieldsets
  const addNewFieldInFieldsets = async () => {
    const newData = [
      ...fieldsets_fields_list_view?.data,
      enrichFieldDataInFieldsets(),
    ];

    const res = await addFieldInFieldsetsUsingCrud(
      newData,
      'Failed to create a new field'
    );
    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'fieldsets_fields_list_view',
          value: {
            ...fieldsets_fields_list_view,
            data: newData,
          },
        })
      );
      addToast({
        title: 'Success',
        message: 'Successfully created a new field',
        type: 'success',
      });
    } else {
      addToast({
        title: 'Something went wrong',
        message: res.error,
        type: 'error',
      });
    }
    setIsfieldModel(false);
  };

  // Update field in master fields
  const updateNewFieldInFields = async () => {
    const { id } = editFieldModal;

    const editedFieldIndex = fields_expanded_list.data.findIndex(
      (field) => field.id === id
    );

    if (editedFieldIndex !== -1) {
      //

      const res = await updateFieldInMasterFields(
        id,
        {
          ...enrichFieldDataInFields(),
        },
        'Failed to update the field'
      );
      if (!res.error) {
        const updatedFields = [...fields_expanded_list.data];
        updatedFields[editedFieldIndex] = res.response.data;
        dispatch(
          updateViewState({
            stateKey: 'fields_expanded_list',
            value: {
              ...fields_expanded_list,
              data: [...updatedFields],
            },
          })
        );
        addToast({
          title: 'Success',
          message: 'Successfully updated the field',
          type: 'success',
        });
      } else {
        addToast({
          title: 'Something went wrong',
          message: res.error,
          type: 'error',
        });
      }
    }
    setIsLoadingForApiCall(false);
    setIsfieldModel(false);
  };
  const updateNewFieldInTemplate = async () => {
    const { id } = editFieldModal;

    const editedFieldIndex = ticket_template_fields_list_view.data.findIndex(
      (field) => field.id === id
    );
    const field= {...enrichFieldDataInTemplates()}
    const isValidUpdate=(field)=>{
      if(field.type_based_attributes?.collection?.collection_type==='DEPENDENT'){
        const hierarchyMapItems = selectedTemplate?.field_hierarchy_map|| [];
    
        let selectedHierarchy = null;
        let selectedIndex = null;
    
        for (const hierarchy of hierarchyMapItems) {
          const hierarchyMap = hierarchy.map;
          const index = hierarchyMap.indexOf(field?.id);
          if (index !== -1) {
            selectedHierarchy = hierarchy; 
            selectedIndex = index; 
            break;
          }
        }
        const hierarchyMap = selectedHierarchy.map || [];
        const parentFieldId = hierarchyMap[selectedIndex===0 ? 0 : selectedIndex - 1];
        const parent=selectedTemplate?.fields.find(ele=>ele.id==parentFieldId)
        const parentRoles = parent?.permissions?.viewers?.roles || [];
        const childRoles = field?.permissions?.viewers?.roles || [];
        if (selectedIndex >= 0 && hierarchyMap[0]!==field?.id) {
          const isValid=selectedTemplate?.fields.some(item=>{
            if(item.id===field.id){
              const isExpression=(item)=>{
                return item?.permissions?.expression?.and?.length>0 && item?.permissions?.expression?.and.find(ele=>ele.or)?.or.length>0
                }
              if(field.type_based_attributes.required==true && parent.type_based_attributes.required!==true){
                addToast({
                  title: 'Something went wrong',
                  message: 'Ancestor field should be mandatory',
                  type: 'error',
                });
                return false
              }
              if(isExpression(field) && !isExpression(parent)){
                  addToast({
                    title: 'Something went wrong',
                    message: 'Ancestor field conditions should be same',
                    type: 'error',
                  });
                  return false
              }
              if (selectedIndex > 0 && parentRoles.length !== childRoles.length) {
                  addToast({
                    title: 'Something went wrong',
                    message: 'Dependent fields roles should be the same',
                    type: 'error',
                  });
                  return false;
              }
              if (selectedIndex > 0 && parentRoles.length>0 && childRoles.length>0) {
                const roles=['bot_user','ticket_user']
                if(roles.some(item=>parentRoles.includes(item)) && roles.some(item=>!childRoles.includes(item))){
                  addToast({
                    title: 'Something went wrong',
                    message: 'Dependent fields roles should be the same',
                    type: 'error',
                  });
                  return false;
                }
              }
              if (selectedIndex > 0 && childRoles.length === 0 && parentRoles.length > 0) {
                  addToast({
                    title: 'Something went wrong',
                    message: 'Dependent fields roles should be the same. Child field roles cannot be empty.',
                    type: 'error',
                  });
                  return false;
              }
              return true
            }
            else{
              return false
            }
          })
          return isValid
        }
        else{return true}
      }
      else {return true}
    }

    if (editedFieldIndex !== -1 && isValidUpdate(field)) {
      const updatedFields = [...ticket_template_fields_list_view.data];
      updatedFields[editedFieldIndex] = enrichFieldDataInTemplates();

      const res = await patchDataForTemplateWithPath({
        id: templateId,
        data: {
          id,
          path: 'fields',
          operation: 'update',
          data: {
            ...enrichFieldDataInTemplates(),
          },
        },
        key: 'fields',
        defaultErrorMessage: 'Failed to update the field',
        successMessage: 'Successfully updated the field',
      });

      if (!res.error) {
        dispatch(
          updateViewState({
            stateKey: 'ticket_template_fields_list_view',
            value: {
              ...ticket_template_fields_list_view,
              data: res?.response?.data.fields || [],
            },
          })
        );
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
      }
    }
    setIsLoadingForApiCall(false);
    setIsfieldModel(false);
  };
  const updateNewFieldInFieldsets = async () => {
    const { id } = editFieldModal;

    const editedFieldIndex = fieldsets_fields_list_view?.data?.findIndex(
      (field) => field.id === id
    );

    if (editedFieldIndex !== -1) {
      const updatedFields = [...fieldsets_fields_list_view?.data];
      updatedFields[editedFieldIndex] = enrichFieldDataInFieldsets();
      const res = await addFieldInFieldsetsUsingCrud(
        updatedFields,
        'Failed to update the field'
      );
      if (!res.error) {
        dispatch(
          updateViewState({
            stateKey: 'fieldsets_fields_list_view',
            value: {
              ...fieldsets_fields_list_view,
              data: updatedFields,
            },
          })
        );
        addToast({
          title: 'Success',
          message: 'Successfully updated the field',
          type: 'success',
        });
      } else {
        addToast({
          title: 'Something went wrong',
          message: res.error,
          type: 'error',
        });
      }
    }

    setIsfieldModel(false);
  };
  const buttonClick = () => {
    const { pathname } = window.location;
    if (pathname.includes('/template-makers/template')) {
      setApiCall(edit ? updateNewFieldInTemplate() : addNewFieldInTemplate());
      if (isCreateFieldInMaster) {
        setApiCall(addNewFieldInFields());
      }
    } else if (pathname.includes('/fields')) {
      setApiCall(edit ? updateNewFieldInFields() : addNewFieldInFields());
    } else {
      setApiCall(edit ? updateNewFieldInFieldsets() : addNewFieldInFieldsets());
    }
  };

  const handleHeading = () => {
    switch (type) {
      case FieldTypes.TEXT:
        setTitle(
          edit ? LABELS.FIELD_ACTIONS.TEXT_EDIT : LABELS.FIELD_ACTIONS.TEXT_ADD
        );
        break;
      case FieldTypes.LABEL:
        setTitle(
          edit
            ? LABELS.FIELD_ACTIONS.LABEL_EDIT
            : LABELS.FIELD_ACTIONS.LABEL_ADD
        );
        break;
      case FieldTypes.EMAIL:
        setTitle(
          edit
            ? LABELS.FIELD_ACTIONS.EMAIL_EDIT
            : LABELS.FIELD_ACTIONS.EMAIL_ADD
        );
        break;
      case FieldTypes.NUMBER:
        setTitle(
          edit
            ? LABELS.FIELD_ACTIONS.NUMBER_EDIT
            : LABELS.FIELD_ACTIONS.NUMBER_ADD
        );
        break;
      case FieldTypes.DATE:
        setTitle(
          edit ? LABELS.FIELD_ACTIONS.DATE_EDIT : LABELS.FIELD_ACTIONS.DATE_ADD
        );
        break;
      case FieldTypes.RICH_TEXT:
        setTitle(
          edit
            ? LABELS.FIELD_ACTIONS.RICH_TEXT_EDIT
            : LABELS.FIELD_ACTIONS.RICH_TEXT_ADD
        );
        break;
      case FieldTypes.BOOLEAN:
        setTitle(
          edit
            ? LABELS.FIELD_ACTIONS.BOOL_TEXT_EDIT
            : LABELS.FIELD_ACTIONS.BOOL_TEXT_ADD
        );
        break;
      case FieldTypes.DROPDOWN:
        setTitle(
          edit
            ? LABELS.FIELD_ACTIONS.DROPDOWN_TEXT_EDIT
            : LABELS.FIELD_ACTIONS.DROPDOWN_TEXT_ADD
        );
        break;
      case FieldTypes.FIELDSETS:
        setTitle(
          edit
            ? LABELS.FIELD_ACTIONS.FIELDSETS_EDIT
            : LABELS.FIELD_ACTIONS.FIELDSETS_ADD
        );
        break;
      case FieldTypes.METRICS:
        setTitle(
          edit
            ? LABELS.FIELD_ACTIONS.METRIC_EDIT
            : LABELS.FIELD_ACTIONS.METRIC_ADD
        );
        break;
      case FieldTypes.MEDIA:
        setTitle(
          edit
            ? LABELS.FIELD_ACTIONS.MEDIA_EDIT
            : LABELS.FIELD_ACTIONS.MEDIA_ADD
        );
        break;
      case FieldTypes.TAGS:
        setTitle(
          edit ? LABELS.FIELD_ACTIONS.TAG_EDIT : LABELS.FIELD_ACTIONS.TAG_ADD
        );
        break;
      case FieldTypes.TEXT_AREA:
        setTitle(
          edit
            ? LABELS.FIELD_ACTIONS.TEXT_AREA_EDIT
            : LABELS.FIELD_ACTIONS.TEXT_AREA_ADD
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleHeading();
  }, [type]);

  const handleData = () => {
    switch (type) {
      case FieldTypes.TEXT:
        setBaseData(
          baseSettings({
            data: fields,
            setFields,
            fieldSlug: FIELD_SLUG.FIELD_TEXT,
            setIsError,
            existingFields: existingTemplateFields,
          })
        );
        setFieldAdvData(advancedTextFields(fields, setFields, setIsError));
        break;
      case FieldTypes.LABEL:
        setBaseData(
          baseLabelSetting({
            data: fields,
            setFields,
            fieldSlug: FIELD_SLUG.FIELD_LABEL,
            setIsError,
            existingFields: existingTemplateFields,
          })
        );
        setFieldAdvData(advanceLabelSetting(fields, setFields, setIsError));
        break;
      case FieldTypes.EMAIL:
        setBaseData(
          baseMailSetting({
            data: fields,
            setFields,
            fieldSlug: FIELD_SLUG.FIELD_EMAIL,
            setIsError,
            existingFields: existingTemplateFields,
          })
        );
        setFieldAdvData(advancedTextFields(fields, setFields, setIsError));
        break;
      case FieldTypes.NUMBER:
        setBaseData(
          baseNumberSetting({
            data: fields,
            setFields,
            fieldSlug: FIELD_SLUG.FIELD_NUM,
            setIsError,
            existingFields: existingTemplateFields,
          })
        );
        setFieldAdvData(AdvancedNumberFields(fields, setFields, setIsError));
        break;
      case FieldTypes.DATE:
        setBaseData(
          DateBaseSettings({
            data: fields,
            setFields,
            setIsError,
            existingFields: existingTemplateFields,
            templateFieldsArray: ticket_template_fields_list_view?.data || [],
            onOpenDateValidationDrawer: (dateDrawerData) => {
              dispatch(setActiveModal({ targetModalId: 'dateValidationDrawer' }));
              dispatch(updateViewState({
                stateKey: 'dateValidationDrawer',
                value: {
                  ...dateDrawerData,
                }
              }));
            }
          })
        );
        setFieldAdvData(AdvanceDateFields(fields, setFields));
        break;
      case FieldTypes.RICH_TEXT:
        setBaseData(
          richTextBaseSettings({
            data: fields,
            setFields,
            setIsError,
            existingFields: existingTemplateFields,
          })
        );
        setFieldAdvData(advancedRichTextFields(fields, setFields));
        break;
      case FieldTypes.DROPDOWN:
        // setCollectionData({
        //   ...collectionData,
        //   collection_type: collectionData?.collection_type || DROPDOWN_TYPES.STATIC,
        // });
        setCollectionData((pre) => ({
          ...pre,
          collection_type:
            collectionData?.collection_type || DROPDOWN_TYPES.STATIC,
        }));
        setBaseData(
          BasicDropdownSetting({
            data: fields,
            setFields,
            setIsError,
            teamsDetail,
            setTeamsDetail,
            collectionData,
            setCollectionData,
            name,
            existingFields: existingTemplateFields,
            specialWorkflowData: checkIfSpecialWorkflowExists(),
          })
        );
        setFieldAdvData(AdvanceDropdownSeeting(fields, setFields, setIsError));
        break;
      case FieldTypes.BOOLEAN:
        setBaseData(
          BooleanBasicSetting({
            data: fields,
            setFields,
            setIsError,
            existingFields: existingTemplateFields,
          })
        );
        setFieldAdvData(advancedBoolField(fields, setFields));
        break;
      case FieldTypes.MEDIA:
        setBaseData(
          mediaBasicSetting({
            data: fields,
            setFields,
            setIsError,
            existingFields: existingTemplateFields,
            isError,
            shouldRenderPrimaryMediaField:
              !window.location.pathname.includes('/fields'),
          })
        );
        setFieldAdvData(AdvancedMediaField(fields, setFields));
        break;
      // case FieldTypes.FIELDSETS:
      //   setFieldsetData(AddFieldsetContent());
      //   break;
      case FieldTypes.TAGS:
        setCollectionData({
          ...collectionData,
          collection_type:
            collectionData?.collection_type === TAG_TYPES.DYNAMIC
              ? TAG_TYPES.DYNAMIC
              : TAG_TYPES.STATIC,
        });
        setBaseData(
          BasicTagSetting({
            data: fields,
            setFields,
            setIsError,
            collectionData,
            setCollectionData,
            existingFields: existingTemplateFields,
          })
        );
        setFieldAdvData(AdvanceTagSeeting(fields, setFields, setIsError));
        break;
      case FieldTypes.TEXT_AREA:
        setBaseData(
          baseSettings({
            data: fields,
            setFields,
            fieldSlug: FIELD_SLUG.FIELD_TEXT_AREA,
            setIsError,
            existingFields: existingTemplateFields,
          })
        );
        setFieldAdvData(advancedTextAreaFields(fields, setFields, setIsError));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  // validation
  const checkIsAllRequireTrue = () => {
    const result = {};
    let isNotDuplicateLabelOrValue = true;
    const staticCheck = [];

    if (
      collectionData?.collection_type === DROPDOWN_TYPES.STATIC
    ) {
      if (!collectionData?.staticValues) {
        collectionData.staticValues = [];
      }
      let deepCopyStaticValue = collectionData?.staticValues;
      if (!deepCopyStaticValue.length) deepCopyStaticValue = [{}];
      const { duplicateValue, duplicateLabel, duplicateRank } =
        isDuplicatesLabelValue(collectionData.staticValues);
      isNotDuplicateLabelOrValue = !(
        duplicateLabel ||
        duplicateValue ||
        duplicateRank
      );

      if (
        deepCopyStaticValue.find(
          ({ rank }) => rank > deepCopyStaticValue.length
        )
      ) {
        Object.assign(result, {
          isStaticRankValueNumber: true,
        });
        staticCheck.push(false);
      }

      Object.assign(result, {
        isDuplicateStaticValue:
          duplicateValue || duplicateLabel || duplicateRank,
      });
      const errors = validateDropDownOptions(deepCopyStaticValue);

      if (errors.find((error) => error.rank || error.label || error.value)) {
        Object.assign(result, {
          staticListErrors: errors,
        });
        staticCheck.push(false);
      }
    }
    const errorRowOne = baseData?.row1?.map((each) => {
      if (each.required) {
        if (each.key === 'label') {
          // validate the field label length below 63 characters
          if (fields[each.key]?.length > 63) {
            showToastMessage({
              description: 'Maximum 63 characters are allowed',
              status: 'info',
            });
            return false;
          }
          // validate field label can not be same as field, sla or any other metric name
          const validationMessage = validateSlaMetricFieldCannotHaveSameName(
            fields[each.key]?.toLowerCase(), selectedTemplate, fields.id
          );
          if (validationMessage) {
            showToastMessage({
              description: validationMessage,
              status: 'info',
            });
            return false;
          }
          if (!fields[each.key]?.trim()) {
            Object.assign(result, { [each.key]: true });
            return false;
          }
        }
        if (!fields[each.key]) {
          Object.assign(result, { [each.key]: true });
          return false;
        }
      }

      // Check for the label error
      if (isError?.[each.valueKey]) {
        Object.assign(result, { [each.key]: true });
        return false;
      }

      Object.assign(result, { [each.key]: false });
      return true;
    });
    const errorRowTwo = baseData?.row2?.map((each) => {
      if (each.required) {
        if (
          fields.type === FieldTypes.RICH_TEXT &&
          (fields[BASE_SETTINGS_KEYS.IS_ADD_ATTACHMENT] === false ||
            fields[BASE_SETTINGS_KEYS.IS_ADD_ATTACHMENT] === undefined)
        ) {
          Object.assign(result, {
            [each.key]: false,
          });
          return true;
        }
        if (!fields[each.key]) {
          Object.assign(result, { [each.key]: true });
          return false;
        }
      }
      return true;
    });
    const errorRowThree = baseData?.row3?.map((each) => {
      if (each.key === FIELD_VALIDATE_KEY.MEDIA_SIZE) {
        const isSizeValid = isMediaSizeValid(fields[each.key]);
        Object.assign(result, {
          [each.key]: !isSizeValid,
        });
        return isSizeValid;
      }
      if (each.key === FIELD_VALIDATE_KEY.MAX_NUMBER_ATTACHMENT) {
        const isCountValid = isAttachmentCountValid(fields[each.key]);
        Object.assign(result, {
          [each.key]: !isCountValid,
        });
        return isCountValid;
      }
      Object.assign(result, { [each.key]: false });
      return true;
    });
    const errorAdv = fieldAdvData?.row2?.map((each) => {
      if (
        fields[each.key] &&
        fields[FIELD_VALIDATE_KEY.MAX_LENGTH] &&
        each.key === FIELD_VALIDATE_KEY.MIN_LENGTH &&
        each.errorString === LABELS.ERROR.MIN_LENGTH
      ) {
        Object.assign(result, {
          [each.key]: !(
            parseInt(fields[each.key], 10) <
            parseInt(fields[FIELD_VALIDATE_KEY.MAX_LENGTH], 10)
          ),
        });
        return (
          parseInt(fields[each.key], 10) <
          parseInt(fields[FIELD_VALIDATE_KEY.MAX_LENGTH], 10)
        );
      }
      if (
        fields[each.key] &&
        fields[FIELD_VALIDATE_KEY.MIN_LENGTH] &&
        each.key === FIELD_VALIDATE_KEY.MAX_LENGTH &&
        each.errorString === LABELS.ERROR.MAX_LENGTH
      ) {
        Object.assign(result, {
          [each.key]: !(
            parseInt(fields[each.key], 10) >
            parseInt(fields[FIELD_VALIDATE_KEY.MIN_LENGTH], 10)
          ),
        });
        return (
          parseInt(fields[each.key], 10) >
          parseInt(fields[FIELD_VALIDATE_KEY.MIN_LENGTH], 10)
        );
      }
      if (
        fields[each.key] &&
        fields[FIELD_VALIDATE_KEY.MAX_ROW] &&
        each.key === FIELD_VALIDATE_KEY.MIN_ROW &&
        each.errorString === LABELS.ERROR.MIN_ROW
      ) {
        Object.assign(result, {
          [each.key]: !(
            parseInt(fields[each.key], 10) <
            parseInt(fields[FIELD_VALIDATE_KEY.MAX_ROW], 10)
          ),
        });
        return (
          parseInt(fields[each.key], 10) <
          parseInt(fields[FIELD_VALIDATE_KEY.MAX_ROW], 10)
        );
      }
      if (
        fields[each.key] &&
        fields[FIELD_VALIDATE_KEY.MIN_ROW] &&
        each.key === FIELD_VALIDATE_KEY.MAX_ROW &&
        each.errorString === LABELS.ERROR.MAX_ROW
      ) {
        Object.assign(result, {
          [each.key]: !(
            parseInt(fields[each.key], 10) >
            parseInt(fields[FIELD_VALIDATE_KEY.MIN_ROW], 10)
          ),
        });
        return (
          parseInt(fields[each.key], 10) >
          parseInt(fields[FIELD_VALIDATE_KEY.MIN_ROW], 10)
        );
      }
      Object.assign(result, { [each.key]: false });
      return true;
    });

    if (
      fields.type === 'NUMBER' &&
      fields.default_value
    ) {
      let defaultError;
      const max = parseFloat(fields.max_length, 10) || Number.MAX_SAFE_INTEGER;
      const min = parseFloat(fields.min_length, 10) || Number.MIN_SAFE_INTEGER;

      if (
        max &&
        max < parseFloat(fields.default_value, 10)
      ) {
        defaultError = `Default value cannot be greater than ${max}`;
      }
      if (
        min &&
        min > parseFloat(fields.default_value, 10)
      ) {
        defaultError = `Default value cannot be smaller than ${min}`;
      }
      Object.assign(result, {
        [`${BASE_SETTINGS_KEYS.DEFAULT_VALUE}`]: !!defaultError,
        [`${BASE_SETTINGS_KEYS.DEFAULT_VALUE}_message`]: defaultError,
      });
      if (defaultError) {
        SettingsTabTypes !== SettingsTabTypes.BASIC && setActiveSetting(SettingsTabTypes.BASIC);
        staticCheck.push(false);
      } else {
        staticCheck.push(true);
      }
    }

    if (
      fields.type === 'TEXT' &&
      fields.default_value
    ) {
      let defaultError;
      const max = parseFloat(fields.max_length, 10) || Number.MAX_SAFE_INTEGER;
      const min = parseFloat(fields.min_length, 10) || Number.MIN_SAFE_INTEGER;

      if (
        max &&
        max < parseFloat(fields.default_value?.length, 10)
      ) {
        defaultError = `Default value cannot be greater than ${max}`;
      }
      if (
        min &&
        min > parseFloat(fields.default_value?.length, 10)
      ) {
        defaultError = `Default value cannot be smaller than ${min}`;
      }
      Object.assign(result, {
        [`${BASE_SETTINGS_KEYS.DEFAULT_VALUE}`]: !!defaultError,
        [`${BASE_SETTINGS_KEYS.DEFAULT_VALUE}_message`]: defaultError,
      });
      if (defaultError) {
        SettingsTabTypes !== SettingsTabTypes.BASIC && setActiveSetting(SettingsTabTypes.BASIC);
        staticCheck.push(false);
      } else {
        staticCheck.push(true);
      }
    }

    setIsError(result);
    const cur = [
      ...(errorRowOne || []),
      ...(errorRowTwo || []),
      ...(errorRowThree || []),
      ...(errorAdv || []),
      isNotDuplicateLabelOrValue,
      ...staticCheck,
    ].every((item) => item);
    setIsActive(cur);
    return cur;
  };
  const finishCheck = () => {
    const row1 = baseData?.row1?.map((each) => {
      if (each.required) {
        if (fields[each.key]) {
          return true;
        }
        return false;
      }
      return true;
    });
    const row2 = baseData?.row2?.map((each) => {
      if (each.required) {
        if (fields[each.key]) {
          return true;
        }
        return false;
      }
      return true;
    });
    const row3 = baseData?.row3?.map((each) => {
      if (each.required || collectionData[each.key]) {
        if (fields[each.key] || collectionData[each.key]?.length) {
          return true;
        }
        return false;
      }
      if (
        fields[each.key] &&
        each.key === FIELD_VALIDATE_KEY.DEFAULT_VALUE &&
        !each.required
      ) {
        if (each.errorString === LABELS.ERROR.EMAIL_FORMAT) {
          return !isError[each.key];
        }
        if (each.errorString === LABELS.ERROR.NUMBER_FORMAT) {
          return !isError[each.key];
        }
        if (each.errorString === LABELS.ERROR.VALUE_USED) {
          return !isError[each.key];
        }
        return true;
      }
      if (
        fields[each.key] &&
        each?.key === FIELD_VALIDATE_KEY.MEDIA_SIZE &&
        !each?.required
      ) {
        if (each.errorString === LABELS.ERROR.MEDIA_SIZE) {
          return !isError[each.key];
        }
      }
      return true;
    });
    const advRow2 = fieldAdvData?.row2?.map((each) => {
      if (
        fields[each.key] &&
        each.key === FIELD_VALIDATE_KEY.MIN_LENGTH &&
        each.errorString === LABELS.ERROR.MIN_LENGTH
      ) {
        return !isError[each.key];
      }
      if (
        fields[each.key] &&
        each.key === FIELD_VALIDATE_KEY.MAX_LENGTH &&
        each.errorString === LABELS.ERROR.MAX_LENGTH
      ) {
        return !isError[each.key];
      }
      if (
        fields[each.key] &&
        each.key === FIELD_VALIDATE_KEY.MIN_ROW &&
        each.errorString === LABELS.ERROR.MIN_ROW
      ) {
        return !isError[each.key];
      }
      if (
        fields[each.key] &&
        each.key === FIELD_VALIDATE_KEY.MAX_ROW &&
        each.errorString === LABELS.ERROR.MAX_ROW
      ) {
        return !isError[each.key];
      }
      return true;
    });

    setIsActive(
      [
        ...(row1 || []),
        ...(row2 || []),
        ...(row3 || []),
        ...(advRow2 || []),
      ].every((item) => item)
    );
  };

  useEffect(finishCheck, [fields]);
  // validation end

  const onFinshClick = () => {
    const bool = checkIsAllRequireTrue();

    if (bool) {
      buttonClick();
    } else {
      setIsLoadingForApiCall(false);
    }
  };

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={() => setIsfieldModel(false)}
      isOpen={isFieldModel}
      id="DrawerViewZModal"
      closeOnOverlayClick={false}
      blockScrollOnMount={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Flex
            alignItems="center"
            gap="10px"
            borderBottom={`1px solid ${RULE_CARD_BORDER}`}
            pb="10px"
          >
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => {
                setIsfieldModel(false);
                if (edit === false) {
                  setIsAddField(true);
                }
              }}
            />
            <Text fontSize="16px">{title}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <FieldContent
            type={type}
            fields={fields}
            setFields={setFields}
            isActive={isActive}
            setIsActive={setIsActive}
            isError={isError}
            baseData={baseData}
            fieldData={fieldAdvData}
            // fieldsetData={fieldsetData}
            isActiveButton={isActiveButton}
            checkIsAllRequireTrue={checkIsAllRequireTrue}
            activeSetting={activeSetting}
            setActiveSetting={setActiveSetting}
            SETTINGS_TABS={SETTINGS_TABS}
            SETTINGS_TABS_LABEL_MAP={SETTINGS_TABS_LABEL_MAP}
            setCollectionData={setCollectionData}
            collectionData={collectionData}
            edit={edit}
            setIsError={setIsError}
          />
        </DrawerBody>
        <DrawerFooter>
          <Flex gap="8px" pt="10px">
            {window.location.pathname.includes('/template-makers/template') &&
              edit === false && (
                <CustomCheckBox
                  isChecked={isCreateFieldInMaster}
                  checkBoxText={ACTION_LABELS.ADD_FIELD_IN_MASTER}
                  onChange={(e) => {
                    setIsCreateFieldInMaster(e.target.checked);
                  }}
                  size="md"
                  style={{ position: 'absolute', left: 30 }}
                >
                  <span className="text-[14px] block">
                    {ACTION_LABELS.ADD_FIELD_IN_MASTER}
                  </span>
                </CustomCheckBox>
              )}

            <SecondaryCustomButton
              onClick={() => {
                setIsfieldModel(false);
                if (edit === false) {
                  setIsAddField(true);
                }
              }}
              buttonText={LABELS.ACTIONS.CANCEL}
              id="AddEditLayoutSectionModalNameCnclBtn"
            />
            <PrimaryCustomButton
              buttonText={LABELS.ACTIONS.FINISH}
              onClick={() => {
                setIsLoadingForApiCall(true);
                onFinshClick(apiCall);
              }}
              isLoading={isLoadingForApiCall}
              id="CreateEditFieldModalFinishBtn"
            />
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

CreateEditFieldModal.propTypes = {
  type: PropTypes.string.isRequired,
  fieldData: PropTypes.instanceOf(Object),
  edit: PropTypes.bool,
  isFieldModel: PropTypes.bool.isRequired,
  setIsfieldModel: PropTypes.func.isRequired,
  setIsAddField: PropTypes.func,
};
CreateEditFieldModal.defaultProps = {
  edit: false,
  fieldData: {},
  setIsAddField: () => {},
};

export default CreateEditFieldModal;
