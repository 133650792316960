import React from 'react';
import {
  Box, FormLabel, Radio,
  RadioGroup
} from '@chakra-ui/react';
import CustomInput from '../../../components/InputBox/Input';
import TextAreaBox from '../../DynamicRenderer/TextArea';
import { ENCODING_TYPE } from '../../DynamicRenderer/customEventAndTriggers/customEventAndTriggers.constant';

const renderWebhookForm = (
  actionToPerform,
  handleChange,
  actionToPerformSelectedIndex,
  handleActionErrorData,
  actionErrorData
) => (
<>
    <FormLabel
      fontWeight={600}
      className="ellipsis"
      data-testid="DynamicRendererWebhookFormlabel"
    >
      Webhook
    </FormLabel>
    <Box
      p="15px"
      border="1px solid #E2E8F0"
      borderRadius="6px"
      data-testid="DynamicRendererWebhookBox"
    >
      <Box
        marginTop="10px"
        data-testid="DynamicRendererWebhookCIBox"
      >
        <CustomInput
          label="URL"
          value={actionToPerform.url}
          onChange={(e) => {
            handleChange(
              e.target.value,
              `action_to_perform[${actionToPerformSelectedIndex}].url`
            );
            handleActionErrorData('url');
          }}
          isError={!!actionErrorData?.url}
          errorMessage={actionErrorData?.url || ''}
          isMandatory
          id="DynamicRenderer8DivCInput"
        />
      </Box>
      <Box
        marginTop="10px"
        data-testid="DynamicRendererWebhookTypeCIBox"
      >
        <CustomInput
          label="Request Type"
          value={actionToPerform.request_type}
          onChange={(e) => {
            handleChange(
              e.target.value,
              `action_to_perform[${actionToPerformSelectedIndex}].request_type`
            );
            handleActionErrorData('request_type');
          }}
          isError={!!actionErrorData?.request_type}
          errorMessage={actionErrorData?.request_type || ''}
          isMandatory
          id="DynamicRenderer9DivCInput"
        />
      </Box>
      <Box
        marginTop="10px"
        data-testid="DynamicRendererWebhookEndoingBox"
      >
        <FormLabel
          fontWeight={600}
          className="ellipsis"
          data-testid="DynamicRendererWebhookLabelForm"
        >
          Encoding
        </FormLabel>
        <RadioGroup
          // className={styles.radiobutton}
          value={actionToPerform.encoding}
          onChange={(value) => handleChange(
            value,
            `action_to_perform[${actionToPerformSelectedIndex}].encoding`
          )}
          data-testid="DynamicRendererWebhookRadioGrp"
        >
          <Box display="flex" gap="20px">
          <Radio
            value={ENCODING_TYPE.JSON}
            key={ENCODING_TYPE.JSON}
            data-testid="DynamicRendererWebhookJSONRadio"
          >
            {ENCODING_TYPE.JSON}
          </Radio>
          <Radio
            value={ENCODING_TYPE.XML}
            key={ENCODING_TYPE.XML}
            data-testid="DynamicRendererWebhookXMLRadio"
          >
            {ENCODING_TYPE.XML}
          </Radio>
          </Box>
        </RadioGroup>
      </Box>
      <Box
        marginTop="10px"
        data-testid="DynamicRendererWebhookTextAreaBox"
      >
        <TextAreaBox
          rows={10}
          value={actionToPerform.insert_data}
          label="Insert Data"
          onChange={(e) => {
            handleChange(
              e.target.value,
              `action_to_perform[${actionToPerformSelectedIndex}].insert_data`
            );
            handleActionErrorData('insert_data');
          }}
          isMandatory
        />
        {actionErrorData?.insert_data ? (
          <span className="field-error">
            {actionErrorData.insert_data}
          </span>
        ) : null}
      </Box>
    </Box>
</>
);

export default renderWebhookForm;
