import React from "react"
import TableColumnText from "../../../../components/Text/TableColumnText"
import { EditIcon } from '@chakra-ui/icons';
import { Text, Box } from "@chakra-ui/react";
import { deleteRenderer } from "../../../DynamicRenderer/MetricsAndSLA/Metrics/utils";
import { getTenantData } from "../../../../helpers/tenantUrl";
import { axiosInstanceWithoutBaseUrl } from "../../../../utils/axios/axios";
const publishedStyles={color:'#229F54',padding:'2px 5px',backgroundColor:'#DFF7E9',borderRadius:'4px'}
const draftStyles={color:'#6B7280',padding:'2px 5px',backgroundColor:'#E5E7EB',borderRadius:'4px'}
export const COLUMNS_TRIGGERS=({onEdit,onDelete,showWarning})=>[
    {
        name: 'Offering',
        label: 'Offering',
        type: 'string',
        cellRenderer: ({ rowData }) => <TableColumnText text={rowData?.name} />,
    },
    {
        name: 'Associated Flow',
        label: 'Associated Flow',
        type: 'string',
         cellRenderer: ({rowData}) => <TableColumnText text={rowData?.associateFlow?rowData?.associateFlow:'--'} />,
    },
    {
        name: 'Trigger Keywords/Phrases',
        label: 'Trigger Keywords/Phrases',
        type: 'string',
        cellRenderer: ({ rowData }) => <TableColumnText text={(rowData?.keys && rowData?.keys.length>0)?rowData.keys.map(item => item.split(',')).flat().join(', '):'--'} />,
    },
    {
        name: 'Trigger Context',
        label: 'Trigger Context',
        type: 'string',
        cellRenderer: ({ rowData }) => {
            const text = rowData?.context ? rowData.context : '--';
            const words = text.split(' ');
    
            const displayText = words.length > 7 
                ? words.slice(0, 10).join(' ') + '...' 
                : text;
    
            return <TableColumnText text={displayText} />;
        },
    },
    {
        name: 'Created On',
        label: 'Created On',
        type: 'string',
        cellRenderer: ({ rowData }) => <TableColumnText text={rowData?.created?rowData?.created:'--'} />,
    },
    {
        name: 'Status',
        label: 'Status',
        type: 'string',
        cellRenderer: ({ rowData }) => <Text as="span" style={rowData?.status==='Published'?(publishedStyles):(rowData?.status==='Draft'?(draftStyles):{})}>{rowData?.status?rowData?.status:'--'}</Text>,
    },
    {
        name: 'Actions',
        label: 'Actions',
        type: 'string',
        cellRenderer: ({ rowData }) => (
            <Box  className="flex gap-[10px] action-buttons" justify-content="flex-start">
                <EditIcon className="cursor-pointer" onClick={()=>{onEdit(rowData)}} />
                {deleteRenderer({
                    title: 'Delete this trigger',
                    description: 'Are you sure you want to delete this trigger?',
                    onConfirm: () => {
                        showWarning(rowData)
                    //   onDelete(rowData);
                    },
                    dataTestId: `offers-${rowData.name}-delete`
                  })}
            </Box>
        )
    },
    
]

export const isDefaultOffer = async (offers,name,flow,flag) =>{
    let defaultOffer = offers?.find((data)=> data?.offer_type === "template")
    if(defaultOffer?.name === name && flow && flag){
       const tenantInformation = getTenantData();
       await axiosInstanceWithoutBaseUrl.post(`/botConfig/publish`,{
        tenantId : tenantInformation?.id,
        botId: "default",
        configType: "defaultSetting",
        properties: [`${flow}`],
        fullName: "createTicketV2Flow",
      })        
    }
}