/* eslint-disable import/prefer-default-export */
import React from 'react';

import { Avatar, Flex, Text, Tooltip, Box } from '@chakra-ui/react';
import {
  // CopyIcon, DeleteIcon, EditIcon,
  StarIcon
} from '@chakra-ui/icons';
import {
  TrashIcon,
  PencilSquareIcon,
  Square2StackIcon,
  CheckCircleIcon } from '@heroicons/react/24/outline';

import dayjs from 'dayjs';

import TableColumnText from '../../../components/Text/TableColumnText';

import { getColorForStatus } from '../utils';
import { CARD_LISTS_TOOLTIP_LABELS } from '../../../utils/enums/labels';

import styles from '../cardLayout.module.scss';
import { getCreatedByInfo } from '../helpers';
import { isCurrentUserTemplateOwner } from '../../../utils/helper/roleBasedAccess';


export const renderFavorite = ({ isFavorite, toggleFavorite }) => (
    <Box
      className={styles.starIconStyle}
    >
    <Tooltip
      label={
        isFavorite
          ? CARD_LISTS_TOOLTIP_LABELS.UNMARK_AS_FAVORITE
          : CARD_LISTS_TOOLTIP_LABELS.MARK_AS_FAVORITE
      }
      placement="top"
      hasArrow
    >
    {isFavorite ? (
      <StarIcon
        color="yellow"
        width="18"
        height="18px"
        onClick={(e) => {
          e.stopPropagation();
          toggleFavorite();
        }}
      />
    ) : (
      <StarIcon
        color="white"
        width="18px"
        height="18px"
        onClick={(e) => {
          e.stopPropagation();
          toggleFavorite();
        }}
      />
    )}
    </Tooltip>
    </Box>
    // {/* <IconButton
    //   className={styles.starIconStyle}
    //   icon={
    //     isFavorite ? <StarIcon color="yellow" /> : <StarIcon color="white" />
    //   }
    //   onClick={(e) => {
    //     e.stopPropagation();
    //     toggleFavorite();
    //   }}
    // /> */}
);

export const scrollToTemplate = (parentRef, templateId, isScrolled) => {
  if (templateId) {
    const element = parentRef.current?.querySelector(`#template_${templateId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'center' });
      isScrolled.current = true;
    }
  }
};

export const renderStatus = (lifeCycle = '') => (
  <Text
    className={`${styles.statusStyle} ${getColorForStatus(
      lifeCycle
    )} rounded-[3px] inline`}
  >
    {lifeCycle.split('_').join(' ')}
  </Text>
);

export const renderBagde = (props) => {

  return (<Box w={'130px'} maxW={'130px'} >
          <Flex justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
         {props.defaultFlag && <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Box width={'59px'} height={'23px'} bgColor={'#DEE8FC'} color={'#2563EB'} borderRadius={'4px'} px={'5px'} py={'2px'} mr={"5px"}>
          <Text fontWeight={'500'} fontSize={'14px'}>Default</Text>
          </Box> 
          </Flex>}
          {!props.defaultFlag && props.enableDefault && props.rowIds.includes(props.rowId) && 
          <Flex className='new-column-content' alignItems={'center'} justifyContent={'center'} height={'23px'} width={'121px'} borderRadius={'50px'} border={'1px solid #D1D5DB'} px={'5px !important'} py={'3px !important'} mr={'5px'} onClick={(e) => {
          e.stopPropagation();
          props.toggleMarker(props.rowId); 
          }}>
         <Flex justifyContent={'center'} alignItems={'center'}>
          <Flex width={"19.5px"} height={"19.5px"} justifyContent={'center'} alignItems={'center'}>
          <CheckCircleIcon color="#94A3B8" width={"16.5px"} height={"19.5px"} pb={"3px"} className="cursor-pointer"/>
          </Flex>
          <Text color="#6B7280" lineHeight="14.52px" fontWeight="500" fontSize="12px" pr="2px">Mark As Default</Text>
         </Flex>
          </Flex> }
          </Flex>
          </Box>)
};

export const columns = ({
  favouriteMap,
  onEdit,
  onDelete,
  onClone,
  toggleFavorite,
  toggleMarker,
  showWarning,
  rowIds
}) => {
  const fetchTenant = JSON.parse(localStorage.getItem('tenant')) ?? false
  let rolesSettings = false
  if(fetchTenant){
     rolesSettings = fetchTenant?.defaultProducts?.find((data)=>data.product === 'mainApp')?.roles?.includes("Tenant Admin") ?? fetchTenant?.defaultProducts?.find((data)=>data.product === 'ticketApp')?.roles?.includes("Ticket Admin") ?? false
  }
  const cols = [
    {
      col_name: 'Ticket Type Title',
      cellRenderer: ({ rowData }) => <TableColumnText text={rowData?.name} showLength={18}/>,
    },
    {
      col_name: '',
      cellRenderer: ({ rowData }) => renderBagde({rowId:rowData.id,enableDefault:rowData?.lifecycle_status ===  "APPROVED" && rolesSettings,defaultFlag:rowData?.properties?.defaultTicket?.defaultTicketBadge === true,toggleMarker,rowIds}) 
    },
    {
      col_name: 'Favorite',
      cellRenderer: ({ rowData }) =>
        renderFavorite({
          isFavorite: favouriteMap[rowData.id],
          toggleFavorite: () => toggleFavorite(rowData.id),
        }),
    },
    {
      col_name: 'Description',
      cellRenderer: ({ rowData }) => (
        <TableColumnText text={rowData?.properties?.description} showLength={25} />
      ),
    },
    {
      col_name: 'Prefix',
      cellRenderer: ({ rowData }) => (
        <TableColumnText text={rowData?.properties?.prefix} showLength={10} />
      ),
    },
    {
      col_name: 'Status',
      cellRenderer: ({ rowData }) => renderStatus(rowData?.lifecycle_status),
    },
    // {
    //   col_name: 'Created By',
    //   cellRenderer: ({ rowData }) => {
    //     if (!rowData?.properties?.owners) {
    //       return null;
    //     }
    // {
    //   col_name: 'Created By',
    //   cellRenderer: ({ rowData }) => {
    //     if (!rowData?.properties?.owners) {
    //       return null;
    //     }

    //     return (
    //       <Flex gap="10px" alignItems="center">
    //         <Avatar
    //           size="xs"
    //           name={rowData?.created_by || rowData?.properties?.owners[0] || ''}
    //         />
    //         <TableColumnText text={getCreatedByInfo(rowData)} />
    //       </Flex>
    //     );
    //   },
    // },
    //     return (
    //       <Flex gap="10px" alignItems="center">
    //         <Avatar
    //           size="xs"
    //           name={rowData?.created_by || rowData?.properties?.owners[0] || ''}
    //         />
    //         <TableColumnText text={getCreatedByInfo(rowData)} />
    //       </Flex>
    //     );
    //   },
    // },
    {
      col_name: 'Last Updated',
      cellRenderer: ({ rowData }) => {
        if (rowData?.updated_on) {
          return (
            <Box
              fontSize="14px"
              color="#6B7280"
              fontWeight="400"
              lineHeight="normal"
            >
              {dayjs(rowData?.updated_on).format('D MMM YYYY, h:mm A')}
            </Box>
          );
        }
        return '';
      },
    },
    {
      col_name: 'Actions',
      cellRenderer: ({ rowData }) => (
          <div className="flex gap-[10px]">
            <Tooltip
              label={CARD_LISTS_TOOLTIP_LABELS.EDIT}
              placement="top"
              hasArrow
            >
              <PencilSquareIcon
                color="#94A3B8"
                data-testid={`ticket-template-${rowData.name}-edit`}
                className="cursor-pointer h-6 w-6"
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(rowData.id, rowData.name);
                }}
              />
            </Tooltip>

            <Tooltip
              label={CARD_LISTS_TOOLTIP_LABELS.CLONE}
              placement="top"
              hasArrow
            >
              <Square2StackIcon
                color="#94A3B8"
                className="cursor-pointer h-6 w-6"
                data-testid={`ticket-template-${rowData.name}-clone`}
                onClick={(e) => {
                  e.stopPropagation();
                  onClone(rowData.id, rowData.name);
                }}
              />
            </Tooltip>

            {!rowData?.wasApproved &&
              isCurrentUserTemplateOwner(rowData, false) && (
                <Tooltip
                  label={CARD_LISTS_TOOLTIP_LABELS.DELETE}
                  placement="top"
                  hasArrow
                >
                  <TrashIcon
                    color="#94A3B8"
                    className="cursor-pointer h-6 w-6"
                    data-testid={`ticket-template-${rowData.name}-delete`}
                    onClick={(e) => {
                      e.stopPropagation();
                      showWarning(rowData)
                      //onDelete(rowData.id);
                    }}
                  />
                </Tooltip>
            )}
          </div>
      ),
    },
  ];

  return cols;
};
