import Joi from 'joi';
import axiosInstance from '../../utils/axios/axios';

const nodeSchema = Joi.object({
  title: Joi.string().required().messages({
    'string.empty': 'Title is required',
    'any.required': 'Title is required',
  }),
  key: Joi.string().guid({ version: 'uuidv4' }).required(),
  position: Joi.number().default(0),
  children: Joi.array().items(Joi.link('#nodeSchema')).optional(),
  isActor: Joi.boolean().optional(),
  imageUrlInfo: Joi.object({
    imageUrl: Joi.string().allow('').optional(),
    blobName: Joi.string().allow('').optional(),
  }).optional(),
  description: Joi.string().optional(),
}).id('nodeSchema');

const validateCreateHierarchy = Joi.object({
  name: Joi.string().required().messages({
    'string.empty': 'Name is required',
    'any.required': 'Name is required',
  }),
  description: Joi.string().required().messages({
    'string.empty': 'Description is required',
    'any.required': 'Description is required',
  }),
  hierarchy: Joi.array().items(nodeSchema).min(1).required().messages({
    'array.min': 'Include at least one node',
    'any.required': 'Include at least one node',
  }),
  is_actor_hierarchy: Joi.boolean().optional(),
});

export { validateCreateHierarchy };

export const emptyHierarchy = {
  name: '',
  description: '',
  hierarchy: [],
};

export const getHierarchy = async (id) => {
  try {
    const filterString = `&$filter=${'id'} eq ${id}`;
    const response = await axiosInstance.get(
      `${'hierarchies/list'}?$top=${1}&$skip=${0}&$select=${'*'?.toString()}&$orderby=${'created_on desc'}${filterString}`
    );
    if (response.data) {
      return {
        error: null,
        response: response?.data,
      };
    }
    return { error: 'some thing went wrong', response: null };
  } catch (error) {
    return {
      error,
      response: null,
    };
  }
};
