// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* toolbar styles */

.dark-mode .ql-toolbar {
    background-color: #2d3748; /* set the background color for the toolbar */
    color: #fff; /* set the text color for the toolbar */
  }
  
  .dark-mode .ql-toolbar .ql-stroke {
    fill: none;
    stroke: #fff;
  }
  
  .dark-mode .ql-toolbar .ql-fill {
    fill: #fff;
    stroke: none;
  }
  
  .dark-mode .ql-toolbar .ql-picker {
    color: #fff;
  }
  
  /* placeholder text styles */
  .dark-mode .ql-editor::before {
    color: #a0aec0; /* set the color for the placeholder text */
  }
  
  .ql-editor {
    overflow-y: scroll;
    resize: vertical;
    height: auto;
	  min-height: 120px;
	  overflow-y: auto;
    max-height: 400px;
  }`, "",{"version":3,"sources":["webpack://./src/components/QuillEditor/quill-styles.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,yBAAyB,EAAE,6CAA6C;IACxE,WAAW,EAAE,uCAAuC;EACtD;;EAEA;IACE,UAAU;IACV,YAAY;EACd;;EAEA;IACE,UAAU;IACV,YAAY;EACd;;EAEA;IACE,WAAW;EACb;;EAEA,4BAA4B;EAC5B;IACE,cAAc,EAAE,2CAA2C;EAC7D;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;GACb,iBAAiB;GACjB,gBAAgB;IACf,iBAAiB;EACnB","sourcesContent":["/* toolbar styles */\r\n\r\n.dark-mode .ql-toolbar {\r\n    background-color: #2d3748; /* set the background color for the toolbar */\r\n    color: #fff; /* set the text color for the toolbar */\r\n  }\r\n  \r\n  .dark-mode .ql-toolbar .ql-stroke {\r\n    fill: none;\r\n    stroke: #fff;\r\n  }\r\n  \r\n  .dark-mode .ql-toolbar .ql-fill {\r\n    fill: #fff;\r\n    stroke: none;\r\n  }\r\n  \r\n  .dark-mode .ql-toolbar .ql-picker {\r\n    color: #fff;\r\n  }\r\n  \r\n  /* placeholder text styles */\r\n  .dark-mode .ql-editor::before {\r\n    color: #a0aec0; /* set the color for the placeholder text */\r\n  }\r\n  \r\n  .ql-editor {\r\n    overflow-y: scroll;\r\n    resize: vertical;\r\n    height: auto;\r\n\t  min-height: 120px;\r\n\t  overflow-y: auto;\r\n    max-height: 400px;\r\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
