import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DrawerContent,
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
  useColorModeValue,
  DrawerFooter,
  DrawerBody,
  Box,
  HStack,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { LABELS } from '../../utils/enums/labels';
import CustomButton from '../../components/Button/SimpleButton';
import { COLORS } from '../../utils/enums/colors';
import CustomInput from '../../components/InputBox/Input';
import CustomSelectBox from '../../components/SelectBox/Select';
import {
  STATUS_OPTION,
  getStatusObject,
  processErrors,
} from '../DynamicRenderer/AddEditSignatureDrawer/utils';
// import DynamicSelectWithInputTags
// from '../../components/dynamicSelectWithInputTags/DynamicSelectWithInputTags';
// import {
//   roleMapping,
//   roleSettingSelectOpt,
// } from '../DynamicRenderer/customTemplateModal/CustomTemplateModalLayout';
import QuillEditor from '../../components/QuillEditor';
import {
  COMMUNICATION_TEMPLATES,
  EMAIL_QUILL_TOOLBAR,
  EMAIL_TEMPLATE_KEY_MAP,
  // SEND_AS_OPTIONS,
  parseAPIToRichTextMention,
  createEmailTemplatePayload,
  createEmailTemplateValidation,
  useMentionObj,
  parseAPIToCustomMention,
  SEND_AS_OPTIONS,
  // parseRichTextMentionToAPI,
  // parseCustomMentionToAPI,
} from './utills';
import {
  patchDataForTemplateWithPath,
} from '../TicketTemplateBuilder/utils/templateAPIUtils';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import CustomMention from '../../components/Mention';
import HTMLPreviewModal from '../../components/PreviewModal/HTMLPreviewModal';
import { NOTES, convertMentionToHTML } from '../../components/Mention/helpers';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../components/Button/PrimarySecondaryButton';

const AddOrEditEmailTemplateDrawer = ({
  setOpenDrawer,
  openDrawer,
  edit,
  selectedEmailTemplateValue,
  type,
}) => {
  const { mentionObj } = useMentionObj({ isEmailForm: true });

  const [stateForEmail, setStateForEmail] = useState({});
  useEffect(() => {
    setStateForEmail({
      ...selectedEmailTemplateValue,
      [EMAIL_TEMPLATE_KEY_MAP.SEND_AS]:
        selectedEmailTemplateValue[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] ?? 'text',
      [EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE]:
      selectedEmailTemplateValue[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] === 'html'
        ? parseAPIToCustomMention(selectedEmailTemplateValue[EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE] || '')
        : parseAPIToRichTextMention(selectedEmailTemplateValue[EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE] || ''),
      [EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY]: selectedEmailTemplateValue[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] === 'html'
        ? parseAPIToCustomMention(selectedEmailTemplateValue[EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY] || '')
        : parseAPIToRichTextMention(selectedEmailTemplateValue[EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY] || ''),
      [EMAIL_TEMPLATE_KEY_MAP.SUBJECT]: parseAPIToCustomMention(
        selectedEmailTemplateValue[EMAIL_TEMPLATE_KEY_MAP.SUBJECT] || ''
      ),
    });
  }, [selectedEmailTemplateValue]);

  const [errorKeyList, setErrorKeyList] = useState({});
  const { selectedTemplate } = useSelector((state) => state.viewState || {});
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const { id: templateId } = useParams();

  const handleOnChange = (value, key) => {
    // if (key.length === 1 && key[0] === EMAIL_TEMPLATE_KEY_MAP.SEND_AS) {
    //   setStateForEmail((pre) => {
    //     const prevSendAs = pre[EMAIL_TEMPLATE_KEY_MAP.SEND_AS];
    //     const prevEmail = pre[EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY];
    //     const prevSignature = pre[EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE];

    //     if (prevSendAs === 'html') {
    //       return {
    //         ...pre,
    //         [key]: value,
    //         [EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY]: parseCustomMentionToAPI(
    //           parseAPIToRichTextMention(prevEmail)
    //         ),
    //         [EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE]: parseCustomMentionToAPI(
    //           parseAPIToRichTextMention(prevSignature)
    //         ),
    //       };
    //     }
    //     return {
    //       ...pre,
    //       [key]: value,
    //       [EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY]: parseRichTextMentionToAPI(
    //         parseAPIToCustomMention(prevEmail)
    //       ),
    //       [EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE]: parseRichTextMentionToAPI(
    //         parseAPIToCustomMention(prevSignature)
    //       ),
    //     };
    //   });
    // } else {
    //   setStateForEmail((pre) => ({
    //     ...pre,
    //     [key]: value,
    //   }));
    //   setErrorKeyList((pre) => ({ ...pre, [key]: false }));
    // }

    setStateForEmail((pre) => ({
      ...pre,
      [key]: value,
    }));
    setErrorKeyList((pre) => ({ ...pre, [key]: false }));
  };
  const addNewEmailTemplateInCommunicationTemplate = async (payload) => {
    setIsLoadingBtn(true);
    // const newData = [
    //   ...(selectedTemplate?.[COMMUNICATION_TEMPLATES] || []),
    //   { ...payload },
    // ];

    const res = await patchDataForTemplateWithPath({
      id: templateId,
      data: {
        path: COMMUNICATION_TEMPLATES,
        operation: 'create',
        data: {
          ...payload,
        },
      },
      defaultErrorMessage:
        'Failed to create a email template in communication template',
      successMessage:
        'Successfully created email template in communication template',
    });

    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
    }
    setIsLoadingBtn(false);
    setOpenDrawer(false);
  };
  const updateEmailTemplateInCommunicationTemplate = async (payload) => {
    setIsLoadingBtn(true);
    const { id } = stateForEmail;

    const editedFieldIndex = [
      ...(selectedTemplate?.[COMMUNICATION_TEMPLATES] || []),
    ].findIndex((item) => item.id === id);

    if (editedFieldIndex !== -1) {
      const updatedFields = [
        ...(selectedTemplate?.[COMMUNICATION_TEMPLATES] || []),
      ];
      updatedFields[editedFieldIndex] = { ...payload };

      const res = await patchDataForTemplateWithPath({
        id: templateId,
        data: {
          path: COMMUNICATION_TEMPLATES,
          operation: 'update',
          id,
          data: {
            ...payload,
          },
        },
        defaultErrorMessage: 'Failed to update the email template',
        successMessage: 'Successfully updated the email template',
      });

      if (!res.error) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
      }
    }
    setIsLoadingBtn(false);
    setOpenDrawer(false);
  };

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={setOpenDrawer}
      isOpen={openDrawer}
      data-testid="AddOrEditEmailTemplateDrawerEmail"
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => setOpenDrawer(false)}
            />
            <Text fontSize="16px">
              {edit ? 'Edit Email Template' : 'Add New  Email Template'}
            </Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <CustomInput
            onChange={(e) => {
              handleOnChange(e.target.value, [EMAIL_TEMPLATE_KEY_MAP.NAME]);
            }}
            value={stateForEmail[EMAIL_TEMPLATE_KEY_MAP.NAME]}
            placeholder=""
            isError={errorKeyList[EMAIL_TEMPLATE_KEY_MAP.NAME]}
            helperText=" "
            label="Name"
            isMandatory
            errorMessage={
              stateForEmail[EMAIL_TEMPLATE_KEY_MAP.NAME]
                ? 'Name is already exist'
                : 'Name is required'
            }
            data-testid="AddOrEditEmailTemplateDrawerFieldName"
            height="38px"
          />
          <Box mt="15px">
            <CustomSelectBox
              value={getStatusObject(
                stateForEmail[EMAIL_TEMPLATE_KEY_MAP.STATUS],
                STATUS_OPTION
              )}
              options={STATUS_OPTION}
              onChange={(e) => {
                handleOnChange(e.value, [EMAIL_TEMPLATE_KEY_MAP.STATUS]);
              }}
              label="Status"
              isMandatory
              isError={errorKeyList[EMAIL_TEMPLATE_KEY_MAP.STATUS]}
              errorMessage="Status is required"
              aria-label="Status"
            />
          </Box>
          {/* <Box mt="20px" w="100%">
            <DynamicSelectWithInputTags
              values={stateForEmail[EMAIL_TEMPLATE_KEY_MAP.TO]}
              label="To"
              onChange={(value) => {
                handleOnChange(value, [EMAIL_TEMPLATE_KEY_MAP.TO]);
              }}
              selectOptions={roleSettingSelectOpt}
              mapping={roleMapping}
            />
            {errorKeyList[EMAIL_TEMPLATE_KEY_MAP.TO] && (
              <span className="field-error">
                Please add at least one item from Org, Team, Queue etc.
              </span>
            )}
          </Box>
          <Box mt="20px" w="100%" mb="20px">
            <DynamicSelectWithInputTags
              values={stateForEmail[EMAIL_TEMPLATE_KEY_MAP.CC]}
              label="Cc"
              onChange={(value) => {
                handleOnChange(value, [EMAIL_TEMPLATE_KEY_MAP.CC]);
              }}
              selectOptions={roleSettingSelectOpt}
              mapping={roleMapping}
            />
          </Box> */}
          <Box mt="15px">
            <CustomMention
              label="Subject"
              isMandatory
              value={stateForEmail[EMAIL_TEMPLATE_KEY_MAP.SUBJECT]}
              onChange={(e) => {
                handleOnChange(e, [EMAIL_TEMPLATE_KEY_MAP.SUBJECT]);
              }}
              placeholder=""
              isError={errorKeyList[EMAIL_TEMPLATE_KEY_MAP.SUBJECT]}
              errorMessage="Subject is required"
              id="AddOrEditEmailTemplateDrawerSubject"
              height="38px"
              isMulti
              multiLine
            />
          </Box>
          <Box mt="10px">
            <CustomSelectBox
              value={getStatusObject(
                stateForEmail[EMAIL_TEMPLATE_KEY_MAP.SEND_AS],
                SEND_AS_OPTIONS
              )}
              options={SEND_AS_OPTIONS}
              onChange={(e) => {
                handleOnChange(e.value, [EMAIL_TEMPLATE_KEY_MAP.SEND_AS]);
              }}
              label="Send as"
              isError={errorKeyList[EMAIL_TEMPLATE_KEY_MAP.SEND_AS]}
              errorMessage="Send as is required"
              aria-label="send-as"
              isDisabled={
                stateForEmail[EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY]
                || stateForEmail[EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE]
              }
            />
          </Box>
          {/* {stateForEmail[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] ===
            EMAIL_TEMPLATE_KEY_MAP.TEXT && (
            <Box mt="10px">
              <CustomTextArea
                value={stateForEmail[EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY] || ''}
                onChange={(e) => {
                  handleOnChange(e.target.value, [
                    EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY,
                  ]);
                }}
                placeholder="Email body content"
                label="Email Body"
                isMandatory
                isError={errorKeyList[EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY]}
                errorMessage=" Email body is required"
              />
            </Box>
          )} */}
          {stateForEmail[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] === 'html' ? (
            <Box mt="20px" w="100%">
              <CustomMention
                label="Email body"
                isMandatory
                value={stateForEmail[EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY]}
                onChange={(e) => {
                  handleOnChange(e, [EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY]);
                }}
                placeholder=""
                isError={errorKeyList[EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY]}
                errorMessage="Email body is required"
                id="AddOrEditEmailTemplateHTMLEditorEmailBody"
                height="260px"
                showHardCodMention={[NOTES]}
                isMulti
                multiLine
              />
            </Box>
          ) : (
            <Box mt="10px">
              <QuillEditor
                editorvalue={
                  stateForEmail[EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY] || ''
                }
                toolBarModules={EMAIL_QUILL_TOOLBAR}
                format=""
                // customEditorContainerStyles={{ height: '120px' }}
                handleOnChange={(value) => {
                  handleOnChange(value, [EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY]);
                }}
                label="Email body"
                isMandatory
                testId="AddOrEditEmailTemplateDrawerQuillEditor"
                mentionObj={mentionObj}
                isError={errorKeyList[EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY]}
                errorMessage="Email body is required"
                showClearButton
              />
            </Box>
          )}
          <Box marginRight="10px" data-testid="AddEmailTemplateModal">
            <CustomButton
              buttonText="Preview"
              variant="outline"
              colorScheme="customBlue"
              style={{
                height: 30,
                marginTop: '8px',
              }}
              color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
              onClick={() => setIsModalOpen(true)}
              id="DynamicRendererSendMailPreviewBtnText"
            />
            <HTMLPreviewModal
              open={isModalOpen}
              closePreviewModal={() => setIsModalOpen(false)}
              title="Email Preview"
              content={isModalOpen ? [
                {
                  label: 'Email Body',
                  html: stateForEmail[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] === 'html'
                    ? convertMentionToHTML(
                      stateForEmail[EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY],
                      selectedTemplate
                    )
                    : stateForEmail[EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY],
                },
                {
                  label: 'Email Signature',
                  html: stateForEmail[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] === 'html'
                    ? convertMentionToHTML(
                      stateForEmail[EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE],
                      selectedTemplate
                    )
                    : stateForEmail[EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE]
                }
              ] : []}
            />
          </Box>
          {stateForEmail[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] === 'html' ? (
            <Box mt="20px" w="100%">
              <CustomMention
                label="Email Signature"
                value={stateForEmail[EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE]}
                onChange={(e) => {
                  handleOnChange(e, [EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE]);
                }}
                placeholder=""
                id="AddOrEditEmailTemplateHTMLEditorSignature"
                height="260px"
              />
            </Box>
          ) : (
            <Box mt="20px" w="100%" pt="20px">
              <QuillEditor
                editorvalue={
                  stateForEmail[EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE] || ''
                }
                toolBarModules={EMAIL_QUILL_TOOLBAR}
                format=""
                // customEditorContainerStyles={{ height: '120px' }}
                handleOnChange={(value) => {
                  handleOnChange(value, [
                    EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE,
                  ]);
                }}
                label="Email Signature"
                testId="AddOrEditEmailTemplateDrawerQuillEditor"
                mentionObj={mentionObj}
                showClearButton
              />
            </Box>
          )}
        </DrawerBody>
        <DrawerFooter>
          <HStack alignItems="center" gap="10px">
            <SecondaryCustomButton
            classname="calculatedHeight"
            // variant="outline"
            pl="17px"
            pr="17px"
            // buttonColor={LABELS.SIGNATURE_DRAWER_COLOR}
            buttonText={LABELS.ACTIONS.DISCARD}
            // style={{
            //   border: 'none',
            //   background: COLORS.SIGNATURE_DRAWER_BG,
            //   height: '38px',
            //   marginRight: '10px',
            //   color: COLORS.SIGNATURE_DRAWER_COLOR,
            // }}
            // _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
            onClick={() => {
              setOpenDrawer(false);
            }}
            size="lg"
          /><PrimaryCustomButton
          classname="calculatedHeight"
          // variant="solid"
          pl="17px"
          pr="17px"
          // style={{ height: '38px' }}
          // buttonColor="customBlue"
          buttonText={LABELS.ACTIONS.ADD}
          onClick={() => {
            setIsLoadingBtn(true);
            const checkNameUnique =
              [...(selectedTemplate?.[COMMUNICATION_TEMPLATES] || [])]
                ?.filter((item) => item.type === type)
                .map((ins) => ins.name) || [];
            const payload = createEmailTemplatePayload(stateForEmail, type);
            const { error } = createEmailTemplateValidation(
              edit ? [] : checkNameUnique
            ).validate(payload, {
              abortEarly: false,
            });
            if (!isEmpty(error)) {
              setIsLoadingBtn(false);
              const tempError = processErrors(error.details);
              setErrorKeyList(tempError);
              return;
            }
            if (edit) {
              updateEmailTemplateInCommunicationTemplate(payload);
            } else {
              addNewEmailTemplateInCommunicationTemplate(payload);
            }
          }}
          size="lg"
          isLoading={isLoadingBtn}
          data-testid="AddOrEditEmailTemplateDrawerButtonAdd"
        />
        </HStack>
          
          
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

AddOrEditEmailTemplateDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  setOpenDrawer: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  selectedEmailTemplateValue: PropTypes.object,
  type: PropTypes.string.isRequired,
};
AddOrEditEmailTemplateDrawer.defaultProps = {
  selectedEmailTemplateValue: {},
  edit: false,
};
export default AddOrEditEmailTemplateDrawer;
