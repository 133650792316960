import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import CustomInput from '../../../components/InputBox/Input';
import CustomSelectBox from '../../../components/SelectBox/Select';
import TableWithAction from '../../../components/Table/TableWithAction';

import {
  generateFieldMap,
  getRelationScopeFields,
} from './relation.helper';
import {
  cardinality,
  cardinalitySelf,
  mandatoryOption,
  optionLabel,
} from '../../../utils/constants';
import RELATION_VALIDATIONS from '../../../utils/validationSchemas/templates/relations';
import styles from './Relation.module.scss';
import {
  PrimaryCustomButton,
  SecondaryCustomButton,
} from '../../../components/Button/PrimarySecondaryButton';
import {
  isEachSimilarityValueMapped,
} from './relation.service';
import CustomToast from '../../../components/Notification/Notification';
import EditRelationTag from './RelationTagInput';
import EditSimilarity from './EditSimilarity';

const EditRelation = ({
  relationData,
  navigateBack,
  templateOptions,
  templateIdMap,
  offerOptionsByTemplateId,
  offerIdMap,
  onSaveRelation,
  isRelationLoading,
  currentTemplate,
  hierarchies,
}) => {
  const { addToast } = CustomToast();

  const [details, setDetails] = useState(relationData || {});
  const [isError, setIsError] = useState(false);

  const cardinalityOptions = useMemo(
    () => {
      if (
        details?.template_id_left
        && details?.template_id_right
        && details?.template_id_left === details?.template_id_right
      ) {
        return cardinalitySelf.map((c) => ({
          label: c.label,
          value: c.id,
        }));
      }

      return cardinality.map((c) => ({
        label: c.label,
        value: c.id,
      }));
    },
    [details?.template_id_left, details?.template_id_right]
  );

  const mandatoryOptions = useMemo(
    () =>
      mandatoryOption.map((c) => ({
        label: c.label,
        value: c.id,
      })),
    []
  );

  const selectedTemplate = useMemo(() => {
    if (!details?.template_id_right) {
      return {};
    }
    return templateIdMap?.[details?.template_id_right];
  }, [details?.template_id_right, templateIdMap]);

  const leftTemplateFields = useMemo(
    () =>
      generateFieldMap(
        [...(currentTemplate?.fields || [])].filter(
          (item) => item.type === 'DROPDOWN' || item.type === 'TEXT_AREA' || item.type === 'TEXT' || item.type === 'RICH_TEXT' 
        )
      ),
    [currentTemplate]
  );

  const rightTemplateFields = useMemo(
    () =>
      generateFieldMap(
        [...(selectedTemplate?.fields || [])].filter(
          (item) => item.type === 'DROPDOWN' || item.type === 'TEXT_AREA' || item.type === 'TEXT' || item.type === 'RICH_TEXT'
        )
      ),
    [selectedTemplate]
  );

  const combinedRelationalFields = useMemo(() => {
    const fields = getRelationScopeFields(
      Object.values(leftTemplateFields),
      currentTemplate?.id
    );

    fields.push(
      ...getRelationScopeFields(
        Object.values(rightTemplateFields),
        selectedTemplate?.id
      )
    );

    // Removing the selected fields
    const customAttributes = details?.customAttributes || [];
    const fieldMap = customAttributes?.reduce((prev, curr) => {
      prev[curr?.field?.value || ''] = true;
      return prev;
    }, {});

    return fields.filter((f) => !fieldMap[f?.value || '']);
  }, [
    leftTemplateFields,
    rightTemplateFields,
    currentTemplate,
    selectedTemplate,
    details?.customAttributes,
  ]);

  const cardinalityMap = useMemo(
    () =>
      cardinality.reduce((prev, curr) => {
        prev[curr.id] = curr.label;
        return prev;
      }, {}),
    []
  );

  const handleChange = (key, value) => {
    if (key.startsWith('default_relation')) {
      // eslint-disable-next-line no-unused-vars
      const [_mainKey, subKey] = key.split('.');
      const otherSubKey = subKey === 'left_to_right' ? 'right_to_left' : 'left_to_right';
      setDetails((prev) => (
        {
          ...prev,
          default_relation: {
            [subKey]: value,
            [otherSubKey]: prev?.default_relation?.[otherSubKey] || '',
          }
        }
      ));
    } else if (key.startsWith('relations_list')) {
      setDetails((prev) => {
        const defRelation = prev?.default_relation || {};

        if (key === 'relations_list_left') {
          defRelation.left_to_right = value.includes(defRelation.left_to_right)
            ? defRelation.left_to_right
            : '';
        }
        if (key === 'relations_list_right') {
          defRelation.right_to_left = value.includes(defRelation.right_to_left)
            ? defRelation.right_to_left
            : '';
        }
        return {
          ...prev,
          [key]: value,
          default_relation: defRelation,
        };
      });
    } else {
      setDetails((prev) => ({
        ...prev,
        [key]: value,
      }));
    }

    setIsError(false);
  };

  // Add Custom Attributes
  const addAttribute = useCallback(() => {
    setDetails((prev) => {
      if (!prev?.field || !prev?.mandatory) {
        return prev;
      }

      const tempdata = cloneDeep(prev?.customAttributes || []);
      tempdata.push({
        field: prev?.field,
        mandatory: prev?.mandatory,
      });

      return {
        ...prev,
        field: null,
        mandatory: '',
        customAttributes: tempdata,
      };
    });
  }, []);
  // Delete Custom Attributes
  const deleteAttribute = useCallback((rowNo) => {
    setDetails((prev) => {
      const tempData = cloneDeep(prev?.customAttributes);
      tempData.splice(rowNo, 1);
      return {
        ...prev,
        customAttributes: tempData,
      };
    });
  }, []);

  useEffect(() => {
    if (details?.template_id_left && !details?.id) {
      handleChange('offer_id_left', 'none');
    }
  }, [details?.template_id_left]);

  useEffect(() => {
    if (details?.template_id_right && !details?.id) {
      handleChange('offer_id_right', 'none');
    }
  }, [details?.template_id_right]);

  const onSave = useCallback(async () => {
    try {
      const result = await isEachSimilarityValueMapped(
        details?.similarities, currentTemplate, hierarchies
      );
      const resultInverse = await isEachSimilarityValueMapped(
        details?.similaritiesInverse, selectedTemplate, hierarchies
      );

      if (result || resultInverse) {
        let errorMessage = 'All value of fields must be mapped in Similarity and Inverse Similarity sections';
        if (!result) {
          errorMessage = 'All value of fields must be mapped in Inverse Similarity section';
        } else if (!resultInverse) {
          errorMessage = 'All value of fields must be mapped in Similarity section';
        }
        addToast({
          title: 'Something went wrong',
          message: errorMessage,
          type: 'error',
          duration: 2000,
          isClosable: true,
        });
        return;
      }

      const { error } = RELATION_VALIDATIONS.validate(details, {
        abortEarly: false,
      });
      if (error) {
        setIsError(true);
        return;
      }

      const customAttributes = details.customAttributes?.map((attr) => ({
        template_id: attr?.field?.template_id,
        field_id: attr?.field?.value,
        isRequired: attr?.mandatory === optionLabel.mandatory,
      }));

      const similarities = // isMapSwap
        [...(details?.similarities || [])].map((sim) => ({
          left_field_id: sim?.fieldA?.value,
          right_field_id: sim?.fieldB?.value,
          mappings: sim?.mappings || [],
        }));

      const similaritiesInverse = // isMapSwap
        [...(details?.similaritiesInverse || [])].map((sim) => ({
          left_field_id: sim?.fieldA?.value,
          right_field_id: sim?.fieldB?.value,
          mappings: sim?.mappings || [],
        }));

      // These keys can be changed from either side of the relationship.
      const payload = {
        name: details.name?.replace(/\s+/g, ' ').trim(),
        cardinality: details?.cardinality,
        custom_attributes: customAttributes,
        similarity: similarities,
        similarity_inverse: similaritiesInverse,
        offer_id_left: details.offer_id_left !== 'none' ? details.offer_id_left : null,
        offer_id_right: details.offer_id_right !== 'none' ? details.offer_id_right : null,
        relations_list_left: details?.relations_list_left || [],
        relations_list_right: details?.relations_list_right || [],
        default_relation: {
          left_to_right: details?.default_relation?.left_to_right || '',
          right_to_left: details?.default_relation?.right_to_left || '',
        },
      };

      // Only update the following keys of the relation
      // if the relation id is not present/it is being created
      if (!details?.id) {
        payload.template_id_left = details.template_id_left || currentTemplate?.id;
        payload.template_id_right = details.template_id_right;
      }

      onSaveRelation(payload, details?.id);
    } catch (error) {
      console.error('Error:', error);
    }
  }, [
    details,
    leftTemplateFields,
    rightTemplateFields,
    onSaveRelation,
  ]);

  return (
    <Box
      className={styles.relationContainer}
      height="calc(100vh - 15rem)"
      overflow="auto"
    >
      <div
        className="flex items-center gap-[5px] mb-[10px]"
        data-testid="relationsEdit"
      >
        <ArrowBackIcon
          className={`
            cursor-pointer
            border
            border-[#2563EB]
            p-1
            rounded-full
            hover:bg-[#2563EB]
            hover:text-white
          `}
          color="#2563EB"
          h="32px"
          w="32px"
          onClick={navigateBack}
          data-testid="RelationsEditModalBackIcon"
        />
        <p className="font-semibold text-[16px]">Relation</p>
      </div>

      <div>
        {/* name of relation  */}
        <div style={{paddingTop:"10px"}}>
        <CustomInput
          id="input-test-id"
          type="text"
          label="Name"
          value={details?.name || ''}
          isMandatory
          onChange={(e) => handleChange('name', e.target.value)}
          className={`${styles.inputBox}  ${styles.width}`}
        />
        </div>
        

        {/* Template Left */}
        <div style={{paddingTop:"5px"}}>
        <CustomSelectBox
          size="sm"
          className={styles.width}
          options={templateOptions}
          value={
            details?.template_id_left
              ? {
                value: details.template_id_left,
                label: templateIdMap[details.template_id_left]?.name,
              }
              : null
          }
          onChange={(e) => {
            if (e.value !== details.template_id_left) {
              handleChange('template_id_left', e.value);
            }
          }}
          label="Template Left"
          isDisabled={details?.id || details?.template_id_left}
          isMandatory
        />
        </div>
        

        {
          details?.template_id_left && (
            <div style={{paddingTop:"15px"}}>
              <CustomSelectBox
              size="sm"
              className={styles.width}
              options={offerOptionsByTemplateId[details?.template_id_left]}
              value={
                details?.offer_id_left
                  ? {
                    value: details.offer_id_left,
                    label: offerIdMap[details.offer_id_left]?.name,
                  }
                  : null
              }
              onChange={(e) => {
                if (e.value !== details.offer_id_left) {
                  handleChange('offer_id_left', e.value);
                }
              }}
              label="Offer Left"
            />
            </div>
            
          )
        }

        {/* Template Right */}
        <div style={{paddingTop:"15px"}}>
        <CustomSelectBox
          size="sm"
          className={styles.width}
          options={templateOptions}
          value={
            details?.template_id_right
              ? {
                value: details.template_id_right,
                label: templateIdMap[details.template_id_right]?.name,
              }
              : null
          }
          onChange={(e) => {
            if (e.value !== details.template_id_right) {
              handleChange('template_id_right', e.value);
            }
          }}
          label="Template Right"
          isDisabled={details?.id}
          isMandatory
        />
        </div>
        

        {
          details?.template_id_right && (
            <div style={{paddingTop:"15px"}}>
              <CustomSelectBox
              size="sm"
              className={styles.width}
              options={offerOptionsByTemplateId[details?.template_id_right]}
              value={
                details?.offer_id_right
                  ? {
                    value: details.offer_id_right,
                    label: offerIdMap[details.offer_id_right]?.name,
                  }
                  : null
              }
              onChange={(e) => {
                if (e.value !== details.offer_id_right) {
                  handleChange('offer_id_right', e.value);
                }
              }}
              label="Offer Right"
            />
            </div>
            
          )
        }

        {/* Select Relationship */}
        <div style={{paddingTop:"15px"}}>
        <EditRelationTag
          relations={details?.relations_list_left || []}
          onChange={(value) => handleChange('relations_list_left', value)}
          label="Relations Left"
        />
        </div>
        
        <EditRelationTag
          relations={details?.relations_list_right || []}
          onChange={(value) => handleChange('relations_list_right', value)}
          label="Relations Right"
        />
        <CustomSelectBox
          label="Default Relation (Left to Right)"
          className={styles.width}
          value={{
            value: details?.default_relation?.left_to_right,
            label: details?.default_relation?.left_to_right,
          }}
          options={(details?.relations_list_left || []).map((item) => ({
            value: item,
            label: item,
          }))}
          onChange={(e) => {
            handleChange('default_relation.left_to_right', e.value);
          }}
        />
       <div style={{paddingTop:"15px"}}>
       <CustomSelectBox
          label="Default Relation (Right to Left)"
          className={styles.width}
          value={{
            value: details?.default_relation?.right_to_left,
            label: details?.default_relation?.right_to_left,
          }}
          options={(details?.relations_list_right || []).map((item) => ({
            value: item,
            label: item,
          }))}
          onChange={(e) => {
            handleChange('default_relation.right_to_left', e.value);
          }}
        />
       </div>
        
        {/* To Select Cardinality */}
        <div style={{paddingTop:"15px"}}>
        <CustomSelectBox
          label="Cardinality"
          className={styles.width}
          value={
            details?.cardinality
              ? {
                value: details.cardinality,
                label: cardinalityMap[details.cardinality],
              }
              : null
          }
          options={cardinalityOptions}
          onChange={(e) => {
            handleChange('cardinality', e.value);
          }}
          isMandatory
        />
        </div>
        

        <div style={{paddingTop:"10px"}} className={styles.title}>Custom Attributes</div>
        <Box className={styles.relationBox}>
          <CustomSelectBox
            options={combinedRelationalFields}
            onChange={(e) => handleChange('field', e)}
            value={details?.field || null}
            placeholder="Select Field"
          />
          <CustomSelectBox
            options={mandatoryOptions}
            onChange={(e) => handleChange('mandatory', e.value)}
            value={
              details?.mandatory
                ? {
                  label: optionLabel[details?.mandatory],
                  value: details?.mandatory,
                }
                : null
            }
            placeholder="Select"
          />
          <SecondaryCustomButton
            buttonText="Add"
            isDisabled={!details?.field || !details?.mandatory}
            onClick={addAttribute}
          />
        </Box>

        {details?.customAttributes?.length ? (
          <div style={{paddingTop:"15px"}}>
            <TableWithAction
            className={`${styles.table} ${styles.tdTable}`}
            columns={[]}
            rowData={details?.customAttributes || []}
            keys={['field.label', 'mandatory']}
            action="delete"
            onClick={deleteAttribute}
            hierarchies={hierarchies}
          />
          </div>
          
        ) : null}

        <div style={{paddingTop:"15px"}}>
        <EditSimilarity
          templateIdMap={templateIdMap}
          label="Similarity"
          hierarchies={hierarchies}
          similarities={details?.similarities || []}
          templateIdLeft={details?.template_id_left}
          templateIdRight={details?.template_id_right}
          onChange={(value) => {
            handleChange('similarities', value);
            if (details?.template_id_left === details?.template_id_right) {
              handleChange('similaritiesInverse', value);
            }
          }}
          setIsError={setIsError}
        />
        </div>

        
        {
          details?.template_id_left !== details?.template_id_right && (
            <div style={{paddingTop:"10px"}}>
               <EditSimilarity
              templateIdMap={templateIdMap}
              label="Inverse Similarity"
              hierarchies={hierarchies}
              similarities={details?.similaritiesInverse || []}
              templateIdLeft={details?.template_id_right}
              templateIdRight={details?.template_id_left}
              onChange={(value) => handleChange('similaritiesInverse', value)}
              setIsError={setIsError}
            />
            </div>
           
          )
        }

        <div className={`${styles.buttonsDiv} space-x-2.5`}>
          {isError ? (
            <span className="field-error">
              Please fill all mandatory fields
            </span>
          ) : null}
          <SecondaryCustomButton buttonText="Cancel" onClick={navigateBack} />
          <PrimaryCustomButton
            isLoading={isRelationLoading}
            buttonText="Save"
            onClick={onSave}
          />
        </div>
      </div>
    </Box>
  );
};

EditRelation.propTypes = {
  relationData: PropTypes.object,
  navigateBack: PropTypes.func.isRequired,
  templateOptions: PropTypes.array.isRequired,
  templateIdMap: PropTypes.object.isRequired,
  offerOptionsByTemplateId: PropTypes.object.isRequired,
  offerIdMap: PropTypes.object.isRequired,
  onSaveRelation: PropTypes.func.isRequired,
  isRelationLoading: PropTypes.bool.isRequired,
  currentTemplate: PropTypes.object.isRequired,
  hierarchies: PropTypes.array,
};

EditRelation.defaultProps = {
  relationData: {},
  hierarchies: [],
};

export default EditRelation;
