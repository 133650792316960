import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CopyIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import Joi from 'joi';
import { useSelector } from 'react-redux';
import axios from 'axios';
import CommunicationTemplateCommonTable from './CommunicationTemplateCommonTable';
import { convertFieldType } from '../MasterScreen/ConfigJson/helpers';
import axiosInstance from '../../utils/axios/axios';

export const columns = ({ onEdit, onDelete, onClone, tabType }) => [
  {
    col_name: 'name',
    type: 'string',
  },
  {
    col_name: 'status',
    type: 'string',
  },
  {
    col_name: 'created_by',
    type: 'string',
  },
  {
    col_name: 'actions',
    type: 'actions',
    cellRenderer: ({ rowData }) => (
      <div className="flex gap-[10px] justify-center">
        <EditIcon
          className="cursor-pointer"
          color="#94A3B8"
          onClick={() => onEdit(rowData, tabType)}
        />
        <DeleteIcon
          className="cursor-pointer"
          color="#94A3B8"
          onClick={() => onDelete(rowData, tabType)}
        />
        <CopyIcon
          className="cursor-pointer"
          color="#94A3B8"
          onClick={() => onClone(rowData, tabType)}
        />
      </div>
    ),
  },
];

export const tabsData = [
  {
    id: 'email_templates',
    title: 'Email',
    content: <CommunicationTemplateCommonTable />,
    tabType: 'EMAIL',
  },
  {
    id: 'canned_responses',
    title: 'Canned Notes',
    content: <CommunicationTemplateCommonTable />,
    tabType: 'CANNED_NOTES',
  },
  // {
  //   id: 'in_app_notification',
  //   title: 'In App Notification',
  //   content: <CommunicationTemplateCommonTable />,
  //   tabType: 'IN_APP_NOTIFICATION'
  // },
  // {
  //   id: 'team_slack_messages',
  //   title: 'Team/ Slack Messages',
  //   content: <CommunicationTemplateCommonTable />,
  //   tabType: 'SLACK_TEAMS_NOTIFICATION',
  // },
  // {
  //   id: 'chat_responses',
  //   title: 'Chat Response',
  //   content: <CommunicationTemplateCommonTable />,
  //   tabType: 'CHAT_RESPONSE'
  // },
];
// export const parseRichTextMentionToAPI = (rteString) => {
//   const cleanString = rteString?.replace(/\uFEFF/g, ''); // This is to remove the BOM character
// eslint-disable-next-line max-len
//   const regex = /<span class="mention" data-index="([^"]+)" data-denotation-char="" data-id="([^"]+)" data-value="[^"]+"><span contenteditable="false">([^<]+)<\/span><\/span>/g;
//   return cleanString?.replace(regex, '<span data-index="$1" data-value="$3"><%= $2 %></span>');
// };

export const parseRichTextMentionToAPI = (rteString) => {
  const cleanString = rteString?.replace(/\uFEFF/g, ''); // This is to remove the BOM character
  const regex =
    /<span class="mention" data-index="([^"]+)" data-denotation-char="" data-id="([^"]+)" data-value="[^"]+"><span contenteditable="false">([^<]+)<\/span><\/span>/g;
  return cleanString?.replace(regex, (match, p1, p2, p3) => {
    const isNote = p2.startsWith('note.');
    return `<span data-index="${p1}" data-value="${p3}">${
      isNote ? '<%-' : '<%='
    } ${p2} %></span>`;
  });
};

// export const parseAPIToRichTextMention = (apiString) => {
//   // alert(`parseAPIToRichTextMention${apiString}`);
//   const regex = /<span data-index="([^"]+)" data-value="([^"]+)"><%= ([^"]+) %><\/span>/g;
// eslint-disable-next-line max-len
//   return apiString?.replace(regex, '<span class="mention" data-index="$1" data-denotation-char="" data-id="$3" data-value="$2"><span contenteditable="false">$2</span></span>');
// };

export const parseAPIToRichTextMention = (apiString) => {
  const regexEscaped =
    /<span data-index="([^"]+)" data-value="([^"]+)"><%= ([^"]+) %><\/span>/g;
  const regexUnescaped =
    /<span data-index="([^"]+)" data-value="([^"]+)"><%- ([^"]+) %><\/span>/g;
  const resultEscaped = apiString?.replace(
    regexEscaped,
    '<span class="mention" data-index="$1" data-denotation-char="" data-id="$3" data-value="$2"><span contenteditable="false">$2</span></span>'
  );
  const resultUnescaped = resultEscaped?.replace(
    regexUnescaped,
    '<span class="mention" data-index="$1" data-denotation-char="" data-id="$3" data-value="$2"><span contenteditable="false">$2</span></span>'
  );
  return resultUnescaped;
};

export const parseCustomMentionToAPI = (customMentionString) => {
  const regex = /@\(([^)]+)\)/g;
  const output = customMentionString?.replace(regex, '<%= $1 %>');
  return output;
};

export const parseAPIToCustomMention = (apiString) => {
  // const regex = /<%=([^%>]+)%>/g;
  // const output = input?.replace(regex, '@($1)');
  // return output;
  const regex = /<%=\s*([^%>]+)\s*%>/g;
  const output = apiString?.replace(
    regex,
    (match, group) => `@(${group.trim()})`
  );
  return output;
};

export const convertAssoicatedIdToActorId = (apiString, currentTemplate, relatedTemplate) => {
  const actors = currentTemplate?.actors;
  const associatedFieldIds = actors?.map((actor) => actor.associated_field_id);
  const actorsIds = actors?.reduce((map, actor) => {
    map.set(actor.associated_field_id, actor.id);
    return map;
  }, new Map());

  let output = apiString.replace(new RegExp(`${associatedFieldIds?.join('|')}`, 'g'), (match) => {
    const actorId = actorsIds?.get(match) || match;
    return actorId;
  });

  if (relatedTemplate) {
    const relatedActors = relatedTemplate?.actors;
    const relatedAssociatedFieldIds = relatedActors.map((actor) => actor.associated_field_id);
    const relatedActorsIds = relatedActors.reduce((map, actor) => {
      map.set(actor.associated_field_id, actor.id);
      return map;
    }, new Map());

    output = output.replace(new RegExp(`${relatedAssociatedFieldIds.join('|')}`, 'g'), (match) => {
      const actorId = relatedActorsIds.get(match) || match;
      return actorId;
    });
  }

  return output;
};

export const convertImgDataToSrc = (data) => {
  const imgSrcRegex = /<img.*?uniqueattr=["|'](.*?)["|']/gi;
  data = data.replace(imgSrcRegex, (match, code) => {
    if (!code || code === 'null' || code === 'undefined') return match;
    return match.replace(/src=["|'](.*?)["|']/gi, `src="${code}"`);
  });
  return data;
};

export const EMAIL_TEMPLATE_KEY_MAP = {
  NAME: 'name',
  STATUS: 'status',
  TO: 'to',
  CC: 'cc',
  SUBJECT: 'subject',
  SEND_AS: 'send_as',
  EMAIL_BODY: 'email_body',
  EMAIL_SIGNATURE: 'email_signature',
  ID: 'id',
  TYPE: 'type',
  CREATED_BY: 'created_by',
  NOTIFICATION_LABEL: 'notification_label',
  RESPONSE_BODY: 'response_body',
  TEXT: 'text',
  HTML: 'html',
};
export const COMMUNICATION_TEMPLATES = 'communication_templates';

export const SEND_AS_OPTIONS = [
  { value: 'text', label: 'TEXT' },
  { value: 'html', label: 'HTML' }
];
export const FIELD_QUILL_TOOLBAR = [
  ['bold', 'italic', 'underline', 'strike'],

  ['undo', 'redo'],

  [{ list: 'ordered' }, { list: 'bullet' }],

  [{ indent: '-1' }, { indent: '+1' }],

  [{ size: ['small', false, 'large', 'huge'] }],

];
export const EMAIL_QUILL_TOOLBAR = [
  ...FIELD_QUILL_TOOLBAR,

  ['image'],

  ['clean'],
];

export const CANNED_QUILL_TOOLBAR = [
  ...FIELD_QUILL_TOOLBAR,

  ['clean']
]
export const removeTag = (value) =>
  value?.replace(/<[^>]*>/g, '\n')?.replace(/<br\s*\/?>/g, '');

export const createEmailTemplateValidation = (nameCheckArray) =>
  Joi.object({
    [EMAIL_TEMPLATE_KEY_MAP.NAME]: Joi.string()
      .required()
      .custom((value, helpers) => {
        if (nameCheckArray.includes(value)) {
          return helpers.error('any.invalid');
        }
        return value;
      }, 'uniqueName'),
    [EMAIL_TEMPLATE_KEY_MAP.STATUS]: Joi.string().required(),
    // [EMAIL_TEMPLATE_KEY_MAP.TO]: Joi.object({
    //   teams: Joi.array().min(1),
    //   queues: Joi.array().min(1),
    //   org: Joi.array().min(1),
    //   groups: Joi.array().min(1),
    //   users: Joi.array().min(1),
    //   roles: Joi.array().min(1),
    // })
    //   .or('teams', 'queues', 'org', 'groups', 'users', 'roles')
    //   .required(),
    // [EMAIL_TEMPLATE_KEY_MAP.CC]: Joi.object({
    //   teams: Joi.array(),
    //   queues: Joi.array(),
    //   org: Joi.array(),
    //   groups: Joi.array(),
    //   users: Joi.array(),
    //   roles: Joi.array(),
    // }).default({}),
    [EMAIL_TEMPLATE_KEY_MAP.SUBJECT]: Joi.string().required(),
    [EMAIL_TEMPLATE_KEY_MAP.SEND_AS]: Joi.string().allow(null, ''),
    [EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY]: Joi.string().required(),
    [EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE]: Joi.string().allow(''),
    [EMAIL_TEMPLATE_KEY_MAP.ID]: Joi.string()
      .guid({ version: 'uuidv4' })
      .required(),
    [EMAIL_TEMPLATE_KEY_MAP.TYPE]: Joi.string().required(),
    [EMAIL_TEMPLATE_KEY_MAP.CREATED_BY]: Joi.string().required(),
  });

export const createEmailTemplatePayload = (data, type) => {
  const payload = {
    [EMAIL_TEMPLATE_KEY_MAP.ID]: data?.[EMAIL_TEMPLATE_KEY_MAP.ID] || uuidv4(),
    [EMAIL_TEMPLATE_KEY_MAP.NAME]: data?.[EMAIL_TEMPLATE_KEY_MAP.NAME]?.replace(/\s+/g, ' ').trim(),
    [EMAIL_TEMPLATE_KEY_MAP.STATUS]: data?.[EMAIL_TEMPLATE_KEY_MAP.STATUS],
    // [EMAIL_TEMPLATE_KEY_MAP.TO]: data?.[EMAIL_TEMPLATE_KEY_MAP.TO],
    // [EMAIL_TEMPLATE_KEY_MAP.CC]: data?.[EMAIL_TEMPLATE_KEY_MAP.CC] || {},
    [EMAIL_TEMPLATE_KEY_MAP.SUBJECT]: parseCustomMentionToAPI(
      data?.[EMAIL_TEMPLATE_KEY_MAP.SUBJECT] || ''
    ),
    [EMAIL_TEMPLATE_KEY_MAP.SEND_AS]: data?.[EMAIL_TEMPLATE_KEY_MAP.SEND_AS],
    [EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY]: data?.[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] === 'html'
      ? parseCustomMentionToAPI(data?.[EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY] || '')
      : parseRichTextMentionToAPI(data?.[EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY] || ''),
    [EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE]: data?.[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] === 'html'
      ? parseCustomMentionToAPI(data?.[EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE] || '')
      : parseRichTextMentionToAPI(data?.[EMAIL_TEMPLATE_KEY_MAP.EMAIL_SIGNATURE] || ''),
    // [EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY]: data?.[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] === 'text'
    // ? removeTag(data?.[EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY] || '')
    // : data?.[EMAIL_TEMPLATE_KEY_MAP.EMAIL_BODY],
    [EMAIL_TEMPLATE_KEY_MAP.TYPE]: data?.[EMAIL_TEMPLATE_KEY_MAP.TYPE] || type,
    [EMAIL_TEMPLATE_KEY_MAP.CREATED_BY]:
      data?.[EMAIL_TEMPLATE_KEY_MAP.CREATED_BY] ||
      localStorage.getItem('userEmail'),
  };
  return payload;
};

export const genericCreateTemplateValidation = (
  nameCheckArray,
  isHiddenNotificationLabel
) => {
  if (isHiddenNotificationLabel) {
    return Joi.object({
      [EMAIL_TEMPLATE_KEY_MAP.NAME]: Joi.string()
        .required()
        .custom((value, helpers) => {
          if (nameCheckArray.includes(value)) {
            return helpers.error('any.invalid');
          }
          return value;
        }, 'uniqueName'),
      [EMAIL_TEMPLATE_KEY_MAP.STATUS]: Joi.string().required(),
      [EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY]: Joi.string().required(),
      [EMAIL_TEMPLATE_KEY_MAP.ID]: Joi.string()
        .guid({ version: 'uuidv4' })
        .required(),
      [EMAIL_TEMPLATE_KEY_MAP.TYPE]: Joi.string().required(),
      [EMAIL_TEMPLATE_KEY_MAP.CREATED_BY]: Joi.string().required(),
      [EMAIL_TEMPLATE_KEY_MAP.SEND_AS]: Joi.string().required(),
    });
  }
  return Joi.object({
    [EMAIL_TEMPLATE_KEY_MAP.NAME]: Joi.string()
      .required()
      .custom((value, helpers) => {
        if (nameCheckArray.includes(value)) {
          return helpers.error('any.invalid');
        }
        return value;
      }, 'uniqueName'),
    [EMAIL_TEMPLATE_KEY_MAP.STATUS]: Joi.string(),
    [EMAIL_TEMPLATE_KEY_MAP.NOTIFICATION_LABEL]: Joi.string().required(),
    [EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY]: Joi.string().required(),
    [EMAIL_TEMPLATE_KEY_MAP.ID]: Joi.string()
      .guid({ version: 'uuidv4' })
      .required(),
    [EMAIL_TEMPLATE_KEY_MAP.TYPE]: Joi.string().required(),
    [EMAIL_TEMPLATE_KEY_MAP.CREATED_BY]: Joi.string().required(),
    [EMAIL_TEMPLATE_KEY_MAP.SEND_AS]: Joi.string().required(),
  });
};

export const genericCreateTemplatePayload = (
  data,
  type,
  isHiddenNotificationLabel
) => {
  if (isHiddenNotificationLabel) {
    return {
      [EMAIL_TEMPLATE_KEY_MAP.ID]:
        data?.[EMAIL_TEMPLATE_KEY_MAP.ID] || uuidv4(),
      [EMAIL_TEMPLATE_KEY_MAP.NAME]: data?.[EMAIL_TEMPLATE_KEY_MAP.NAME],
      [EMAIL_TEMPLATE_KEY_MAP.STATUS]: data?.[EMAIL_TEMPLATE_KEY_MAP.STATUS],
      [EMAIL_TEMPLATE_KEY_MAP.SEND_AS]: data?.[EMAIL_TEMPLATE_KEY_MAP.SEND_AS],
      // [EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY]:
      // removeTag(data?.[EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY] || ''),
      [EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY]: data?.[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] === 'html'
        ? parseCustomMentionToAPI(data?.[EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY] || '')
        : parseRichTextMentionToAPI(data?.[EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY] || ''),
      [EMAIL_TEMPLATE_KEY_MAP.TYPE]:
        data?.[EMAIL_TEMPLATE_KEY_MAP.TYPE] || type,
      [EMAIL_TEMPLATE_KEY_MAP.CREATED_BY]:
        data?.[EMAIL_TEMPLATE_KEY_MAP.CREATED_BY] ||
        localStorage.getItem('userEmail'),
    };
  }
  return {
    [EMAIL_TEMPLATE_KEY_MAP.ID]: data?.[EMAIL_TEMPLATE_KEY_MAP.ID] || uuidv4(),
    [EMAIL_TEMPLATE_KEY_MAP.NAME]: data?.[EMAIL_TEMPLATE_KEY_MAP.NAME],
    [EMAIL_TEMPLATE_KEY_MAP.STATUS]: data?.[EMAIL_TEMPLATE_KEY_MAP.STATUS],
    [EMAIL_TEMPLATE_KEY_MAP.NOTIFICATION_LABEL]:
      data?.[EMAIL_TEMPLATE_KEY_MAP.NOTIFICATION_LABEL],
    [EMAIL_TEMPLATE_KEY_MAP.SEND_AS]: data?.[EMAIL_TEMPLATE_KEY_MAP.SEND_AS],
    // [EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY]:
    // removeTag(data?.[EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY] || ''),
    [EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY]: data?.[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] === 'html'
      ? parseCustomMentionToAPI(data?.[EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY] || '')
      : parseRichTextMentionToAPI(data?.[EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY] || ''),
    [EMAIL_TEMPLATE_KEY_MAP.TYPE]: data?.[EMAIL_TEMPLATE_KEY_MAP.TYPE] || type,
    [EMAIL_TEMPLATE_KEY_MAP.CREATED_BY]:
      data?.[EMAIL_TEMPLATE_KEY_MAP.CREATED_BY] ||
      localStorage.getItem('userEmail'),
  };
};

const getTitle = (key, toLowerCase = false) => {
  const titles = {
    CANNED_NOTES: 'Canned Notes Template',
    IN_APP_NOTIFICATION: 'App Notification Template',
    SLACK_TEAMS_NOTIFICATION: 'Slack/Team Notification Template',
    CHAT_RESPONSE: 'Chat Response Template',
  };

  const title = titles[key] || '';

  return toLowerCase ? title.toLowerCase() : title;
};
export const updateTemplateSuccessMsg = (key) =>
  `Successfully updated the ${getTitle(key, true)}`;

export const updateTemplateFailedMsg = (key) =>
  `Failed to update the ${getTitle(key, true)}`;

export const editTitleForTemplate = (key) => `Edit ${getTitle(key)}`;

export const addTitleForTemplate = (key) => `Add New ${getTitle(key)}`;

export const createTemplateSuccessMsg = (key) =>
  `Successfully created ${getTitle(key, true)}`;

export const createTemplateFailedMsg = (key) =>
  `Failed to create a ${getTitle(key, true)}`;

// eslint-disable-next-line arrow-body-style
export const getMentionOptionLabel = ({
  name,
  type,
  subType = null,
  isRelated = false,
}) => `${name}${subType ? `- ${subType}` : ''} (${type}${
  isRelated ? ' - Event' : ''
})`.trim();

export const useMentionObj = ({ linkedItemId, userAttributes , ...formProps }) => {
  const { isEmailForm, isNotesForm, isStatusTransitionForm, isCannedNotes } = formProps;
  const [relatedItemActor, setRelatedItemActor] = useState([]);
  const [relatedItemActorTransitions, setRelatedItemActorTransitions] =
    useState([]);
  const [relatedItemMetric, setRelatedItemMetric] = useState([]);
  const [relatedItemSla, setRelatedItemSla] = useState([]);
  const [relatedItemFields, setRelatedItemFields] = useState([]);
  const userAttrOptions = userAttributes?.map((item) => ({
    label: item.label,
    value: item.key,
  }));

  const fetchData = async (id) => {
    const source = axios.CancelToken.source();
    try {
      const params = {
        $select: 'fields, slas, metric, actors',
        $filter: `id eq '${id}'`,
      };
      const response = await axiosInstance.get('templates/list', {
        params,
        cancelToken: source.token,
      });

      if (response?.data?.rows) {
        const relatedTemplate = [...response.data.rows][0];
        const RELATED_FIELDS_LIST = [...(relatedTemplate.fields || [])]
          .flatMap((item) => {
            const fieldOptions = [{
              id: `ticket?.data['${item.id}'] || ''`,
              value: getMentionOptionLabel({
                name: item?.label,
                type: convertFieldType({ rowData: item }),
              })
            }];

            if (item.type === 'DROPDOWN' && item?.type_based_attributes?.collection?.specialField === 'actor') {
              const firstNameOption = {
                id: `ticket?.data['${item.id}'] ? ticket.data['${item.id}']?.userResponse?.first_name : ''`,
                value: getMentionOptionLabel({
                  name: item.label,
                  type: 'Actor',
                  subType: 'First Name',
                })
              };

              const lastNameOption = {
                id: `ticket?.data['${item.id}'] ? ticket.data['${item.id}']?.userResponse?.last_name : ''`,
                value: getMentionOptionLabel({
                  name: item.label,
                  type: 'Actor',
                  subType: 'Last Name',
                })
              };

              fieldOptions.push(firstNameOption, lastNameOption);
            }

            return fieldOptions;
          });
        const RELATED_METRIC_LIST = [...(relatedTemplate.metric || [])].map((item) => ({
          id: `parentMetric['${item.id}']`,
          value: getMentionOptionLabel({
            name: item.name,
            type: 'Metric',
            isRelated: true
          })
        }));

        const RELATED_SLA_LIST = Object.values(relatedTemplate?.slas || {})
          .flatMap(([key, data]) => {
            if (Array.isArray(data)) {
              return data.map((item) => ({
                id: `parentSla['${key}']`,
                value: getMentionOptionLabel({
                  name: item.name,
                  type: 'Sla',
                  isRelated: true
                })
              }));
            }
            return [];
          });

        const RELATED_ACTOR_LIST = [];
        const RELATED_ACTOR_TRANSITION_LIST = [];

        [...(relatedTemplate.actors || [])].forEach((item) => {
          const firstNameOption = {
            id: `parentActor['${item.associated_field_id}'] ? parentActor['${item.associated_field_id}']?.userResponse?.first_name : ''`,
            value: getMentionOptionLabel({
              name: item.name,
              type: 'Actor',
              subType: 'First Name',
              isRelated: true,
            }),
          };

          const lastNameOption = {
            id: `parentActor['${item.associated_field_id}'] ? parentActor['${item.associated_field_id}']?.userResponse?.last_name : ''`,
            value: getMentionOptionLabel({
              name: item.name,
              type: 'Actor',
              subType: 'Last Name',
              isRelated: true,
            }),
          };

          RELATED_ACTOR_LIST.push(firstNameOption);
          RELATED_ACTOR_LIST.push(lastNameOption);
        });

        if (isEmailForm && !isCannedNotes) {
          [...(relatedTemplate.actors || [])].forEach((item) => {
            const firstNameOption = {
              id: `allowedTransitions['${item.associated_field_id}']`,
              value: getMentionOptionLabel({
                name: item.name,
                type: 'Actor',
                subType: '.AllowedTransitions',
              }),
            };

            RELATED_ACTOR_TRANSITION_LIST.push(firstNameOption);
          });
        }

        setRelatedItemActor(RELATED_ACTOR_LIST);
        setRelatedItemActorTransitions(RELATED_ACTOR_TRANSITION_LIST);
        setRelatedItemMetric(RELATED_METRIC_LIST);
        setRelatedItemSla(RELATED_SLA_LIST);
        setRelatedItemFields(RELATED_FIELDS_LIST);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    if (linkedItemId) {
      fetchData(linkedItemId);
    }
  }, [linkedItemId, isEmailForm]);

  const CURRENT_TEMPLATE_FIELDS_LIST = useSelector((state) => {
    const FIELD_LIST = [...(state?.viewState?.selectedTemplate?.fields || [])]
      .flatMap((item) => {
        let fieldOptions;

        if (isCannedNotes) {
          if (item?.type !== 'MEDIA') {
            fieldOptions = [{
              id: `${linkedItemId ? 'parentTicket' : 'ticket'}.data['${item.id}']`,
              value: getMentionOptionLabel({
                name: item?.label,
                type: convertFieldType({ rowData: item }),
                isRelated: !!linkedItemId
              })
            }];
          }
        } else {
          fieldOptions = [{
            id: `${linkedItemId ? 'parentTicket' : 'ticket'}.data['${item.id}']`,
            value: getMentionOptionLabel({
              name: item?.label,
              type: convertFieldType({ rowData: item }),
              isRelated: !!linkedItemId
            })
          }];
        }

        if (item.type === 'DROPDOWN' && item?.type_based_attributes?.collection?.specialField === 'actor') {
          const firstNameOption = {
            id: `${linkedItemId ? 'parentActor' : 'actor'}['${item.id}'] ? ${linkedItemId ? 'parentActor' : 'actor'}['${item.id}']?.userResponse?.first_name : ''`,
            value: getMentionOptionLabel({
              name: item.label,
              type: 'Actor',
              subType: 'First Name',
              isRelated: !!linkedItemId
            })
          };

          const lastNameOption = {
            id: `${linkedItemId ? 'parentActor' : 'actor'}['${item.id}'] ? ${linkedItemId ? 'parentActor' : 'actor'}['${item.id}']?.userResponse?.last_name : ''`,
            value: getMentionOptionLabel({
              name: item.label,
              type: 'Actor',
              subType: 'Last Name',
              isRelated: !!linkedItemId
            })
          };

          userAttrOptions?.forEach((userAttribute) => {
            const userAttributesOfActor = {
              id: `${linkedItemId ? 'parentActor' : 'actor'}['${item.id}'] ? ${linkedItemId ? 'parentActor' : 'actor'}['${item.id}']?.userResponse?.attributes?.${userAttribute.value} : ''`,
              value: `${getMentionOptionLabel({ name: item.label, type: 'Actor', isRelated: !!linkedItemId })} ${userAttribute.label}`
            }
            fieldOptions?.push(userAttributesOfActor);
          })
          fieldOptions?.push(firstNameOption, lastNameOption);
        }

        return fieldOptions || [];
      });

    const SLA_LIST = Object.entries(state?.viewState?.selectedTemplate?.slas || {})
      .flatMap(([key, data]) =>
        data.map((item) => ({
          id: `sla['${key}']`,
          value: getMentionOptionLabel({
            name: item.name,
            type: 'Sla'
          })
        }))
      );

    const METRIC_LIST = [...(state?.viewState?.selectedTemplate?.metric || [])]
      .map((item) => ({
        id: `metric['${item.id}']`,
        value: getMentionOptionLabel({
          name: item.name,
          type: 'Metric'
        })
      }));

    if (
      Array.isArray(FIELD_LIST) &&
      Array.isArray(SLA_LIST) &&
      Array.isArray(METRIC_LIST)
    ) {
      return [...FIELD_LIST, ...SLA_LIST, ...METRIC_LIST];
    }
    return [];
  });

  const CURRENT_TEMPLATE_ACTOR_LIST = useSelector(
    (state) => {
      const currentTemplateActorOptions = [];
      [...(state?.viewState?.selectedTemplate?.actors || [])]
        .forEach((item) => {
          const firstNameOption = {
            id: `actor['${item.id}'] ? actor['${item.id}']?.userResponse?.first_name : ''`,
            value: getMentionOptionLabel({
              name: item.name,
              type: 'Actor',
              subType: 'First Name'
            })
          };

          const lastNameOption = {
            id: `actor['${item.id}'] ? actor['${item.id}']?.userResponse?.last_name : ''`,
            value: getMentionOptionLabel({
              name: item.name,
              type: 'Actor',
              subType: 'Last Name'
            })
          };

          currentTemplateActorOptions.push(firstNameOption);
          currentTemplateActorOptions.push(lastNameOption);
        });

      return currentTemplateActorOptions;
    });

  const CURRENT_TEMPLATE_ACTOR_TRANSITION_LIST = useSelector((state) => {
    const currentTemplateActorOptions = [];
    if (isEmailForm && !isCannedNotes) {
      [...(state?.viewState?.selectedTemplate?.actors || [])].forEach((item) => {
        const actors = {
          id: `allowedTransitions['${item.associated_field_id}']`,
          value: getMentionOptionLabel({
            name: item.name,
            type: 'Actor',
            subType: 'AllowedTransitions',
          }),
        };

        currentTemplateActorOptions.push(actors);
      });
    }

    return currentTemplateActorOptions;
  });

  const CURRENT_TEMPALTE_TRANSITION_FIELDS_LIST = useSelector((state) => {
    const currentTemplateActorOptions = [];
    [...(state?.viewState?.selectedTemplate?.fields || [])].forEach((item) => {
      if (item.scope === 'WORKFLOW') {
        const actors = {
          id: `transitionsField['${item.id}']`,
          value: getMentionOptionLabel({
            name: item?.label,
            type: 'event',
            subType: 'TransitionsFields',
          }),
        };

        currentTemplateActorOptions.push(actors);
      }
    });
    return currentTemplateActorOptions;
  });

  const HARDCODED_ACTORS_LIST = [
    { id: 'cc_watchers', value: 'CC Watcher' },
    { id: 'to_watchers', value: 'To Watcher' },
    { id: 'submitted_by', value: 'Submitted By' },
    { id: 'actioned_by', value: 'Actioned By' },
  ];

  const HARDCODED_FIELDS_LIST = [
    { id: 'ticket.object_id', value: 'Ticket ID' },
    { id: 'ticketLink', display: 'Ticket Link' },
    { id: 'created_at', value: 'Created At' },
  ];

  const HARDCODED_HTML_NOTE_LIST = [
    { id: 'note.current_note', value: 'Current Note' },
    {
      id: 'note.all_public_notes_latest_first',
      value: 'All Public Notes ( Latest first)',
    },
    {
      id: 'note.all_public_notes_latest_last',
      value: 'All Public Notes ( Latest Last)',
    },
    { id: 'note.all_notes_latest_first', value: 'All Notes (Latest First)' },
    { id: 'note.all_notes_latest_last', value: 'All Notes (Latest Last)' },
    { id: 'note.latest_public_note', value: 'Latest Public Note' },
    { id: 'note.latest_note', value: 'Latest Note' },
  ];

  const HARDCODED_RELATED_FIELDS_LIST = [
    { id: 'parentTicket.object_id', value: 'Ticket ID - Event' },
  ];

  const HARDCODED_TICKETLINK_LIST = [{ id: 'ticketLink', value: 'Ticket Link' }];

  const HARDCODED_ACTORS_TRANSITION_LIST = [
    { id: `allowedTransitions['cc_watchers']`, value: 'CC Watcher.AllowedTransitions' },
    { id: `allowedTransitions['to_watchers']`, value: 'To Watcher.AllowedTransitions' },
    { id: `allowedTransitions['submitted_by']`, value: 'Submitted By.AllowedTransitions' },
    { id: `allowedTransitions['actioned_by"]`, value: 'Actioned By.AllowedTransitions' },
  ];

  const HARDCODED_EVENT_NOTE_LIST = [
    { id: `note['payload']?.message`, value: 'event - note' },
  ];

  const ALL_TICKETS_LIST = [
    { id: `tickets.count`, value: 'Tickets count' },
  ]

  const getFieldMentionData = useCallback(
    (searchVal) => {
      const allFields = [
        ...CURRENT_TEMPLATE_FIELDS_LIST,
        ...CURRENT_TEMPLATE_ACTOR_TRANSITION_LIST,
        ...HARDCODED_FIELDS_LIST,
        // ...HARDCODED_HTML_NOTE_LIST,
        ...relatedItemActorTransitions,
        ...ALL_TICKETS_LIST
      ];

      if (isNotesForm) {
        allFields.push(...HARDCODED_EVENT_NOTE_LIST);
      }

      if(!isCannedNotes) {
        allFields.push(...HARDCODED_HTML_NOTE_LIST);
      }

      if (isStatusTransitionForm) {
        allFields.push(...CURRENT_TEMPALTE_TRANSITION_FIELDS_LIST);
      }

      if (isEmailForm && !isCannedNotes) {
        allFields.push(...HARDCODED_ACTORS_TRANSITION_LIST);
        allFields.push(...HARDCODED_TICKETLINK_LIST);
      }

      if (linkedItemId) {
        allFields.push(...HARDCODED_RELATED_FIELDS_LIST);
        allFields.push(...relatedItemFields);
        allFields.push(...relatedItemMetric);
        allFields.push(...relatedItemSla);
      }

      if (searchVal?.trim()) {
        return allFields.filter((fieldOption) =>
          fieldOption?.value?.toLowerCase()?.includes(searchVal?.toLowerCase())
        );
      }
      return allFields;
    },

    [CURRENT_TEMPLATE_FIELDS_LIST, relatedItemFields, isEmailForm]
  );
  const getActorMetionData = useCallback(
    (searchVal) => {
      const allActors = [
        ...CURRENT_TEMPLATE_ACTOR_LIST,
        ...relatedItemActor,
        ...HARDCODED_ACTORS_LIST,
      ];
      if (searchVal?.trim()) {
        return allActors.filter((actorOption) =>
          actorOption.value.toLowerCase().includes(searchVal.toLowerCase())
        );
      }
      return allActors;
    },
    [CURRENT_TEMPLATE_ACTOR_LIST, relatedItemActor]
  );

  const mentionObj = useMemo(
    () => ({
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      showDenotationChar: false,
      sourceMinChars: 0,
      minChars: 0,
      mentionDenotationChars: ['$', '@'],
      renderItem(item) {
        return item?.value || '';
      },
      source(searchTerm, renderList, mentionChar) {
        let values = [];

        if (mentionChar === '$') {
          values = getFieldMentionData(searchTerm);
        }
        if (mentionChar === '@') {
          values = getActorMetionData(searchTerm);
        }

        renderList(values, searchTerm);
      },
    }),
    [getFieldMentionData]
  );

  return { mentionObj };
};
