import React from 'react';

import { EditIcon } from '@chakra-ui/icons';
import Joi from 'joi';

import TableColumnText from '../../components/Text/TableColumnText';

import { validationErrorMessage } from '../../utils/validationSchemas/validationSchemas';
import { deleteRenderer } from '../DynamicRenderer/MetricsAndSLA/Metrics/utils';
import {
  convertRolesToArray,
  renderCriteria,
} from '../DynamicRenderer/Audience/AudienceEditModal/audience.utls';
import { Box,Flex } from '@chakra-ui/react';

export const FORM_MAP = {
  NAME: 'name',
  DESCRIPTION: 'description',
  ROLE: 'role',
  CREATED_AT: 'Created At',
  CREATED_BY:'Created By'
};

const validateForCreateAudience = Joi.object({
  [FORM_MAP.NAME]: Joi.string().required().messages({
    'string.empty': 'Name is required',
    'any.required': 'Name is required',
  })
}).unknown();

export const createPayloadForAudience = (data) => {
  const payload = {
    id: data?.id || '',
    [FORM_MAP.NAME]: data?.[FORM_MAP.NAME]?.replace(/\s+/g, ' ').trim() || '',
    [FORM_MAP.ROLE]: data?.[FORM_MAP.ROLE] || {},
    [FORM_MAP.DESCRIPTION]: data?.[FORM_MAP.DESCRIPTION] || '',
  };

  return payload;
};

export const enrichDataForEdit = (data) => {
  const payload = {
    id: data?.id || '',
    [FORM_MAP.NAME]: data?.[FORM_MAP.NAME]?.replace(/\s+/g, ' ').trim() || '',
    [FORM_MAP.ROLE]: data?.[FORM_MAP.ROLE] || {},
    [FORM_MAP.DESCRIPTION]: data?.[FORM_MAP.DESCRIPTION] || '',
    [FORM_MAP.INTENT_GROUPS]: data?.[FORM_MAP.INTENT_GROUPS]
      ?.map((g) => g?.[FORM_MAP.INTENT_GROUP_ID])
      ?.filter((g) => g),
  };

  return payload;
};

export const validatePayload = (data, isNameExist) => {
  const payload = createPayloadForAudience(data);

  const { error } = validateForCreateAudience.validate(payload, {
    abortEarly: false,
  });

  if (isNameExist) {
    return {
      success: false,
      message: { name: 'Audience name already exist' }
    };
  }
  if (error) {
    const message = validationErrorMessage(error);
    return {
      success: false,
      message,
    };
  }

  return {
    success: true,
    payload,
  };
};

export const columns = ({ onEditClick, onDelete, optionsMap, configObj }) => {
  const cols = [
    {
      name: FORM_MAP.NAME,
      col_name: FORM_MAP.NAME,
    },
    {
      name: FORM_MAP.DESCRIPTION,
      col_name: FORM_MAP.DESCRIPTION,
    },
    {
      name: FORM_MAP.ROLE,
      col_name: 'Audience Criteria',
      cellRenderer: ({ rowData }) => {
        const roles = rowData?.[FORM_MAP.ROLE] || {};
        if (roles.length > 0) {
          let serializeRoles=roles;
          if(roles.find(item=>item.leftOperand=='actors') && configObj?.actors){
            const updatedRoles = roles.map((roleItem) => {
                if (roleItem.leftOperand === "actors") {
                    const values = roleItem.rightOperand;
                    const mappedActors = Array.isArray(values)
                      ? values.map((actorId) => {
                          const actor = configObj.actors.find((item) => item.id.toString() === actorId);
                          return actor?.name || actorId; // Return actor name or fallback to original ID
                        })
                      : (() => {
                          const actor = configObj.actors.find((item) => item.id.toString() === values);
                          return actor?.name || values; // Return actor name or fallback to original value
                        })();
                    return {
                        ...roleItem,
                        rightOperand: mappedActors,
                    };
                }
                return roleItem;
            });
              serializeRoles = updatedRoles
          }
          if(roles.find(item=>item.leftOperand=='queues') && configObj?.queues){
            const updatedRoles = roles.map((roleItem) => {
              if (roleItem.leftOperand === "queues") {
                  const values = roleItem.rightOperand;
                  const mappedQueues = Array.isArray(values)
                    ? values.map((queueId) => {
                        const queue = configObj.queues.find((item) => item.id.toString() === queueId);
                        return queue?.name || queueId; // Return actor name or fallback to original ID
                      })
                    : (() => {
                        const queue = configObj.queues.find((item) => item.id.toString() === values);
                        return queue?.name || values; // Return actor name or fallback to original value
                      })();
                  return {
                      ...roleItem,
                      rightOperand: mappedQueues,
                  };
              }
              return roleItem;
          });
              serializeRoles = updatedRoles
          }
          const data = serializeRoles;
          return renderCriteria(data);
        }

        return null;
      },
    },
    {
      name: FORM_MAP.CREATED_BY,
      col_name: FORM_MAP.CREATED_BY,
      type: 'string',
      cellRenderer:({rowData})=>(
        <Flex alignItems={"center"} gap="10px" me="2" >
          <Box 
            width="30px"
            height="30px"
            display="flex" 
            justifyContent="center" 
            alignItems="center" 
            backgroundColor={"#2ec1c5"} 
            borderRadius={"30px"}
            color="#fff"
          >
            {rowData?.created_by.slice(0,1)}
          </Box>
          <Box>{rowData?.created_by}</Box>
        </Flex>
      )
    },
    {
      name: FORM_MAP.CREATED_AT,
      col_name: FORM_MAP.CREATED_AT,
      type: 'string',
      cellRenderer:({rowData})=>{
        const date = new Date(rowData?.created_on);
        const day = date.getUTCDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getUTCFullYear();
        let hours = date.getUTCHours();
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert to 12-hour format

        const formattedDate = `${day} ${month} ${year} ${hours}:${minutes}${ampm}`;
        return(<div>{formattedDate}</div>)
      }
    },
    {
      col_name: 'Actions',
      cellRenderer: ({ rowData }) => (
        <div className="flex gap-[10px]">
          <EditIcon
            className="cursor-pointer"
            data-testid={`audience-${rowData.name}-edit`}
            onClick={() => {
              onEditClick(rowData);
            }}
          />
          {deleteRenderer({
            title: 'Delete this audience',
            description: 'Are you sure you want to delete this audience?',
            dataTestId:`audience-${rowData.name}-delete`,
            onConfirm: () => {
              onDelete(rowData);
            },
          })}
        </div>
      ),
    },
  ];

  return cols;
};
