import React, { useEffect, useMemo, useState } from 'react';

import {
  Box,
  ButtonGroup,
  Flex,
  HStack,
  Text,
  Tooltip,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { ArrowPathIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import { FaList } from "react-icons/fa";

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// import { ReactSVG } from 'react-svg';

// import RightSideMenu from '../ToggleMenu';
import Input from './InputField';
import QuickFilterPopOver from './QuickFilterPopOver';
import SortPopOver from './SortPopOver';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomSelectBox from '../../../components/SelectBox/Select';

import { fetchAllCards } from '../../../redux/CardReducer/AllCardSlice';
import {
  STATUSES,
  WIDTH_SIZE,
  checkIfDateRangeIsApplied,
  createFilterDropdown,
  getFilterQuery,
} from '../utils';
import customStyleFromRuleForSelect from '../../../context/CustomTheme/CustomStyleFromRuleForSelect';
import { isDarkThemeApplied } from '../../TicketTemplateBuilder/utils/helper';
// import { DateFilter } from '../QuickFilters/AddQuickFilters';
import {
  fetchQuickFilters,
  saveAppliedFilters,
} from '../../../redux/CardReducer/quickFilterSlice';
import { COLORS } from '../../../utils/enums/colors';
import {
  CARD_LISTS_TOOLTIP_LABELS,
  TITLE_LABELS,
} from '../../../utils/enums/labels';

import styles from '../cardLayout.module.scss';
import sumStyles from '../../TicketTemplateBuilder/styles/templatebuilder.module.scss';
import { useLocation } from 'react-router-dom';
import { MdRefresh } from 'react-icons/md';

const Header = ({
  searchText,
  setSearchText,
  cardCount,
  isCardView,
  setIsCardView,
  appliedFiltersRef,
  onCreateNewTemplate,
}) => {
  const location = useLocation()?.pathname;
  const title=location.includes("bot-integration")? TITLE_LABELS.BOT_INTEGRATION: TITLE_LABELS.TICKETS_TYPES;
  useEffect(() => {
    setSearchText('');
    setAppliedFilters({ ...initialAppiedFilterState });
    onRefresh(initialAppiedFilterState);
  }, [location]); 
  const [standardHeight, setStandardHeight] = useState(
    window.innerWidth === WIDTH_SIZE.STANDARD_SIZE
      ? WIDTH_SIZE.LG
      : WIDTH_SIZE.SM
  );
  useEffect(() => {
    const handleResize = () => {
      setStandardHeight(
        window.innerWidth === WIDTH_SIZE.STANDARD_SIZE
          ? WIDTH_SIZE.LG
          : WIDTH_SIZE.SM
      );
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();

  const { appliedFilters: AppliedFilters } = useSelector(
    (state) => state.quickFilter
  );

  const selectDarkStyle = useMemo(
    () => ({
      ...customStyleFromRuleForSelect,
      control: (...selectProps) => {
        const controlStyle = customStyleFromRuleForSelect.control(
          ...selectProps
        );
        return {
          ...controlStyle,
          height: standardHeight,
          width: '214px',
          // marginRight: '10px',
          pb: '2px',
          color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
        };
      },
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        height: '30px',
        display: 'flex',
        alignItems: 'start',
        padding: '4px'
      }),
      menu: (...selectProps) => {
        const menuStyle = customStyleFromRuleForSelect.menu(...selectProps);
        delete menuStyle.top;
        return {
          ...menuStyle,
          // marginTop: '10px',
        };
      },
    }),
    []
  );

  // This will have the information of the applied filters
  const initialAppiedFilterState = useMemo(
    () => ({
      selectedCategory: '',
      selectedSubcategory: '',
      dateRangeColumn: createFilterDropdown[0],
      dateRanges: [],
      selectedLifeCycle: STATUSES[0],
      selectedSortBy: createFilterDropdown[0],
      selectedSortType: '',
    }),
    []
  );

  const [appliedFilters, setAppliedFilters] = useState(
    initialAppiedFilterState
  );

  const iconButtonClass = useMemo(
    () =>
      useColorModeValue(
        styles.IconButtonStyle_Light,
        styles.IconButtonStyle_Dark
      ),
    [colorMode]
  );

  const onRefresh = (filters = appliedFilters) => {
    const filterData = getFilterQuery(filters);

    dispatch(fetchAllCards(filterData));
  };

  const onApplyQuickFilter = (filterData) => {
    const tempData = {
      ...initialAppiedFilterState,
      dateRanges: filterData.dateRanges,
      selectedLifeCycle: filterData.selectedLifeCycle,
      dateRangeColumn: filterData.dateRangeColumn,
    };

    setAppliedFilters(tempData);

    onRefresh(tempData);
  };

  const onStatusDropdownChange = async (selectedOption) => {
    setAppliedFilters((prev) => {
      const tempFilters = {
        ...prev,
        selectedLifeCycle: selectedOption,
      };

      onRefresh(tempFilters);

      return tempFilters;
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleChangeForDate = (key, value) => {
    setAppliedFilters((prev) => {
      const updatedData = {
        ...prev,
        [key]: value,
      };

      /**
       * If the date range type is changed then checking if the dates are applied
       * If the date ranges are changed then making the API call to fetch the data
       */
      if (
        (key === 'dateRangeColumn' && checkIfDateRangeIsApplied(updatedData)) ||
        key === 'dateRanges'
      ) {
        onRefresh(updatedData);
      }

      return updatedData;
    });
  };

  // eslint-disable-next-line no-unused-vars
  const onReset = () => {
    setSearchText('');
    setAppliedFilters({ ...initialAppiedFilterState });
    onRefresh(initialAppiedFilterState);
  };

  useEffect(() => {
    appliedFiltersRef.current = {
      ...appliedFilters,
      isCardView,
    };
  }, [isCardView, appliedFilters]);

  const fetchData = async () => {
    try {
      await dispatch(fetchQuickFilters());
      // eslint-disable-next-line no-empty
    } catch (fetchAllCardsError) {}
  };

  useEffect(() => {
    fetchData();
    if (Object.keys(AppliedFilters).length) {
      const updatedAppliedFilters = {
        ...AppliedFilters,
      };

      delete updatedAppliedFilters.isCardView;
      setIsCardView(
        typeof AppliedFilters?.isCardView === 'boolean'
          ? AppliedFilters?.isCardView
          : true
      );
      setAppliedFilters({ ...updatedAppliedFilters });

      onRefresh(updatedAppliedFilters);
    } else {
      setTimeout(() => {
        onRefresh(initialAppiedFilterState);
      }, 500); // This is to avoid the flickering of the data on first load
    }

    return () => {
      dispatch(saveAppliedFilters(appliedFiltersRef.current));
    };
  }, []);

  return (
    <Flex
      gap="10px"
      flexWrap="wrap"
      data-testid="HeaderContainerFlex"
      justify="space-between"
      width="100%"
    >
      <Text
        fontSize="20px"
        fontWeight="600"
        alignSelf="center"
        data-testid="HeaderTSCText"
      >
      {title}
      </Text>

      <HStack justifyContent="space-between" spacing="10px">
        <Tooltip
          label={
            isCardView
              ? CARD_LISTS_TOOLTIP_LABELS.SHOW_LIST_VIEW
              : CARD_LISTS_TOOLTIP_LABELS.SHOW_CARD_VIEW
          }
          placement="top"
          hasArrow
          data-testid="HeaderTooltip"
        >
          <Box data-testid="HeaderCButtonBox">
            <Box
              id="HeaderCButtonBoxEmptyBtn"
              position="relative"
              onClick={() => {
                setIsCardView((prev) => !prev);
              }}
              className={` ${useColorModeValue(
                styles.refreshButtonLight,
                styles.refreshButtonDark
              )}`}
            >
            {isCardView?
              <Squares2X2Icon
                height="20px"
                width="20px"
                color={useColorModeValue(
                  styles.refreshButtonLight,
                  styles.refreshButtonDark
                )}
              />:
              <FaList 
              height="20px"
              width="20px"
              color={useColorModeValue(
                styles.refreshButtonLight,
                styles.refreshButtonDark
              )}
              />}
            </Box>
            {/* Icon to swtich between Card View & Table View */}
            {/* <CustomButton
              style={{ height: standardHeight }}
              variant="outline"
              buttonText=""
              svg={
                isCardView
                  ? useColorModeValue(CardListLight, CardListDark)
                  : useColorModeValue(ListLight, ListDark)
              }
              svghover={isCardView ? CardListDark : ListDark}
              onClick={() => {
                setIsCardView((prev) => !prev);
              }}
              position="relative"
              h={standardHeight}
              id="HeaderCButtonBoxEmptyBtn"
            /> */}
          </Box>
        </Tooltip>

        <Box
          data-testid="HeaderCardCountBox"
          border="1px solid #DBDDE0"
          borderRadius="5px"
          display="flex"
          alignItems="center"
          className={`py-[4px] ${useColorModeValue(
            styles.refreshButtonLight,
            styles.refreshButtonDark
          )}`}
        >
          <Box
            mr="10px"
            className={`${useColorModeValue(
              sumStyles.ticketCountFieldLabel,
              sumStyles.ticketCountFieldLabelDark
            )}`}
          >
            Count
          </Box>
          <Box>{cardCount}</Box>
        </Box>
        {/* Dropdown to select the Life cycle */}
        {/* <Box> */}
          <CustomSelectBox
            className={styles.selectBoxStyle}
            value={appliedFilters.selectedLifeCycle}
            onChange={onStatusDropdownChange}
            options={STATUSES}
            styles={selectDarkStyle}
            // size="md"
            id="HeaderCButton1SelectBox"
          />
        {/* </Box> */}

        <ButtonGroup data-testid="HeaderButtonGroup">
          <QuickFilterPopOver
            iconButtonClass={iconButtonClass}
            onApplyQuickFilter={onApplyQuickFilter}
            standardHeight={standardHeight}
          />

          <Box data-testid="sort">
            <SortPopOver
              appliedFilters={appliedFilters}
              setAppliedFilters={setAppliedFilters}
              iconButtonClass={iconButtonClass}
              selectDarkStyle={selectDarkStyle}
              onRefresh={onRefresh}
              standardHeight={standardHeight}
            />
          </Box>

          {/*//////////////// refetch/reset table button */}
          <Tooltip
            label={"Reset Filter"}
            placement="top"
            hasArrow
            data-testid="Refr"
          >
            <Box
              // className={iconButtonClass}
              onClick={() => onReset()}
              data-testid="HeaderRefreshButton"
              className={useColorModeValue(
                styles.refreshButtonLight,
                styles.refreshButtonDark
              )}
              // style={{ height: standardHeight }}
            >
                    <MdRefresh size={24} />
            </Box>
          </Tooltip>
          {/*/////////////////////////////// */}

          <Tooltip
            label={CARD_LISTS_TOOLTIP_LABELS.RESET}
            placement="top"
            hasArrow
            data-testid="HeaderButtonTooltip"
          >
            <Box
              // className={iconButtonClass}
              onClick={() => onRefresh()}
              data-testid="HeaderRefreshButton"
              className={useColorModeValue(
                styles.refreshButtonLight,
                styles.refreshButtonDark
              )}
              // style={{ height: standardHeight }}
            >
              {/* <ReactSVG src={useColorModeValue(RefreshIcon, ReloadDarkIcon)} /> */}

              <ArrowPathIcon width="20px" height="20px" />
            </Box>
          </Tooltip>
        </ButtonGroup>

        <Box>
          <Input
            value={searchText}
            onChange={setSearchText}
            data-testid="HeaderSearchInput"
            standardHeight={standardHeight}
          />
        </Box>

        {/* <Flex data-testid="HeaderDateFilterFlex" h="40px">
        <DateFilter
          dateRangeColumn={appliedFilters.dateRangeColumn}
          handleChange={handleChangeForDate}
          dateRanges={appliedFilters.dateRanges}
        />
      </Flex> */}

        {/* <Box data-testid="HeaderRightSideMenuBox">
        <RightSideMenu />
      </Box> */}

        <CustomButton
          buttonText="Create"
          variant="solid"
          onClick={onCreateNewTemplate}
          style={{
            backgroundColor: useColorModeValue(COLORS.LIGHT, COLORS.DARK),
            height: standardHeight,
            color: '#fff',
          }}
          id="HeaderCButtonBoxCreateBtn"
        />
      </HStack>
    </Flex>
  );
};

Header.propTypes = {
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  cardCount: PropTypes.node,
  appliedFiltersRef: PropTypes.object.isRequired,
  onCreateNewTemplate: PropTypes.func.isRequired,
  isCardView: PropTypes.bool.isRequired,
  setIsCardView: PropTypes.func.isRequired,
};

Header.defaultProps = {
  cardCount: null,
};

export default Header;
