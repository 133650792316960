import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { cloneDeep, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import TableLayout from '../TenantFilters/TableLayout';
import AddNewAudience from './AddNewAudience';
import Loader from '../DynamicRenderer/Dashboard/Loader';

import { getDataForIndeterminateCheckbox, getIntent } from '../CreateEditIntentBuilder/intent.service';
import { columns, enrichDataForEdit } from './utils';
import { getQueueList } from '../../pages/CreateMailServer/utils';
import {
  createAudience,
  deleteAudience,
  getAudiences,
  updateAudience,
  getOfferLinkedAudiences,
} from './audience.services';
import {
  getAudience,
  getIntentGroups,
  keys,
  addData,
  setData,
  deleteData,
  updateData,
} from '../../redux/FieldDefaults';
import { getIdNameMap } from '../../components/IndeterminateCheckbox/utils';
import { outerRoutTableHight } from '../../utils/enums/constVariable';
import CustomToast from '../../components/Notification/Notification';

const MasterAudience = () => {
  const dispatch = useDispatch();

  const audiences = useSelector(getAudience);
  const intentGroups = useSelector(getIntentGroups);
  const [loader, setLoader] = useState(true);

  const [audienceEditDrawer, setAudienceEditDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const selectedAudience = useRef({});
  const isuseEffectTriggered = useRef(false);

  const [offerlinkedAudi, setOfferlinkedAudi] = useState([]);
  const { addToast } = CustomToast();

  const [actorsList, setActorsList]=useState([])
  const [queuesList, setQueuesList]=useState([])

  const onEditClick = useCallback((data = {}) => {
    selectedAudience.current = enrichDataForEdit(data);
    setAudienceEditDrawer(true);
  }, []);

  const onCreateClick = useCallback(() => {
    onEditClick();
  }, [onEditClick]);

  const onCreateOrEditAudience = useCallback((data) => {
    const temp = cloneDeep(data);
    const id = temp?.id;
    delete temp.id;

    setIsLoading(true);
    const promise = id ? updateAudience(temp, id) : createAudience(temp);
    promise
      .then((res) => {
        if (res) {
          if (!id) {
            dispatch(addData({ key: keys.AUDIENCE, data: res }));
          } else {
            dispatch(updateData({ key: keys.AUDIENCE, data: res, id }));
          }
          setAudienceEditDrawer(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onDeleteAudience = useCallback((data) => {

    const isLinked = offerlinkedAudi.find((item) =>
      item.audiences.includes(data.id)
    );
    if (isLinked) {
      addToast({
        title: 'Audience is linked to an offer',
        message: `This audience is linked to an ${isLinked.name}. Please remove the link before deleting the audience.`,
        type: 'info',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    deleteAudience(data?.id).then((res) => {
      if (res) {
        dispatch(deleteData({ key: keys.AUDIENCE, id: data?.id }));
      }
    });
  }, [offerlinkedAudi, dispatch])

  const intentOptions = useMemo(() => {
    const ig = intentGroups?.intent_groups;
    if (!Array.isArray(ig)) {
      return [];
    }

    const ed = getDataForIndeterminateCheckbox(ig);
    return ed;
  }, [intentGroups]);

  const intentOptionsMap = useMemo(
    () => getIdNameMap(intentOptions),
    [intentOptions]
  );

  const cols = useMemo(() => {
    const c = columns({
      onEditClick,
      onDelete: onDeleteAudience,
      optionsMap: intentOptionsMap,
      configObj: { actors: actorsList, queues: queuesList }
    });

    return c;
  }, [onEditClick, onDeleteAudience, intentOptionsMap, actorsList, queuesList]);

  useEffect(() => {
    getQueueList().then((qList) => {
      const users = qList.response ?? [];
      setQueuesList(users);
    });
    },[]);

  useEffect(() => {
    if (isEmpty(offerlinkedAudi)) {
      getOfferLinkedAudiences().then((res) => {
        if (res) {
          const linkedAudiences = res.filter((item) => item?.audiences?.length);
          setOfferlinkedAudi(linkedAudiences);
        }
      });
    }
  }, [offerlinkedAudi]);

  useEffect(() => {
    if (isuseEffectTriggered.current) {
      return;
    }

    isuseEffectTriggered.current = true;

    if (isEmpty(intentGroups)) {
      getIntent().then((res) => {
        if (res) {
          dispatch(setData({ key: keys.INTENT_GROUPS, data: res }));
        }
      });
    }

    if (isEmpty(audiences)) {
      setLoader(true);
      getAudiences().then((res) => {
        dispatch(setData({ key: keys.AUDIENCE, data: res }));
        setTimeout(() => {
          setLoader(false);
        }, 500);
      });
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  }, [intentGroups, audiences]);

  if (loader) {
    return <Loader />;
  }
  
  return (
    <>
      <TableLayout
        tableData={audiences || []}
        title="Audience"
        addBtnText="Create Audience"
        tableHight={outerRoutTableHight}
        onCreateButtonClick={onCreateClick}
        columns={cols}
      />

      <AddNewAudience
        selectedAudience={selectedAudience.current}
        onCreateOrEditAudience={onCreateOrEditAudience}
        intentOptions={intentOptions}
        open={audienceEditDrawer}
        setOpen={setAudienceEditDrawer}
        isLoading={isLoading}
        key={audienceEditDrawer.toString()}
        setActorsList={setActorsList}
        actorsList={actorsList}
      />
    </>
  );
};

export default MasterAudience;
