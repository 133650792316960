import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { InputGroup, InputRightElement } from '@chakra-ui/input';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
// import { Box } from '@chakra-ui/react';
import CustomInput from '../../components/InputBox/Input';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import { debounce } from './utils';

const ListSearchView = ({
  // eslint-disable-next-line no-unused-vars
  // name,
  // size,
  listKey,
  placeholder,
  // style,
  searchKey = 'label',
  filterKey,
}) => {
  const dispatch = useDispatch();
  const { viewState } = useSelector((state) => state);

  const inputRef = useRef('');
  const data = useMemo(
    () => viewState?.[listKey]?.data,
    [viewState?.[listKey]]
  );

  const [searchInputValue, setSearchInputValue] = useState('');
  const [isLoadedAlready, setIsLoadedALready] = useState(false);

  const onSearch = debounce(() => {
    setIsLoadedALready(true);

    inputRef.current = searchInputValue;

    if (!isLoadedAlready && !filterKey) {
      dispatch(
        updateViewState({
          stateKey: listKey,
          value: {
            ...viewState[listKey],
            originalData: viewState[listKey]?.data,
          },
        })
      );
    }
    let filteredList;
    if (searchInputValue.trim() !== '') {
      let list = filterKey
        ? viewState?.[listKey]?.data
        : viewState?.[listKey]?.originalData;
      list = Array.isArray(list) ? list : [];
      filteredList = list.filter((ins) =>
        ins?.[searchKey]
          ?.toLowerCase()
          ?.includes(searchInputValue?.toLowerCase())
      );
    } else {
      filteredList = filterKey
        ? viewState?.[listKey]?.data || []
        : viewState?.[listKey]?.originalData || [];
    }

    dispatch(
      updateViewState({
        stateKey: filterKey || listKey,
        value: {
          ...viewState[listKey],
          data: filteredList,
        },
      })
    );
  }, 100);

  useEffect(() => {
    onSearch();
  }, [data, searchInputValue]);
  const handleClearInput = () => {
    setSearchInputValue('');
  };
  return (
    <div data-testid="ListSearchViewDiv">
      {/* <CustomInput
        placeholder={placeholder}
        type="text"
        size={size}
        onChange={(e) => onSearch(e.target.value)}
        style={style}
      /> */}
      <InputGroup className="flex justify-center items-center">
        <CustomInput
          placeholder={placeholder}
          type="text"
          size="sm"
          value={searchInputValue}
          onChange={(e) => {
            setSearchInputValue(e.target.value);
            onSearch();
          }}
          id="ListSearchViewDivCInput"
        />
        <InputRightElement
          style={{ cursor: 'pointer', width: '24px', position: 'absolute', right: '10px' }}
          className="calculatedHeight"
        >
          {!searchInputValue ? <SearchIcon color="#94A3B8" /> : <CloseIcon onClick={handleClearInput} color="#94A3B8" />}
        </InputRightElement>
      </InputGroup>
    </div>
  );
};

ListSearchView.propTypes = {
  // name: PropTypes.string.isRequired,
  // size: PropTypes.string,
  listKey: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  // style: PropTypes.object,
  searchKey: PropTypes.string.isRequired,
  filterKey: PropTypes.string,
};

ListSearchView.defaultProps = {
  placeholder: '',
  // style: {},
  // size: 'sm',
  filterKey: '',
};

export default ListSearchView;
