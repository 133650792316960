/* eslint-disable indent */
import CustomToast from '../../components/Notification/Notification';

import { getTenantData } from '../../helpers/tenantUrl';
import axiosInstance, { axiosInstanceWithoutBaseUrl } from '../../utils/axios/axios';

const { addToast } = CustomToast();

const showToastMessage = ({ status = 'success', description }) => {
  addToast({
    title: status === 'success' ? 'Success' : 'Something went wrong',
    message: description,
    type: status,
  });
};

export const cloneTemplate = async (id, name) => {
  try {
    const res = await axiosInstance.post('/templates/clone', {
      data: { id, name },
    });
    if (res.status < 300) {
      showToastMessage({
        description: 'Successfully cloned the template',
      });
      return {
        response: res.data,
        error: null,
      };
    }
    throw new Error('Something went wrong');
  } catch (err) {
    showToastMessage({
      status: 'error',
      description:
        err?.response?.data?.message || 'Failed to clone the template',
    });
    return {
      response: null,
      error: err?.response?.data?.message || 'Failed to clone the template',
    };
  }
};

export const deleteTemplate = async (id) => {
  try {
    const res = await axiosInstance.delete(
      `templates/delete?$filter=id eq ${id}`
    );
    if (res.status < 300) {
      showToastMessage({
        description: 'Successfully deleted the template',
      });
      return {
        response: res.data,
        error: null,
      };
    }
    throw new Error('Something went wrong');
  } catch (err) {
    showToastMessage({
      status: 'error',
      description:
        err?.response?.data?.message || 'Failed to delete the template',
    });
    return {
      response: null,
      error: err?.response?.data?.message || 'Failed to delete the template',
    };
  }
};

export const toggleFavoriteForTemplate = async (id, isFavorite) => {
  try {
    const requestBody = {
      data: {
        template_id: id,
        tenant_id: getTenantData()?.id,
      },
    };

    const endpoint = isFavorite ? '/favourites/remove' : 'favourites/add';

    const res = await axiosInstance.post(endpoint, requestBody);

    if (res.status < 300) {
      showToastMessage({
        description: isFavorite
          ? 'Removed from favourites'
          : 'Added to favourites',
      });
      return {
        response: res.data,
        error: null,
      };
    }
    throw new Error('Something went wrong');
  } catch (err) {
    const defaultErrorMessage = isFavorite
      ? 'Failed to remove from favourites'
      : 'Failed to add to favourites';

    showToastMessage({
      status: 'error',
      description: err?.response?.data?.message || defaultErrorMessage,
    });
    return {
      response: null,
      error: err.toString(),
    };
  }
};

export const getCreatedByInfo = (cardData) => {
  const owners = cardData?.properties?.owners;
  const createdBy = cardData?.created_by;
  if (createdBy) {
    return createdBy;
  }
  if (typeof owners === 'string' && owners.trim() !== '') {
    return owners.trim();
  }
  if (createdBy === null || createdBy === '') {
    return 'No Info';
  }
  return 'No Info';
};

export const getChildren = (allTemplates, currentTemplate) => {
  if (currentTemplate.properties?.child_templates_list.length) {
    const {
      properties: { child_templates_list: childTemplateIds },
    } = currentTemplate;
    const childIdStrings = childTemplateIds
      .filter((id) => id)
      .map((id) => id.toString());
    const children = allTemplates.filter((template) =>
      childIdStrings.includes(template.id)
    );
    return children;
  }

  return [];
};

export const getTreeItem = (allTemplates, template, children) => ({
  id: template.id,
  name: template.name,
  parent_id: template.properties?.parent_id,
  properties: {
    description: template?.properties?.description,
    parent_id: template.properties?.parent_id,
    owners: template.properties?.owners,
    prefix: template.properties?.prefix,
    defaultTicket: template.properties?.defaultTicket || false,
  },
  lifecycle_status: template.lifecycle_status,
  created_by: template.created_by,
  updated_on: template.updated_on,
  children: children.length
    ? children.map((child) =>
        getTreeItem(allTemplates, child, getChildren(allTemplates, child))
      )
    : [],
});

export const getTree = (allTemplates) => {
  const rootTemplates = allTemplates?.filter(
    (template) => !template?.properties?.parent_id
  );

  const tree = rootTemplates?.map((rootLevelTemplate) =>
    getTreeItem(
      allTemplates,
      rootLevelTemplate,
      getChildren(allTemplates, rootLevelTemplate)
    )
  );

  return tree;
};


export const deleteTriggersFromWarningModel = async (id,templateName) => {
  if(!id) return false 
  let getTemplateOffers = [];
  let getTemplateTriggers = [];
  await axiosInstance.get(`fieldDefaults/list?$select=*&$filter=item_type eq '${id}'`).then((res)=>{
    getTemplateOffers = res?.data?.rows ?? [] ;
  })
  await axiosInstanceWithoutBaseUrl.get(`/botmgmt-service/BotIntegration/getTriggers?$skip=0&$top=1000`).then((res)=>{
    getTemplateTriggers = res?.data?.data ?? [];
  })
  let manipulatedData = getTemplateOffers?.map((item) => {
    let getOfferidParent = getTemplateTriggers?.filter((data) => {
      if( data.triggerName === item?.name && data.itemType==id) return true
      else return false
    });
  
    return { 
      id: item?.id, 
      isPresented: getOfferidParent?.length > 0 ?? false, 
      getOfferidParent
    };
  }) ?? [];
  let collectData = manipulatedData.filter((data)=>data.isPresented).map((data)=> {
    let collectTransformedData = []
    if(data?.getOfferidParent){
      for (let offer of data?.getOfferidParent){
         let triggerIntent =`${templateName?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase()}-${data?.id}`?.toLowerCase()?.replace(/\s+/g, '')
        // let flowName =  offer.metadata?.find((data)=>data.name === "triggerName")?.value ??  `${templateName?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase()}-${data.id}`?.toLowerCase()?.replace(/\s+/g, '') ?? offers.metadata?.find((data)=>data.name === "flow").value+"-"+data.id
        collectTransformedData.push({offerId:data.id ,triggerIntent:triggerIntent,offerName:offer.triggerName,selectedTemplateId:offer.itemType })
      }
    }
    return collectTransformedData
  })
  return collectData.flat(Infinity)
}

export const deleteTriggersForOffers = async (offer) => {
  let getTemplateTriggers = []
  await axiosInstanceWithoutBaseUrl.get(`/botmgmt-service/BotIntegration/getTriggers?$skip=0&$top=1000`).then((res)=>{
    getTemplateTriggers = res?.data?.data ?? [];
  })
  let getData = getTemplateTriggers?.filter((data) => {
    if(data.triggerName===offer.offerName && data.itemType==offer?.selectedTemplateId){return true}
    else return false
}).map((singleOffer)=>{
  if(singleOffer?.triggerName === offer.offerName && singleOffer.itemType==offer?.selectedTemplateId){
    let triggerIntent =`${offer?.selectedTemplate?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase()}-${offer?.offerId}`?.toLowerCase()?.replace(/\s+/g, '')
    return {offerId:offer.offerId,triggerIntent:triggerIntent,selectedTemplateId:offer.selectedTemplateId,offerName:offer.offerName}
  }
  else return {}
    
  })
  return getData
}

export const deleteTriggerConfirmation = async (data) =>{
      let triggers=[]
      try{
        const response = await axiosInstanceWithoutBaseUrl.get(
            `/botmgmt-service/BotIntegration/getTriggers?$skip=0&$top=1000`
        );
        if (Array.isArray(response?.data?.data)) {
            triggers=response?.data?.data
        }
      }
      catch(err){
        return []
      }
      const ids=triggers.filter(item=>{
        if(data.find(ele=>ele.offerName===item.triggerName && ele.selectedTemplateId===item.itemType)){
          return item
        }
        else{
          return null
        }
      })
      const intents=data.filter(item=>{
        if(triggers.find(ele=>ele.triggerName===item.offerName && ele.itemType===item.selectedTemplateId && ele.status==='Published')){
          return item
        }
        else return null
      })
      const deletePayload={
        id:ids.map(item=>item.id),
        triggerIntent:intents.map(item=>item.triggerIntent),
        isPublish:true
      }
      try {
        const response=await axiosInstanceWithoutBaseUrl.put(`botmgmt-service/BotIntegration/deleteTrigger`,deletePayload)
        if (response) {
         return [];
        }
      }
      catch(err){
        return [];
      }
}