// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dd9lVOiMlv7jN8EnxCn0{display:flex;align-items:center;gap:1rem}.meYaeLMjBezNGkbWgPmf{border-radius:50% !important;min-width:1.5rem !important;height:1.5rem !important}.MK9X5BBGOraibyeCi_nl{margin-right:10px !important}.vWZSynyA0VdHRC7Jt0bP{width:655px !important;margin-bottom:1rem;margin-top:15px}.oMcFR4d1ohodJBEHjvT1{border:1px solid #f2f2f2;border-radius:.375rem;padding:10px;font-size:14px}.oMcFR4d1ohodJBEHjvT1 .v3GIX2IODWOt3nZHuhTo{max-width:150px}`, "",{"version":3,"sources":["webpack://./src/pages/Audience/audience.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,sBACE,4BAAA,CACA,2BAAA,CACA,wBAAA,CAGF,sBACE,4BAAA,CAGF,sBACE,sBAAA,CACA,kBAAA,CACA,eAAA,CAGF,sBACE,wBAAA,CACA,qBAAA,CACA,YAAA,CACA,cAAA,CAGF,4CACI,eAAA","sourcesContent":[".drawer__header {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n}\n\n.drawer__close__button {\n  border-radius: 50% !important;\n  min-width: 1.5rem !important;\n  height: 1.5rem !important;\n}\n\n.drawer__footer__close__button {\n  margin-right: 10px !important;\n}\n\n.audienceBox {\n  width: 655px !important;\n  margin-bottom: 1rem;\n  margin-top: 15px;\n}\n\n.dynamicSelectWithInput{\n  border:1px solid #f2f2f2;\n  border-radius:0.375rem;\n  padding: 10px;\n  font-size: 14px;\n}\n\n.dynamicSelectWithInput .operator{\n    max-width: 150px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawer__header": `Dd9lVOiMlv7jN8EnxCn0`,
	"drawer__close__button": `meYaeLMjBezNGkbWgPmf`,
	"drawer__footer__close__button": `MK9X5BBGOraibyeCi_nl`,
	"audienceBox": `vWZSynyA0VdHRC7Jt0bP`,
	"dynamicSelectWithInput": `oMcFR4d1ohodJBEHjvT1`,
	"operator": `v3GIX2IODWOt3nZHuhTo`
};
export default ___CSS_LOADER_EXPORT___;
