/* eslint-disable indent */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Table,
  TableContainer,
  Th,
  Tr,
  Td,
  Thead,
  useColorModeValue,
  Box,
  Icon
} from '@chakra-ui/react';
import { capitalize, cloneDeep, startCase, sortBy, get } from 'lodash';
import { MdDragIndicator } from 'react-icons/md';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import SortableTable from '../Dnd/SortableTable';
import TableColumnText from '../Text/TableColumnText';
import {
  BLACK,
  BORDER_DARK,
  BORDER_LIGHT,
  COLORS,
  WHITE,
} from '../../utils/enums/colors';
import styles from './table.module.scss';
import TriangleRightIcon from '../../assets/icons/TriangleRightIcon.svg';
import TriangleDown from '../../assets/icons/TriangleDown.svg';
import Circle from '../../assets/icons/tableCircle.svg';
import Loader from '../../pages/DynamicRenderer/Dashboard/Loader';

const CustomTable = (props) => {
  const {
    columns,
    tableData,
    isTableLoading,
    onRowClick,
    rowIdKey,
    hideTableHead,
    nestedTable,
    height,
    title,
    sortableConfig
  } = props;
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (e, rowId) => {
    e.stopPropagation();
    if (expandedRows[rowId]) {
      setExpandedRows((prev) => {
        const temp = { ...prev };
        delete temp[rowId];
        return temp;
      });
    } else {
      setExpandedRows((prev) => {
        const temp = { ...prev };
        temp[rowId] = true;
        return temp;
      });
    }
  };

  const getColumnContent = ({ column, td, index, subIndex }) => {
    const columnCellContent =
      column?.cellRenderer && typeof column.cellRenderer === 'function' ? (
        column.cellRenderer({
          rowData: td,
          column,
          rowIndex: index,
        })
      ) : (
        <TableColumnText text={td[column.col_name]} />
      );

    if (nestedTable) {
      if (subIndex === 0) {
        return (
          <div className="flex gap-[10px] max-w-[300px] items-center">
            <div
              style={{
                paddingLeft: (td?.nestedLevel || 0) * 30,
              }}
            >
              {td?.children?.length > 0 ? (
                <div onClick={(e) => toggleRow(e, td?.id)}>
                  {expandedRows[td?.id] ? (
                    <ReactSVG
                      src={TriangleDown}
                      className={useColorModeValue(styles.light, styles.dark)}
                    />
                  ) : (
                    <ReactSVG
                      src={TriangleRightIcon}
                      className={useColorModeValue(styles.light, styles.dark)}
                    />
                  )}
                </div>
              ) : (
                <div className="">
                  <ReactSVG src={Circle} />
                </div>
              )}
            </div>
            <div className="flex-1">{columnCellContent}</div>
          </div>
        );
      }
    }
    return columnCellContent;
  };

  const memoizedTableData = useMemo(() => {
    if (nestedTable) {
      const tempData = [];
      const nestData = (data, nestedLevel) => {
        data?.forEach((d) => {
          const temp = { ...d, nestedLevel };
          tempData.push(temp);
          if (expandedRows[temp.id]) {
            nestData(temp.children, nestedLevel + 1);
          }
        });
      };
      nestData(tableData, 0);
      return tempData;
    }
    return tableData;
  }, [tableData, nestedTable, expandedRows]);
  const [rowsData, setRowsData] = useState([]);
  useEffect(() => {
    const sortedData = sortBy(memoizedTableData, (item) => get(item, 'properties.position', Infinity));
    setRowsData(sortedData);
  }, [memoizedTableData]);
  const config = {
    ...sortableConfig,
    onTableOrderChange: (sortedList) => setRowsData(sortedList)
  };
  const tableRef = useRef(null);
  const handleScrollDuringDrag = (evt) => {
  const container = tableRef.current;
  if (!container) return;

  const containerRect = container.getBoundingClientRect();
  const scrollSpeed = 10;
  const mouseY = evt.originalEvent.clientY;
  const threshold = 50;
  const contSkipNum = 100;
  if (mouseY < containerRect.top + contSkipNum) {
    const newScrollTop = Math.max(0, container.scrollTop - scrollSpeed);
    container.scrollTop = parseInt(newScrollTop - threshold, 10);
  } else if (mouseY > containerRect.bottom - contSkipNum) {
    const maxScrollTop = container.scrollHeight - container.clientHeight;
    const newScrollTop = Math.min(maxScrollTop, container.scrollTop + scrollSpeed);
    container.scrollTop = parseInt(newScrollTop + threshold, 10);
  }
  };
  return (
    <TableContainer
      ref={tableRef}
      borderRadius="10px 10px 0px 0px"
      // borderWidth="1.5px"
      fontSize="14px"
      borderTop="1.5"
      borderBottom="0px"
      borderColor={useColorModeValue(BORDER_LIGHT, BORDER_DARK)}
      color={useColorModeValue(COLORS.BLACK, COLORS.TEXT_DARK)}
      data-testid="CusTableTbleContainer"
      height={height}
      overflowWrap="anywhere"
      overflow="auto"
      style={{ overflowY: 'auto' , scrollbarWidth:'thin', paddingRight:'10px' }}
      // css={{
      //   '&::-webkit-scrollbar': {
      //     display: 'none' /* Chrome, Safari, Opera */,
      //   },
      // }}
    >
<Table variant="simple" data-testid="CusTable">
  {!hideTableHead && (
    <Thead
      position="sticky" // fixing the header to the top while scrolling
      top={0} // fixing the header to the top while scrolling
      zIndex={1}
      bg={useColorModeValue(WHITE, BLACK)}
      data-testid="CusTableThead"
      className="border-b-2"
    >
      <Tr data-testid="CusTableTr" padding="10px">
        {columns.map((column, idx) => (
          <Th
            paddingLeft="0px"
            paddingRight="0px"
            paddingTop="20px"
            paddingBottom="20px"
            color={useColorModeValue(BLACK, WHITE)}
            key={column?.col_name}
            width={column?.col_name === 'Ticket Type Title'? '500px':`${100 / (columns.length - 1)}%`}
            textAlign={
              column.col_name === 'actions'
                ? 'center'
                : column.align || 'start'
            }
            className={`${useColorModeValue(
              styles.tableRowDataLight,
              styles.tableRowDataDark
            )}`}
            fontWeight="500"
            fontSize="14px"
            lineHeight="normal"
            style={{ ...column?.headerStyle, textTransform: 'none' }}
            colSpan={config?.isSortable && idx === 0 ? 2 : 1}
          >
            {column?.headerRenderer && typeof column.headerRenderer === 'function'
              ? column.headerRenderer({ column })
              : column?.textAsItIs
              ? column?.col_name
              : capitalize(startCase(column?.col_name).toLowerCase())}
          </Th>
        ))}
      </Tr>
    </Thead>
  )}

  <SortableTable
    data={rowsData}
    config={config}
    handleScrollDuringDrag={handleScrollDuringDrag}
  >
    {!rowsData.length ? (
      <Tr>
        <Td colSpan={columns.length} textAlign="center">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="calc(100vh - 22rem - 60px)"
            fontSize="16px"
            color={useColorModeValue(COLORS.BLACK, COLORS.TEXT_DARK)}
            data-testid="NoRecordsMessage"
          >
            {isTableLoading ? (
              <Loader height="100px" />
            ) : (
              `No Record Found For ${title || 'data'}`
            )}
          </Box>
        </Td>
      </Tr>
    ) : (
      rowsData.map((td, index) => (
        <Tr
          key={`rowId-${td.id}`}
          {...(rowIdKey && typeof rowIdKey === 'function'
            ? { id: rowIdKey(td) }
            : {})}
          {...(typeof onRowClick === 'function'
            ? { className: 'cursor-pointer' }
            : {})}
          className="border-b-1 table-row"
        >
          {config?.isSortable && (
            <Td
              paddingInlineEnd="unset"
              paddingInline="unset"
              padding="unset"
              marginRight={1}
              minWidth="40px"
              width="2%"
            >
          <Box
            display="flex"
            width="30px"
            height="60px"
            background="whitesmoke"
            boxShadow="1px 1px 5px #ccc"
            justifyContent="center"
            alignItems="center"
            cursor="grab"
            transition="transform 1.5s ease-in-out"
          >
                      <Icon as={MdDragIndicator} boxSize={5} />
          </Box>
            </Td>
          )}
          {columns.map((column, subIndex) => (
            <Td
              padding="16px 0px"
              cursor='pointer'
              className={`${
                useColorModeValue(styles.tableRowDataLight, styles.tableRowDataDark)
              }`}
              key={`table_data_${index + 1}_${`${subIndex + 1}`}`}
              {...(onRowClick &&
                typeof onRowClick === 'function' && {
                  onClick: (e) => {
                    localStorage.removeItem('activeTabIndex');
                    localStorage.removeItem('settingsTabType');
                    onRowClick({
                      event: e,
                      rowData: td,
                      column,
                      rowIndex: index,
                    });
                  },
                })}
            >
              {getColumnContent({
                column,
                td,
                index,
                subIndex,
              })}
            </Td>
          ))}
        </Tr>
      ))
    )}
  </SortableTable>
</Table>

    </TableContainer>
  );
};

CustomTable.propTypes = {
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  isTableLoading: PropTypes.bool,
  rowIdKey: PropTypes.func,
  hideTableHead: PropTypes.bool,
  nestedTable: PropTypes.bool,
  level: PropTypes.number,
  height: PropTypes.string,
  title: PropTypes.string,
  sortableConfig:
    PropTypes.shape({
      isSortable: PropTypes.bool.isRequired,
      onToggleSortable: PropTypes.func.isRequired,
      onOrderChange: PropTypes.func.isRequired,
      onOrderSave: PropTypes.func.isRequired,
      btnTitle:
        PropTypes.shape({
          toggleOff: PropTypes.string,
          toggleOn: PropTypes.string,
        })
    })
};

CustomTable.defaultProps = {
  onRowClick: null,
  rowIdKey: undefined,
  isTableLoading: false,
  hideTableHead: false,
  nestedTable: false,
  level: 1,
  //Note:- plz don't default hight It impact many other place 
  // if needed send as props
  height: 'calc(100vh - 18rem)',
  title: 'data',
  sortableConfig:
    {
      isSortable: false,
      onToggleSortable: () => {},
      onOrderChange: () => {},
      onOrderSave: () => {},
    },
};

export default CustomTable;
