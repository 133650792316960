import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { Box } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import CustomButton from '../../components/Button/SimpleButton';
import CustomInput from '../../components/InputBox/Input';
import CustomTextArea from '../../components/Textarea/CustomTextArea';
import AttachmentUpload from '../../components/AttachmentUpload';
import CustomSelectBox from '../../components/SelectBox/Select';
import SelectFieldFromTemplateWithDelete from '../../components/selectFieldFromTemplateWithDelete';
import CustomInderminateCheckBox from '../../components/IndeterminateCheckbox';

import { FORM_MAP, OFFER_TYPES } from './fieldDefaults.utils';
import FIELD_DEFAULT_VALIDATIONS from '../../utils/validationSchemas/MasterFieldDefaults';
import { validationErrorMessage } from '../../utils/validationSchemas/validationSchemas';
import { getHierarchies } from '../../components/dynamicExpressionWithTags/dynamicExpressionWithTags.helper';
import { getTeamQueueMap } from '../../components/selectFieldFromTemplateWithDelete/utils';
import { mediaFormatSelectOpt2FileType } from '../../utils/enums/selectOption';
import CustomTabs from '../../components/Tabs/Tabs';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../components/Button/PrimarySecondaryButton';

const BASE_TABS = [
  {
    id: 'offer_details',
    title: 'Offer Details',
    tabType: 'OFFER',
  },
  {
    id: 'field_values',
    title: 'Field Values',
    tabType: 'FIELD',
  },
];

const getFlattenedItems = (schema) => {
  const flattenArr = [];
  const flattenItem = (item) => {
    if (item?.type === 'component') {
      if (item?.subItems && item?.subItems?.length > 0) {
        item?.subItems?.forEach((subItem) => {
          flattenItem(subItem);
        });
      }
    }
    flattenArr.push(item);
  };
  schema?.forEach((item) => {
    flattenItem(item);
  });

  const allFields = [];

  flattenArr.forEach((item) => {
    if (item.type === 'component') {
      allFields.push(...item.subItems);
    } else {
      allFields.push(item);
    }
  });

  return allFields;
};

const EditFieldDefault = ({
  currentFieldDefault,
  onCancel,
  OfferList,
  onSave,
  isLoading,
  audienceMap,
  audience,
  selectedTemplate,
  intentOptions,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [data, setData] = useState({ ...currentFieldDefault });
  const [error, setError] = useState({});
  const [hierarchy, setHierarchy] = useState(null);
  const [teamQueueMap, setTeamQueueMap] = useState([]);
  const [hierarchies, setHierarchies] = useState([]);

  useEffect(() => {
    getHierarchies(setHierarchies);
  }, []);

  const handleChange = useCallback((key, value) => {
    setData((prev) => ({
      ...prev,
      [key]: value,
    }));

    setError((prev) => ({
      ...prev,
      [key]: '',
    }));
  }, []);

  const handleSave = useCallback(() => {
    const payload = { ...data };

    payload.name = payload.name?.replace(/\s+/g, ' ').trim();
    payload.description = payload.description?.trim();

    const { error: validationErrors } = FIELD_DEFAULT_VALIDATIONS.validate(
      payload,
      {
        abortEarly: false,
      }
    );
    const isNameIsDuplicate = OfferList?.some(
      ({ name }) => name === payload?.name
    );

    if (validationErrors || isNameIsDuplicate) {
      let message = {};
      if (validationErrors) message = validationErrorMessage(validationErrors);
      if (isNameIsDuplicate) message[[FORM_MAP.NAME]] = 'This name is already been used';
      if (message[FORM_MAP.NAME] || message[FORM_MAP.DESCRIPTION]) {
        setTabValue(0);
      }
      setError(message);
      return;
    }

    if (!data?.[FORM_MAP.DESCRIPTION]) {
      delete data[FORM_MAP.DESCRIPTION];
    }

    onSave(payload);
  }, [data]);

  const blobNamesArray = useMemo(() => {
    if (data[FORM_MAP.THUMBNAIL]) {
      return [data[FORM_MAP.THUMBNAIL]];
    }
    return [];
  }, [data[FORM_MAP.THUMBNAIL]]);

  const optionsForAudience = useMemo(() => {
    const audiences = data?.[FORM_MAP.AUDIENCES];

    if (!Array.isArray(audiences)) {
      return null;
    }

    return audiences.map((a) => ({
      label: audienceMap[a],
      value: a,
    }));
  }, [audienceMap, data?.[FORM_MAP.AUDIENCES]]);

  const workflowFields = useMemo(() => {
    if (!selectedTemplate || !selectedTemplate?.workflow?.workflow_status) {
      return [];
    }

    const workflowStatus = selectedTemplate?.workflow?.workflow_status;

    if (!Array.isArray(workflowStatus)) {
      return [];
    }

    return workflowStatus
      .filter((s) => s?.id !== 'start_1')
      .map((s) => ({
        value: s?.id,
        label: s?.data?.label,
      }));
  }, [selectedTemplate]);

  const createLayoutFields = useMemo(() => {
    if (
      !selectedTemplate ||
      !selectedTemplate?.view_layout_map ||
      !selectedTemplate?.layouts
    ) {
      return [];
    }

    const createViewLayoutId =
      selectedTemplate.view_layout_map?.find(
        (vlMap) => vlMap.type === 'VIEW' && vlMap.view_id === 'create'
      )?.layout_id || null;

    const createLayout = selectedTemplate.layouts.find(
      (l) => l.id === createViewLayoutId
    );

    const fields = getFlattenedItems(createLayout?.schema);
    return fields.map((field) => field.id) || [];
  }, [selectedTemplate]);

  const showError = useCallback((err) => {
    if (!err?.trim()) {
      return null;
    }

    return <span className="field-error mt-1">{err}</span>;
  }, []);

  useEffect(() => {
    getTeamQueueMap()
      .then(setTeamQueueMap)
      .catch(() => setTeamQueueMap([]));
  }, []);

  useEffect(() => {
    if (
      !selectedTemplate?.field_hierarchy_map ||
      !selectedTemplate?.field_hierarchy_map?.length
    ) {
      return;
    }

    getHierarchies(setHierarchy);
  }, [selectedTemplate]);

  const showOfferContain = () => (
    <Box mb="20px">
      <>
        <div className="flex-1 pt-2">
          <CustomInput
            label="Offer Name"
            value={data?.[FORM_MAP.NAME] || ''}
            onChange={(e) => {
              handleChange(FORM_MAP.NAME, e.target.value);
            }}
            isError={!!error?.[FORM_MAP.NAME]}
            errorMessage={error?.[FORM_MAP.NAME] || ''}
            isMandatory
            //isDisabled={data?.[FORM_MAP.OFFER_TYPE] === OFFER_TYPES.TEMPLATE}
          />
        </div>
        <div className="flex-1 pt-2">
          <CustomTextArea
            id="EditOfferDescriptionCustomTextArea"
            label="Description"
            value={data?.[FORM_MAP.DESCRIPTION] || ''}
            onChange={(e) => {
              handleChange(FORM_MAP.DESCRIPTION, e.target.value);
            }}
            isError={!!error?.[FORM_MAP.DESCRIPTION]}
            errorMessage={error?.[FORM_MAP.DESCRIPTION] || ''}
            isMandatory
          />
        </div>
        <div className="flex-1">
          <div className="SCLabel mb-2">
            <span> Thumbnail </span>
            {/* <span className="text-[red] ml-1">*</span> */}
          </div>
          <AttachmentUpload
            blobName={blobNamesArray}
            onImageUpload={(imageData) => {
              handleChange(FORM_MAP.THUMBNAIL, imageData[imageData.length - 1]);
            }}
            onImageDelete={() => handleChange(FORM_MAP.THUMBNAIL, '')}
            maxFileSize={4 * 1024 * 1024}
            maxNoOfFileAllow={1}
            fileSupportForUpload={mediaFormatSelectOpt2FileType.images}
          />
          {showError(error?.[FORM_MAP.THUMBNAIL])}
        </div>
        <div className="flex gap-3 items-start pt-2">
          <div className="flex-1 ">
            <CustomSelectBox
              label="Audience"
              options={audience}
              value={optionsForAudience}
              onChange={(e) =>
                handleChange(
                  FORM_MAP.AUDIENCES,
                  e.map((v) => v.value)
                )
              }
              isMulti
              isError={!!error?.[FORM_MAP.AUDIENCES]}
              errorMessage={error?.[FORM_MAP.AUDIENCES] || ''}
            />
          </div>

          <div className="flex-1">
            <CustomInderminateCheckBox
              data={intentOptions}
              label="Intent Groups"
              value={data?.[FORM_MAP.INTENT_GROUPS] || []}
              onChange={(e) => handleChange(FORM_MAP.INTENT_GROUPS, e)}
              errorMessage={error?.[FORM_MAP.INTENT_GROUPS] || ''}
              parentSelectsChild={false}
            />
          </div>
        </div>
      </>
    </Box>
  );
  const showFieldValue = () =>
    selectedTemplate?.fields.length > 0 && (
      <div className="flex gap-3 items-start pt-2">
        <div className="flex-1">
          <SelectFieldFromTemplateWithDelete
            fieldList={selectedTemplate?.fields}
            value={data?.[FORM_MAP.FIELD_ATTRIBUTES] || []}
            workFlowStatusList={workflowFields}
            label="Field Attributes"
            parentFields={selectedTemplate?.fields || []}
            onChange={(value) => {
              handleChange(FORM_MAP.FIELD_ATTRIBUTES, value);
            }}
            key={selectedTemplate?.id}
            hierarchyObject={hierarchy}
            fieldHierarchyMap={selectedTemplate?.field_hierarchy_map || []}
            hierarchies={hierarchies}
            teamQueueMap={teamQueueMap}
            createLayoutFields={createLayoutFields}
            preventFieldRepeat
            hideWorkflowStatus
            actorAssociationMap={selectedTemplate?.actors || []}
            sourceTemplateName={selectedTemplate.name}
          />
          {showError(error?.[FORM_MAP.FIELD_ATTRIBUTES])}
        </div>
      </div>
    );

  return (
    <div
      className="p-4"
      style={{
        overflow: 'auto',
        height: 'calc(-9rem + 85vh)',
      }}
      position="relative"
    >
            <Box position="relative">
        <ArrowBackIcon
          className={`
        cursor-pointer
        border
        border-[#E2E8F0]
        p-1
        rounded-[5px]
      `}
          h="32px"
          w="32px"
          top="4px"
          position="absolute"
          zIndex={999}
          borderRadius="50%"
          onClick={onCancel}
        />
      </Box>
      <Box>
        <CustomTabs
          smallTabs="true"
          tabsData={BASE_TABS.map((tab) => ({
            ...tab,
            content:
              tab.tabType === 'OFFER' ? showOfferContain() : showFieldValue(),
          }))}
          tabRadius="100px"
          onChange={(tabIndex) => {
            setTabValue(tabIndex);
          }}
          actionHeaderComponent={
            <div className="flex justify-end items-center gap-2 pt-2">
              <SecondaryCustomButton
                // variant="outline"
                onClick={onCancel}
                buttonText="Cancel"
                isDisabled={isLoading}
                _hover={{ background: 'transparent' }}
              />

              <CustomButton
                variant="solid"
                onClick={() => (tabValue === 0 ? setTabValue(1) : handleSave())}
                colorScheme="#2563EB"
                buttonText={tabValue === 0 ? 'Next' : 'Save'}
                isLoading={isLoading}
                style={{backgroundColor:"#2563EB", height:"35px"}}
              />
            </div>
          }
          specialTab
          variant="unstyled"
          defaultTabIndex={tabValue}
          index={tabValue}
        />
      </Box>
    </div>
  );
};

EditFieldDefault.propTypes = {
  currentFieldDefault: PropTypes.object,
  isLoading: PropTypes.bool,
  audienceMap: PropTypes.object,
  OfferList: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  audience: PropTypes.array.isRequired,
  selectedTemplate: PropTypes.object.isRequired,
  intentOptions: PropTypes.array.isRequired,
};

EditFieldDefault.defaultProps = {
  currentFieldDefault: {},
  isLoading: false,
  audienceMap: {},
};

export default EditFieldDefault;
