import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { ReactSVG } from 'react-svg';
import { v4 as uuidv4 } from 'uuid';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _remove from 'lodash/remove';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _capitalize from 'lodash/capitalize';
import _uppercase from 'lodash/upperCase';
import _flattenDeep from 'lodash/flattenDeep';
import _startCase from 'lodash/startCase';
import _camelCase from 'lodash/camelCase';
import {
  FormLabel,
  Wrap,
  WrapItem,
  IconButton,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import _, { isEmpty } from 'lodash';
import { Group } from 'antd/es/avatar';
import CustomButton from '../../components/Button/SimpleButton';
import CustomSelect from '../../components/SelectBox/Select';
import styles from '../../components/dynamicSelectWithInputTags/dynamicSelectWithInputTags.module.scss';
import pageStyles from '../Audience/audience.module.scss'
import {
  COLORS,
  DARK,
  LIGHT,
  TEXT_DARK,
  TEXT_LIGHT,
  OPERATOR_BG
} from '../../utils/enums/colors';
import { LABELS } from '../../utils/enums/labels';
// eslint-disable-next-line import/no-cycle
import {
  // getActorsByTemplateId,
  ROLE_SETTING_TYPE_VS_INPUT_TYPE,
  ROLE_SETTING_TYPE_VS_OPTIONS,
  parseComponentValuesToAPIData,
  parseAPIDataToComponentValues,
  getActorsByTemplateId,
  getAppusers,
  getTenantRolesAndTeams,
  getUserAttributes,
  getGroup,
} from '../../components/dynamicSelectWithInputTags/dynamicSelectWithInputTags.helper';
import CustomField from '../../components/dynamicExpressionWithTags/CustomField';
import {
  roleSettingSelectOpt,
  roleMapping,
  userAttribute,
} from '../../utils/enums/selectOption';
import useKeycloak from '../../utils/hooks/useKeyCloak';
import { availableUsersListInTenantLevel } from '../../utils/helper/roleBasedAccess';
import { getQueueList } from '../../pages/CreateMailServer/utils';
import { SecondaryCustomButton } from '../../components/Button/PrimarySecondaryButton';
import { stripEmptyArraysFromObject } from '../../components/dynamicSelectWithTags/dynamicSelectWithTags.helper';

const DynamicSelectWithInput = ({
  label,
  selectOptions,
  mapping,
  onChange,
  roleSettingTypeVsInputType,
  roleSettingTypeVsOptions,
  values,
  templateId,
  isMandatory,
  disableAddButton,
  isReadOnly,
  isAddedOrganization,
  includeUserAttributes,
  showOnlyUserAttributes,
  showQueueMapField,
  actorOption,
  audience,
  setAudience,
  actorsList
}) => {
  const [options, setOptions] = useState([]);
  const [componentState, setComponentState] = useState({
    value: '',
    type: selectOptions[0],
  });
  const [attributeOptions, setAttributeOption] = useState([]);
  const [leftOptionList, setLeftOptionList] = useState([]);
  const [keyValuePairs, setKeyValuePairs] = useState([]);
  const [keycloak] = useKeycloak();
  const { selectedTemplate } = useSelector((state) => state.viewState || {});
  const [attributesLoaded, setAttributesLoaded] = useState(false);
  const [operator, setOperator] = useState({label:'IS',value:'IS'})

  const operatorOptions = [{label:'IS',value:'IS'},{label:'IN',value:'IN'},{label:'NOT IN',value:'NOT IN'}]
  const addKeyValue = ({ key,operator, value }) => {
    let valueByKey = _get(keyValuePairs, `[${key}]`, []) || [];
    if (key === 'queues' && !Array.isArray(value)) return;
    if (value?.label) {
      if (valueByKey.findIndex((vbk) => vbk.value === value.value) > -1) return;
    } else if (valueByKey.findIndex((vbk) => vbk === value) > -1) return;
    if (Array.isArray(value)) {
      valueByKey = [...valueByKey, ...value];
    } else {
      valueByKey.push(value);
    }
    setKeyValuePairs((prevState) => {
      const newState = {
        ...prevState,
        [key]: valueByKey,
      };
      const apiReadyData = parseComponentValuesToAPIData(newState);
      onChange(stripEmptyArraysFromObject(apiReadyData));
      return newState;
    });
  };
 
  const removeKeyValue = ({ key, value }) => {
    const valueByKey = _cloneDeep(keyValuePairs[key]);
    _remove(valueByKey, (vbk) => {
      if (vbk.value && vbk.value === value.value) {
        return true;
      }
      return vbk === value;
    });
    setKeyValuePairs((prevState) => {
      // Create a shallow copy of prevState
      const newState = { ...prevState };

      // Check if valueByKey is empty and delete the key if true
      if (Array.isArray(valueByKey) && valueByKey.length === 0) {
        delete newState[key];
      } else {
      // Otherwise, update the state with the new value
        newState[key] = valueByKey;
      }
      const apiReadyData = parseComponentValuesToAPIData(newState);
      onChange(stripEmptyArraysFromObject(apiReadyData));
      return newState;
    });
    setAudience((prevAudience) => {
      const updatedAudience = prevAudience
        .map((item) => {
          if (item.leftOperand === key) {
            if (Array.isArray(item.rightOperand)) {
              const updatedRightOperand = item.rightOperand.filter(
                (v) => v !== value
              );
              return updatedRightOperand.length > 0
                ? { ...item, rightOperand: updatedRightOperand }
                : null;
            } else if (item.rightOperand === value) {
              return null;
            }
          }
          return item;
        })
        .filter((item) => item !== null);
    
      return updatedAudience;
    });
  };

  useEffect(()=>{
    if(values){
      if(Array.isArray(values)) setAudience(values)
    }
    else setAudience([]) 
  },[values])

  useEffect(() => {
    /**
     * api call for get info
     */
    getTenantRolesAndTeams().then((res) => {
      let roles = res?.defaultProducts?.map((item) =>
        _map(item?.permissions, (perm) => ({
          value: perm,
          label: _startCase(_camelCase(perm)),
        }))
      );
      roles = _.uniqBy(_.flattenDeep(roles), 'value') || _flattenDeep(roles);
      setOptions((prevState) => ({
        ...prevState,
        roles,
      }));
      const teams = res?.team?.team?.map((item) => ({
        value: item,
        label: _startCase(_capitalize(item)),
      }));
      setOptions((prevState) => ({
        ...prevState,
        teams,
      }));
      const org = res?.domain?.split('.');
      if (!_isEmpty(org)) {
        setOptions((prevState) => ({
          ...prevState,
          org: org[0],
        }));
        setComponentState((prevState) => ({
          ...prevState,
          value: org[0],
        }));
      }
    });
  }, [keycloak]);

  useEffect(() => {
    if (componentState?.type?.value === 'queues') {
      if (showQueueMapField) {
        const queueFieldList = [...(selectedTemplate?.fields || [])].filter((item) => item.type === 'DROPDOWN' && _get(item, 'type_based_attributes.collection.collection_type') === 'SPECIAL_FIELD' && _get(
          item,
          'type_based_attributes.collection.specialField'
        ) === 'queue');
        const option = queueFieldList.map((item) => ({ value: item.id, label: item.label }));
        if (option.length) {
          setOptions((prevState) => ({
            ...prevState,
            queues: option,
          }));
        }
      } else {
        getQueueList().then((qList) => {
          const users = qList.response ?? [];
          setOptions((prevState) => ({
            ...prevState,
            queues: users,
          }));
        });
      }
    }
    if (componentState?.type?.value === 'users') {
      const users = availableUsersListInTenantLevel();
      setOptions((prevState) => ({
        ...prevState,
        users,
      }));
    }
  }, [selectedTemplate, leftOptionList]);

  useEffect(() => {
    const actors = _map(selectedTemplate?.actors || actorOption || [], (actor) => ({
      value: actor.id,
      label: _startCase(_camelCase(actor.name)),
    }));
    if (actors.length) {
      setOptions((prevState) => ({
        ...prevState,
        actors, // Update actors
      }));
    }
  }, [selectedTemplate, actorOption]);

  useEffect(() => {
    if (isEmpty(keyValuePairs) && isAddedOrganization) {
      getTenantRolesAndTeams().then((res) => {
        const org = res?.domain?.split('.');
        if (!_isEmpty(org)) {
          addKeyValue({
            key: 'org',
            value: org[0],
          });
        }
      });
    }
  }, [keyValuePairs]);

  useEffect(() => {
    if (showQueueMapField) {
      const queueFieldList = [...(selectedTemplate?.fields || [])].filter((item) => item.type === 'DROPDOWN' && _get(
        item,
        'type_based_attributes.collection.specialField'
      ) === 'queue' &&
       _get(item, 'type_based_attributes.collection.collection_type') === 'SPECIAL_FIELD'
      );
      const option = queueFieldList.map((item) => ({ value: item.id, label: item.label }));
      if (option.length) {
        setOptions((prevState) => ({
          ...prevState,
          queues: option,
        }));
      }
    } else {
      getQueueList().then((qList) => {
        const users = qList.response ?? [];
        setOptions((prevState) => ({
          ...prevState,
          queues: users,
        }));
      });
    }

    getGroup().then((groupListResp) => {
      const groupList = groupListResp?.response?.rows ?? [];
      setOptions((prevState) => ({
        ...prevState,
        groups: groupList.map(({ groupText }) => ({ label: groupText, value: groupText })),
      }));
    });
    const users = availableUsersListInTenantLevel();
    setOptions((prevState) => ({
      ...prevState,
      users,
    }));
    const actors = _map(selectedTemplate?.actors || actorOption || [], (actor) => ({
      value: actor.id,
      label: _startCase(_camelCase(actor.name)),
    }));
    if (actors.length) {
      setOptions((prevState) => ({
        ...prevState,
        actors, // Update actors
      }));
    }
    if (
      includeUserAttributes
        || showOnlyUserAttributes
        || selectOptions?.find((item) => item.value === userAttribute)
    ) {
      getUserAttributes().then((res) => {
        const option = selectOptions.filter((item) => item.value !== 'teams');
        const apiOption = res.response.rows
          .map((item) => {
            const localOption = item.valueLabels
              ?.map((data) => ({ value: data, label: _startCase(_camelCase(data)) }))
              || [];
            setOptions((prevState) => ({
              ...prevState,
              [item.key]: localOption
            }));

            return { value: item.key,
              label: _startCase(item.label),
              rightOptionType: item.validationRule.field };
          });
        setAttributeOption(apiOption);
        const userAttributeOption = option.find(({ value }) => value === userAttribute);

        if (!userAttributeOption) options.push({ value: userAttribute, label: 'User Attribute', icon: Group });

        if (showOnlyUserAttributes) {
          setLeftOptionList(userAttributeOption ? [userAttributeOption] : []);
          setAttributesLoaded(true);
          setComponentState((prevState) => ({
            ...prevState,
            typeAttribute: roleSettingSelectOpt.find(({ value }) => value === userAttribute),
            type: apiOption[0],
            value: '',
          }));
        } else {
          setLeftOptionList([...option]);
        }
      }).catch(() => {
        if (showOnlyUserAttributes) {
          setLeftOptionList([]);
          setComponentState({
            value: '',
            type: null,
          });
          setAttributesLoaded(true);
        } else {
          setLeftOptionList([...selectOptions]);
        }
      });
    } else {
      setLeftOptionList([...selectOptions]);
    }
  }, []);

  const getTypeForSelectedOption = useCallback((type) => {
    if (type?.rightOptionType === 'Predefined List') {
      return 'multi-select';
    }
    if (type?.rightOptionType === 'Free Text') {
      return 'input';
    }
    if (type?.value === 'org') {
      return 'input-disabled';
    }
    if (type?.value === 'groups') {
      return 'multi-select';
    }
    return 'multi-select';
  }, [componentState]);

  const getLabelForType = useCallback((key) => {
    if (key === 'org') {
      return 'Organization ';
    }
    return `${_startCase(key)} `;
  }, []);

  if (showOnlyUserAttributes && !attributesLoaded) {
    return null;
  }

  // eslint-disable-next-line arrow-body-style
  const getValueOptions = () => {
    const inputType = getTypeForSelectedOption(componentState?.type);

    if (inputType.includes('select')) {
      return options[componentState?.type?.value]
        ?.filter(
          ({ value }) => !keyValuePairs[componentState?.type?.value]
            ?.some((item) => value === item.value)
        );
    }

    return options[componentState?.type?.value];
  };

  const handleAudience = () => {
    const predefinedValues = ['org', 'users', 'groups', 'queues', 'teams']
    if (componentState.value) {
      addKeyValue({
        key: componentState?.type?.value,
        value: componentState.value,
      });
    }
  
    // Reset value only for non-'org' types
    if (componentState?.type?.value !== 'org') {
      setComponentState((prevState) => ({
        ...prevState,
        value: '',
      }));
    }

    if (componentState.value) {
      let newEntry = {};

      if (componentState?.type?.value === 'org') {
        newEntry = {
          leftOperand: componentState?.type.value,
          operator: operator.value,
          rightOperand: componentState.value,
        };
      } else if (componentState?.typeAttribute?.value === 'user attribute') {
        newEntry = {
          leftOperand: componentState?.type.value,
          operator: 'IN',
          rightOperand:
            componentState?.type?.rightOptionType === 'Predefined List'
              ? Array.isArray(componentState.value)
                ? componentState.value.map((item) => item.value)
                : componentState.value?.value
              : componentState.value,
        };
      } else {
        let right;
        if (Array.isArray(componentState.value)) {
          if (operator.value === 'IS') {
            if (!predefinedValues.includes(componentState?.type?.value)) {
              right = componentState.value.map((item) => item.value);
            } else {
              right = componentState.value[0]?.value;
            }
          } else {
            right = componentState.value.map((item) => item.value);
          }
        } else {
          right = componentState.value?.value;
        }
        newEntry = {
          leftOperand: componentState?.type.value,
          operator: operator.value,
          rightOperand: right,
        };
      }
      setAudience((prevAudience) => {
        const existingIndex = prevAudience.findIndex(
          (item) => item.leftOperand === newEntry.leftOperand
        );
  
        if (existingIndex !== -1) {
          const existingEntry = prevAudience[existingIndex];
          let updatedRightOperand;

          if (existingEntry.operator !== newEntry.operator) {
            updatedRightOperand = newEntry.rightOperand;
          } else if (
            newEntry.operator === 'IS' &&
            !predefinedValues.includes(newEntry.leftOperand)
          ) {

            updatedRightOperand = Array.isArray(existingEntry.rightOperand)
              ? [...existingEntry.rightOperand, ...newEntry.rightOperand].filter(
                  (value, index, self) => self.indexOf(value) === index
                ) // Ensure uniqueness
              : Array.isArray(newEntry.rightOperand)
              ? newEntry.rightOperand
              : [existingEntry.rightOperand, newEntry.rightOperand];
          } else if (newEntry.operator === 'IS') {
            // Replace entirely for predefined values
            updatedRightOperand = newEntry.rightOperand;
          } else {
            updatedRightOperand = Array.isArray(existingEntry.rightOperand)
              ? [...existingEntry.rightOperand]
              : existingEntry.rightOperand;

            let newRightOperand = newEntry.rightOperand;

            if (
              predefinedValues.find((item) => item !== componentState?.type.value)
            ) {
              if (!Array.isArray(existingEntry.rightOperand)) {
                newRightOperand = [
                  existingEntry.rightOperand,
                  newEntry.rightOperand,
                ];
              }
            }

            if (
              Array.isArray(existingEntry.rightOperand) &&
              Array.isArray(newRightOperand)
            ) {
              newRightOperand.forEach((newValue) => {
                if (
                  !updatedRightOperand.some(
                    (existingValue) => existingValue === newValue
                  )
                ) {
                  updatedRightOperand.push(newValue);
                }
              });
            } else {
              updatedRightOperand = newRightOperand;
            }
          }

          // Replace the entry with updated `operator` and `rightOperand`
          return prevAudience.map((item, index) =>
            index === existingIndex
              ? {
                  ...existingEntry,
                  operator: newEntry.operator,
                  rightOperand: updatedRightOperand,
                }
              : item
          );
        }
  
        // Add as a new entry if `leftOperand` doesn't exist
        return [...prevAudience, newEntry];
      });
    }
  
    // Reset value for non-'org' types
    if (componentState?.type?.value !== 'org') {
      setComponentState((prevState) => ({
        ...prevState,
        value: '',
      }));
    }
  };
  const renderRightOperand=(item)=>{
      if(Array.isArray(item?.rightOperand)) {
        if(item.leftOperand==='actors'){
            const actors = item.rightOperand.map((id) => {
            const actor = actorsList.find((actor) => actor.id === id);
            return actor && actor?.name ? actor.name : id;
          });
          item.rightOperand=actors?actors:item.rightOperand
        }
        return  item.rightOperand.map((value) => (
                    <Flex
                    key={value}
                    justifyContent="center"
                    alignItems="center"
                    bg={useColorModeValue(
                        OPERATOR_BG,
                        COLORS.WRAP_OPERATOR_BG_DARK
                    )}
                    borderRadius="4px"
                    fontWeight={500}
                    height="28px"
                    maxW="350px"
                    paddingLeft="1rem"
                    data-testid="DynamicSelectWithMutliInputTagsBtnFlex"
                    color={useColorModeValue(`${LIGHT}`, `${DARK}`)}
                    >
                    <Box data-testid="DynamicSelectWithMutliInputTagsBtnBox">
                        {value}
                    </Box>
                    <IconButton
                        background="ffffff"
                        _hover={{
                        background: "unset",
                        }}
                        color={COLORS.BLACK}
                        icon={<SmallCloseIcon />}
                        onClick={() => {
                        removeKeyValue({
                            key: item.leftOperand,
                            value,
                        });
                        }}
                        data-testid="DynamicSelectWithMutliInputTagsIconBtn"
                        isDisabled={isReadOnly}
                    />
                    </Flex>))
            
        }
      else{
        let updatedValue
        if(item.leftOperand==='actors'){
            const actor = actorsList.find((actor) => actor.id.toString() == item.rightOperand);
            updatedValue = actor && actor?.name ? actor?.name : item.rightOperand;
        }
          return    <Flex
                justifyContent="center"
                alignItems="center"
                bg={useColorModeValue(
                  OPERATOR_BG,
                  COLORS.WRAP_OPERATOR_BG_DARK
                )}
                borderRadius="4px"
                fontWeight={500}
                height="28px"
                maxW="350px"
                paddingLeft="1rem"
                data-testid="DynamicSelectWithMutliInputTagsBtnFlex"
                color={useColorModeValue(`${LIGHT}`, `${DARK}`)}
              >
                <Box data-testid="DynamicSelectWithMutliInputTagsBtnBox">
                  {updatedValue?updatedValue:item.rightOperand}
                </Box>
                <IconButton
                  background="ffffff"
                  _hover={{
                    background: "unset",
                  }}
                  color={COLORS.BLACK}
                  icon={<SmallCloseIcon />}
                  onClick={() => {
                    removeKeyValue({
                      key: item.leftOperand,
                      value: item.rightOperand,
                    });
                  }}
                  data-testid="DynamicSelectWithMutliInputTagsIconBtn"
                  isDisabled={isReadOnly}
                />
              </Flex>
            }
    }
    const getOperator = (componentState) => {
        if(componentState.type.value==='org'){
          return operatorOptions.slice(0,1)
        }
        else {return operatorOptions} 
    }

  return (
    <Box
      mb="2px"
      className={styles.dynamicSelectWithInput}
      data-testid="DynamicSelectWithMutliInputTagsBox"
    >
      <FormLabel
        className="SCLabel"
        marginBottom="10px"
        color={useColorModeValue(TEXT_LIGHT, TEXT_DARK)}
        data-testid="DynamicSelectWithMutliInputTagsFormLabel"
      >
        {label}
        {isMandatory ? (
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        ) : null}
      </FormLabel>
      <Wrap
        spacing="10px"
        align="center"
        data-testid="DynamicSelectWithMutliInputTagsWrap"
        className="dynamic__select__wrap__container"
      >
        <Flex className='audience-section' alignItems='center' >
          <CustomSelect
            options={leftOptionList.filter(item=>!['roles', 'actors', 'queues'].includes(item.value))}
            value={componentState?.typeAttribute || componentState?.type || leftOptionList[0]}
            onChange={(val) => {
              if (userAttribute === val?.value && attributeOptions?.length) {
                setComponentState((prevState) => ({
                  ...prevState,
                  typeAttribute: val,
                  type: attributeOptions[0],
                  value: '',
                }));
                return;
                }
            setComponentState((prevState) => {
                      const newState = { ...prevState };
                      delete newState.typeAttribute;
                      return {
                        ...newState,
                        type: val,
                        value: val?.value === 'org' ? options?.org : '',
                      };
                    });
            }}
            placeholder="select option"
            getOptionLabel={(e) => (
              <Flex align="center" className="text-[14px]">
                      {e.icon && <ReactSVG src={e.icon} pr="7px" width="15px" height="13px" />}
                      <span className="ml-1">{e.label}</span>
                    </Flex>
                  )}
            id="DynamicSelectWithMutliInputTagsSelect"
            isDisabled={isReadOnly}
          />
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            gap="10px" 
            wrap="nowrap"
            data-testid="DynamicSelectWithMutliInputTagsFlex"
            className="dynamic__select__flex__container"
            ms="10px"
          >
            {
              !componentState?.typeAttribute &&(
                <CustomSelect
                  options={getOperator(componentState)}
                  value={operator}
                  onChange={(val)=>{setOperator({label:val.value,value:val.value})}}
                />
              )
            }
            {componentState?.typeAttribute && (
              <>
                <Box
                  flex={2}
                  display={componentState?.type?.value === 'org' ? 'flex' : 'none'}
                  justifyContent="center"
                  alignItems="center"
                  mt="7px"
                  className="org__label__container"
                  minHeight="38px"
                >
                  <FormLabel className={styles.roleTitleText}>
                    {LABELS.TITLE.LOGGED_FROM}
                  </FormLabel>
                </Box>
                <CustomSelect
                  options={attributeOptions}
                  value={componentState?.typeAttribute ? componentState?.type : attributeOptions[0]}
                  onChange={(val) => {
                    setComponentState((prevState) => ({
                      ...prevState,
                      type: val,
                      value: val?.value === 'org' ? options?.org : '',
                    }));
                  }}
                  placeholder="select option"
                  getOptionLabel={(e) => (
                    <Flex align="center" className="text-[14px]">
                      {e.icon && <ReactSVG src={e.icon} pr="7px" width="15px" height="13px" />}
                      <span className="ml-1">{e.label}</span>
                    </Flex>
                  )}
                  id="DynamicSelectWithMutliInputTagsSelect"
                  isDisabled={isReadOnly}
                />
              </>
            )}
            <CustomField
              value={componentState?.value}
              type={getTypeForSelectedOption(componentState?.type,operator)}
              onChange={(value) => {
                setComponentState((prevState) => ({
                  ...prevState,
                  value,
                }));
              }}
              options={getValueOptions()}
              isOperatorSingle={(getTypeForSelectedOption(componentState?.type,operator)==='multi-select' && operator?.value==='IS')?true:false}
              className="customField"
            />
            <SecondaryCustomButton
              id="button-addSelectInputWithTag-role"
              buttonText={LABELS.ACTIONS.ADD}
              variant="outline"
              _hover={{ bg: 'blue.500', color: 'white' }}
              isDisabled={disableAddButton || isReadOnly || (componentState?.type?.value === 'org' && keyValuePairs?.org)}
              onClick={handleAudience}
              minHeight='38px'
            />
          </Flex>
        </Flex>
      </Wrap>
      {audience?.length > 0 && (
        <Wrap
            spacing="15px"
            mt="22px"
            data-testid="DynamicSelectWithMutliInputTagsWrap"
            border={useColorModeValue(`1px solid ${LIGHT}`, `1px solid ${DARK}`)}
            borderRadius="4px"
            alignItems="center"
        >
            {audience.map((item, index) => (
            <Wrap
                spacing={2}
                pl="10px"
                pt="3px"
                pb="3px"
                key={uuidv4()}
                data-testid="DynamicSelectWithMutliInputTagsInnerWrap"
                height="fit-content"
                fontSize={'14px'}
                display={"flex"}
                alignItems={"center"}
            >
                <Box data-testid="DynamicSelectWithMutliInputTagsBoxWrap" display="flex" alignItems="center" gap="10px">
                    <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    data-testid="DynamicSelectWithMutliInputTagsMapBox"
                    >
                    {getLabelForType(item.leftOperand)}
                    </Box>
                    <Box display="flex" alignItems="center" >{item.operator}</Box>
                    <Flex flexWrap='wrap' gap='5px'>{renderRightOperand(item)}</Flex>
                </Box>
                {index < audience.length - 1 && (
                <Box display="flex" alignItems="center" color={useColorModeValue(`${LIGHT}`, `${DARK}`)}>
                {['roles', 'groups', 'queues', 'org'].includes(item.leftOperand) ? "OR" : "AND"}
                </Box>
                )}
            </Wrap>
            ))}
        </Wrap>
        )}
    </Box>
  );
};

DynamicSelectWithInput.propTypes = {
  label: PropTypes.string.isRequired,
  selectOptions: PropTypes.array,
  mapping: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  templateId: PropTypes.number.isRequired,
  roleSettingTypeVsInputType: PropTypes.object,
  roleSettingTypeVsOptions: PropTypes.object,
  isMandatory: PropTypes.bool,
  disableAddButton: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isAddedOrganization: PropTypes.bool,
  includeUserAttributes: PropTypes.bool,
  showOnlyUserAttributes: PropTypes.bool,
  showQueueMapField: PropTypes.bool,
  actorOption: PropTypes.array,
  audience: PropTypes.string,
  setAudience: PropTypes.array,
};

DynamicSelectWithInput.defaultProps = {
  selectOptions: roleSettingSelectOpt,
  roleSettingTypeVsInputType: ROLE_SETTING_TYPE_VS_INPUT_TYPE,
  roleSettingTypeVsOptions: ROLE_SETTING_TYPE_VS_OPTIONS,
  mapping: roleMapping,
  isMandatory: false,
  disableAddButton: false,
  isReadOnly: false,
  isAddedOrganization: false,
  includeUserAttributes: false,
  showOnlyUserAttributes: false,
  showQueueMapField: true,
  actorOption: []
};

export default DynamicSelectWithInput;