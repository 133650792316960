/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useColorModeValue,
} from '@chakra-ui/react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './input.module.scss';
import { DARK, LIGHT, WHITE } from '../../utils/enums/colors';

const CustomInput = ({
  id,
  label = '',
  value,
  placeholder,
  type = 'text',
  onChange,
  size,
  leftIcon,
  isError = false,
  helperText,
  onKeyDown,
  errorMessage,
  isMandatory,
  rightElement,
  className,
  inputRightElementStyle,
  onClick,
  ...props
}) => (
  <FormControl
    className={classnames(styles.inputBox, className)}
    isInvalid={isError}
    isRequired={isMandatory}
    sx={{
      '.chakra-form__required-indicator': {
        color: '#CF3626',
      },
    }}
  >
    {label && <FormLabel className="SCLabel">{label}</FormLabel>}
    <InputGroup>
      {leftIcon && (
        <InputLeftElement pointerEvents="none" children={leftIcon} />
      )}
      <Input
        data-testid={id}
        placeholder={placeholder}
        _placeholder={{ opacity: 0.7, color: useColorModeValue('', WHITE) }}
        type={type}
        value={value}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onClick={onClick}
        size={size}
        isInvalid={isError}
        className="calculatedHeight"
        borderRadius="6px"
        focusBorderColor={useColorModeValue(LIGHT, DARK)}
        sx={{
          _focusVisible: {
            borderColor: '#D1D5DB',
            boxShadow: 'none',
          },
          _active: {
            borderColor: 'transparent',
            boxShadow: 'none',
          }
        }}
        {...props}
      />
      {rightElement ? (
        <InputRightElement style={inputRightElementStyle}>
          {rightElement}
        </InputRightElement>
      ) : null}
    </InputGroup>
    {!isError && helperText && <FormHelperText>{helperText}</FormHelperText>}
    {isError && errorMessage && (
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    )}
  </FormControl>
);
CustomInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string,
  leftIcon: PropTypes.node,
  isError: PropTypes.bool,
  helperText: PropTypes.string,
  errorMessage: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  isMandatory: PropTypes.bool,
  rightElement: PropTypes.node,
  className: PropTypes.string,
  inputRightElementStyle: PropTypes.object,
  onClick: PropTypes.func,
};

CustomInput.defaultProps = {
  id: '',
  label: '',
  value: undefined,
  onKeyDown: undefined,
  placeholder: '',
  type: 'text',
  size: 'md',
  leftIcon: '',
  isError: false,
  helperText: '',
  errorMessage: '',
  onBlur: () => {},
  onFocus: () => {},
  isMandatory: false,
  rightElement: null,
  className: '',
  inputRightElementStyle: undefined,
  onClick: () => {},
};

export default CustomInput;
