/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Mention, MentionsInput } from 'react-mentions';
import { useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import defaultStyle, { darkMentionDefaultStyle } from './mentionDefaultStyle';
import { getWholeOption } from '../../pages/TicketTemplateWorkflow/helper';
import axiosInstance from '../../utils/axios/axios';
import { convertFieldType } from '../../pages/MasterScreen/ConfigJson/helpers';
import { getMentionOptionLabel } from '../../pages/CommunicationTemplates/utills';
import { themeStyles } from './theme';
import { NOTES, createActorOptionsForCustomMention, createFieldOptionsForCustomMention, getAllActorOptionsForCustomMention, getAllFieldOptionsForCustomMention, getAllHardCodeMentionList } from './helpers';

const CustomMention = ({
  value,
  label,
  onChange,
  isError,
  errorMessage,
  linkedItemId,
  showQueueAndActorEmailOnly,
  onlyReferredMetric,
  isShowReferredMetric,
  isMulti,
  height,
  placeholder,
  showMentionWithQuotes,
  showHardCodMention,
  suggestionList,
  ...props
}) => {
  const [relatedItemActor, setRelatedItemActor] = useState([]);
  const [relatedItemMetric, setRelatedItemMetric] = useState([]);
  const [relatedItemSla, setRelatedItemSla] = useState([]);
  const [relatedItemFields, setRelatedItemFields] = useState([]);
  const { mentions, isCannedNotes } = props

  const fetchRelatedTemplate = async (templateId) => {
    const source = axios.CancelToken.source();
    try {
      const params = {
        $select: 'fields, slas, metric, actors',
        $filter: `id eq '${templateId}'`,
      };
      const response = await axiosInstance.get('templates/list', {
        params,
        cancelToken: source.token,
      });
      if (response?.data?.rows) {
        const template = [...response.data.rows][0];

        const FIELD_LIST = [...(template.fields || [])].map((item) => ({
          id: `parentTicket?.data['${item.id}'] || ''`,
          display: getMentionOptionLabel({
            name: item?.label,
            type: convertFieldType({ rowData: item }),
            isRelated: true,
          }),
        }));
        const METRIC_DATA = [...(template.metric || [])].map((item) => ({
          id: `parentMetric['${item.id}']`,
          display: getMentionOptionLabel({
            name: item.name,
            type: 'Metric',
            isRelated: true,
          }),
        }));

        const SLA_DATA = Object.values(template?.slas || {})
          .flatMap((data) => data)
          .map((item) => ({
            id: `parentSla['${item.id}']`,
            display: getMentionOptionLabel({
              name: item.name,
              type: 'Sla',
              isRelated: true,
            }),
          }));

        const ACTOR_DATA = [];

        [...(template.actors || [])].forEach((item) => {
          const actorFirstNameOption = {
            id: `parentActor['${item.associated_field_id}']?.userResponse?.first_name`,
            display: getMentionOptionLabel({
              name: item.name,
              type: 'Actor',
              subType: 'First Name',
              isRelated: true,
            }),
          };

          const actorLastNameOption = {
            id: `parentActor['${item.associated_field_id}']?.userResponse?.last_name`,
            display: getMentionOptionLabel({
              name: item.name,
              type: 'Actor',
              subType: 'Last Name',
              isRelated: true,
            }),
          };
          ACTOR_DATA.push(actorFirstNameOption);
          ACTOR_DATA.push(actorLastNameOption);
        });

        setRelatedItemActor([...ACTOR_DATA]);
        setRelatedItemFields([...FIELD_LIST]);
        setRelatedItemMetric([...METRIC_DATA]);
        setRelatedItemSla([...SLA_DATA]);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const FIELD_SLA_METRIC_DATA = useSelector((state) => createFieldOptionsForCustomMention(
    state?.viewState?.selectedTemplate,
    showQueueAndActorEmailOnly,
    isCannedNotes
  ));

  const ACTOR_LIST = useSelector((state) => createActorOptionsForCustomMention(
    state?.viewState?.selectedTemplate,
    showQueueAndActorEmailOnly
  ));

  useEffect(() => {
    if (linkedItemId && !showQueueAndActorEmailOnly) {
      fetchRelatedTemplate(linkedItemId);
    }
  }, [linkedItemId]);

  const FIELD_OPTIONS = useMemo(() => getAllFieldOptionsForCustomMention({
    showQueueAndActorEmailOnly,
    currentTemplateFieldSLAMetricOptions: FIELD_SLA_METRIC_DATA,
    includeLinkedItemFields: !!linkedItemId,
    relatedItemFields,
    relatedItemSla,
    relatedItemMetric,
    mentions,
    isCannedNotes
  }), [relatedItemMetric, relatedItemSla, relatedItemFields]);

  const ACTOR_OPTIONS = useMemo(() => getAllActorOptionsForCustomMention({
    showQueueAndActorEmailOnly,
    currentTemplateActorOptionsVal: ACTOR_LIST,
    relatedItemActorOptions: relatedItemActor,
  }), [relatedItemActor]);

  // other then actor and fields mention required
  const HARD_CODE_MENTION_LIST =  useMemo(() => getAllHardCodeMentionList(showHardCodMention), [])

  // const conditionalStyles = props.multiLine ? {
  //   border: '1px solid #d1d5db',
  //   maxHeight: '400px',
  //   overflowY: 'auto',
  //   borderRadius: '8px',
  // } : {
  //   border: 'none',
  //   maxHeight: 'none',
  //   overflowY: 'none',
  //   borderRadius: '0px',
  // };

  const CURRENT_TEMPLATE_ACTOR_TRANSITION_LIST = useSelector((state) => {
    const currentTemplateActorOptions = [];
      [...(state?.viewState?.selectedTemplate?.actors || [])].forEach((item) => {
        const actors = {
          id: `allowedTransitions['${item.associated_field_id}']`,
          display: getMentionOptionLabel({
            name: item.name,
            type: 'Actor',
            subType: 'AllowedTransitions',
          }),
        };

        currentTemplateActorOptions.push(actors);
      });
    return currentTemplateActorOptions;
  });

  return (
    <>
      {label && (
        <div style={label ? { marginBottom: '.5rem' } : {}}>
          <label className="SCLabel">{label}</label>
          {props?.isMandatory && (
            <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
          )}
        </div>
      )}
      {isShowReferredMetric ? (
        <MentionsInput
          value={value}
          singleLine={!isMulti}
          onChange={(e) => {
            const updatedValue = e.target.value;
            onChange(updatedValue);
          }}
          placeholder={placeholder}
          a11ySuggestionsListLabel="Suggested mentions"
          style={useColorModeValue(defaultStyle, darkMentionDefaultStyle)}
        >
          <Mention
            trigger="@"
            data={onlyReferredMetric || []}
            markup="@(__id__)"
            displayTransform={(id, display) =>
              getWholeOption(id, onlyReferredMetric, 'id')?.display || display
            }
          />
        </MentionsInput>

      ) : (
        // <Box style={conditionalStyles}>
        <MentionsInput
          value={value}
          onChange={(e) => {
            const updatedValue = e.target.value;
            onChange(updatedValue);
          }}
          placeholder={placeholder || 'Mention using \'@\''}
          a11ySuggestionsListLabel="Suggested mentions"
          // style={useColorModeValue(defaultStyle, darkMentionDefaultStyle)}
          singleLine={!isMulti}
          style={useColorModeValue(
            props?.multiLine ? themeStyles.light : defaultStyle,
            props?.multiLine ? themeStyles.dark : darkMentionDefaultStyle
          )}
          allowSpaceInQuery={true}
        >
          <Mention
            trigger="$"
            data={[...new Map((suggestionList || [...FIELD_OPTIONS, ...ACTOR_OPTIONS, ...HARD_CODE_MENTION_LIST, ...CURRENT_TEMPLATE_ACTOR_TRANSITION_LIST]).map(item => [item.id, item])).values()]} // Remove duplicates
            markup="@(__id__)"
            displayTransform={(id, display) =>
              getWholeOption(id,suggestionList || [...FIELD_OPTIONS, ...ACTOR_OPTIONS, ...HARD_CODE_MENTION_LIST, ...CURRENT_TEMPLATE_ACTOR_TRANSITION_LIST], 'id')
                ?.display || display
            }
          />
          <Mention
            trigger="@"
            data={ACTOR_OPTIONS || []}
            markup="@(__id__)"
            displayTransform={(id, display) =>{
              return getWholeOption(id, [ ...ACTOR_OPTIONS ], 'id')
                ?.display || display
              }
            }
          />
          {showMentionWithQuotes && (
            <Mention
              trigger='"$'
              data={FIELD_OPTIONS || []}
              markup='"@(__id__)"'
              displayTransform={(id, display) =>
                getWholeOption(id, [...FIELD_OPTIONS, ...ACTOR_OPTIONS,...HARD_CODE_MENTION_LIST], 'id')
                  ?.display || display
              }
            />
          )}
          {showMentionWithQuotes && (
            <Mention
              trigger='"@'
              data={ACTOR_OPTIONS || []}
              markup={`"@(__id__)"`}
              displayTransform={(id, display) =>
                getWholeOption(id, [...FIELD_OPTIONS, ...ACTOR_OPTIONS, ...HARD_CODE_MENTION_LIST], 'id')
                  ?.display || display
              }
            />
          )}
          {/* {showMentionWithQuotes && <></>} */}
        </MentionsInput>
        // </Box>
      )}

      {isError && errorMessage && (
        <span className="field-error">{errorMessage}</span>
      )}
    </>
  );
};
CustomMention.propTypes = {
  id: PropTypes.string,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  isMandatory: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  linkedItemId: PropTypes.string,
  showQueueAndActorEmailOnly: PropTypes.bool,
  onlyReferredMetric: PropTypes.array,
  isShowReferredMetric: PropTypes.bool,
  placeholder: PropTypes.string,
  showMentionWithQuotes: PropTypes.bool,
  height: PropTypes.number,
  multiLine: PropTypes.bool,
  showHardCodMention: PropTypes.array,
};

CustomMention.defaultProps = {
  isMulti: false,
  id: '',
  label: '',
  value: undefined,
  isMandatory: false,
  isError: false,
  errorMessage: '',
  linkedItemId: '',
  showQueueAndActorEmailOnly: false,
  onlyReferredMetric: [],
  showHardCodMention: [],
  isShowReferredMetric: false,
  placeholder: 'Mention using \'@\'',
  showMentionWithQuotes: false,
  height: 38,
  multiLine: false,
};

export default CustomMention;
