/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import TableViewWithOutSearch from '../Locales/TemplateLocalesTable';
import { specialFieldColumn } from './specialField.utils';
import SpecialFieldDrawer from './SpecialFieldDrawer';
import { specialFields } from '../../TicketTemplateBuilder/ConfigureTemplateView/utils/specialFieldsJson';
import { getTenantData } from '../../../helpers/tenantUrl';
import { updateTemplate } from '../customEventAndTriggers/customEventAndTriggers.helper';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import { getAITenantByBotconfig } from './specialField.helper';

const SpecialFields = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { selectedTemplate } = useSelector((state) => state.viewState);
  const [showSpecialFieldDrawer, setShowSpecialFieldDrawer] = useState(false);
  const [selectedSpecialField, setSelectedSpecialField] = useState({});
  const [isAITenant, setIsAITenant] = useState(false);

  const mergeArrays = (firstArray, secondArray) => {
    const firstDataCopy = [...firstArray];
    const metaFieldIdsSet = new Set(firstDataCopy.map((item) => item.type));
    secondArray.forEach((item) => {
      if (!metaFieldIdsSet.has(item.type)) {
        firstDataCopy.push(item);
      }
    });
    return firstDataCopy;
  };

  const openModal = useCallback(
    (data) => {
      setSelectedSpecialField(data?.rowData);
      setShowSpecialFieldDrawer(true);
    },
    [showSpecialFieldDrawer]
  );

  const SPECIAL_FIELD_TABLE_DATA = useMemo(() => {
    const specialFieldRole = selectedTemplate?.special_fields_role_map || [];
    const hardCodeSpecialFieldType = [
      ...specialFields,
      { id: uuidv4(), label: 'Approvers', type: 'SF_APPROVERS', width: 6 },
      { id: uuidv4(), label: 'Linked Tickets Custom', type: 'SF_LINKED_TICKETS_CUSTOM', width: 6 }
    ].map((item) => ({
      id: item.id,
      type: item.type,
      permissions: {
        editors: { org: [getTenantData()?.tenantId] },
        viewers: { org: [getTenantData()?.tenantId] },
      },
    }));
    const updatedData = mergeArrays(specialFieldRole, hardCodeSpecialFieldType);
    return updatedData;
  }, [selectedTemplate, isAITenant]);

  const updateState = (res) => {
    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
    }
  };

  const handleSaveRoleMap = async () => {
    const wholeRoleSettingList = [...SPECIAL_FIELD_TABLE_DATA];
    const findIndex = wholeRoleSettingList.findIndex(
      (item) => item.type === selectedSpecialField.type
    );
    if (findIndex !== -1) {
      wholeRoleSettingList[findIndex] = { ...selectedSpecialField };
    }

    if (wholeRoleSettingList.length) {
      wholeRoleSettingList.forEach((e, index) => {
        if (e.type === 'SF_SMARTSUMMARY' && e.permissions) {
          // Create a new object without the 'editors' property
          wholeRoleSettingList[index] = {
            ...e,
            permissions: {
              ...e.permissions,
            },
          };
          delete wholeRoleSettingList[index].permissions.editors;
        }
      });
    }

    const res = await updateTemplate({
      data: [...wholeRoleSettingList],
      id: selectedTemplate?.id,
      key: 'special_fields_role_map',
      defaultErrorMessage: 'Failed to save special field',
      successMessage: 'Successfully save special field',
    });
    if (res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: {
            ...selectedTemplate,
            ...(res?.response?.data || {}),
          },
        })
      );
      setIsLoading(false);
      return;
    }
    updateState(res);
    setIsLoading(false);
  };

  useEffect(() => {
    const getisAITenant = async () => {
      try {
        const response = await getAITenantByBotconfig();
        setIsAITenant(response);
      } catch (error) {
        console.error('Failed to fetch bot configs', error);
      }
    };

    getisAITenant();
  }, []);

  return (
    <>
      <TableViewWithOutSearch
        columns={specialFieldColumn({
          showModal: openModal,
        })}
        tableData={
          !isAITenant
            ? SPECIAL_FIELD_TABLE_DATA.filter(
                (item) => item.type !== 'SF_SMARTSUMMARY'
              )
            : SPECIAL_FIELD_TABLE_DATA || []
        }
        title="Special Fields"
      />
      {showSpecialFieldDrawer && (
        <SpecialFieldDrawer
          title="Edit Special Field"
          selectedTemplate={selectedTemplate}
          openDrawer={showSpecialFieldDrawer}
          setCloseDrawer={setShowSpecialFieldDrawer}
          saveSpecialField={handleSaveRoleMap}
          selectedSpecialField={selectedSpecialField}
          setSelectedSpecialField={setSelectedSpecialField}
        />
      )}
    </>
  );
};

export default SpecialFields;
