import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  IconButton,
  WrapItem,
  useColorModeValue,
} from '@chakra-ui/react';
import { CloseIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { COLORS } from '../../utils/enums/colors';

const CustomWrapItemWithRemoveAction = ({ wrapTitle, onRemoved }) => (
  <WrapItem>
    <Flex
      justifyContent="space-between"
      alignItems="center"
      bg={useColorModeValue( '#DEE8FC', COLORS.WRAP_BG_LIGHT)}
      p="4px 8px"
      borderRadius="6px"
      fontWeight={500}
      // minH="28px"
      maxW="522px"
      w="100%"
      // maxH="70px"
      color={COLORS.WRAP_COLOR_LIGHT}
      data-testid="RightAndLeftWithAddButtonWithTazBoxFlex"
    >
      <Box data-testid="RightAndLeftWithAddButtonWithTazQBox" noOfLines="1" fontSize="14px" color="#2563EB" fontWeight="400" lineHeight="normal">{wrapTitle}</Box>
      {/* <IconButton
        background="transparent"
        icon={<SmallCloseIcon color={COLORS.WRAP_COLOR_LIGHT} />}
        onClick={onRemoved}
        data-testid="RightAndLeftWithAddButtonWithTazIconBtn"
        w="20px"
        h="20px"
        pl="20px"
      /> */}

      <Box ml="10px"><CloseIcon width="10px" height="10px" color="#111827" onClick={onRemoved} /></Box>
    </Flex>
  </WrapItem>
);

CustomWrapItemWithRemoveAction.propTypes = {
  wrapTitle: PropTypes.string.isRequired,
  onRemoved: PropTypes.func.isRequired,
};

export default CustomWrapItemWithRemoveAction;
