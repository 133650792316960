import React, { useState, useEffect } from 'react';
import { Input, Box, InputGroup, InputRightElement, Button, Spinner, Text, Select, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, Portal } from '@chakra-ui/react';
import { IoSearch } from 'react-icons/io5';
import { AddIcon } from '@chakra-ui/icons';
import { BsInfoCircle } from 'react-icons/bs';
import axiosInstance,{axiosInstanceWithoutBaseUrl} from '../../../../utils/axios/axios';
import { showToastMessage } from '../../../TicketTemplateBuilder/utils/templateAPIUtils';
import CustomTable from '../../../DynamicRenderer/customTable';
import './botIdentificationFields.scss';

const FieldsTableViewWithSearch = ({ columns, tableData, getFields, descriptionData, selectedTemplate, isFetching, setDescriptionData, dropdownFields, deletedFields, setDeletedFields, setDropdownFields }) => {
  const [filter, setFilter] = useState(tableData);
  const [fieldValue, setFieldValue] = useState('');
  const [dropdownValue, setDropdownValue] = useState('');
  const [descriptionPopover, setDescriptionPopover] = useState(false);
  const [infoPopover, setInfoPopover] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [qnaRecords, setQnaRecords] = useState([]);
  const [recordsToBeUpdate,setRecordsToBeUpdate] = useState([]);
  const [offers,setOffers] = useState([])
  const [triggers, setTriggers] = useState([])

  const tenantId = JSON.parse(localStorage.getItem('tenant'))?.id;

  const FIELDS_TOAST = {
    SUCCESS: 'Fields Updated Successfully',
    ERROR: 'Fields Not Updated',
    PARENT_ERROR: 'Ancestor fields must be added'
  };
  

  const getOffers = async () => {
    try {
      const response = await axiosInstance.get(
        `/fieldDefaults/list?$select=*&$filter=item_type%20eq%20%27${selectedTemplate[0]?.id}%27%20and%20offer_type%20ne%20%27email%27`
      );
      if (Array.isArray(response?.data?.rows)) {
        setOffers(response.data.rows);
        return response.data.rows;
      }
      return [];
    } catch (err) {
      return [];
    }
  };
  const getTriggerRecords = async () => {
    try {
      const response = await axiosInstanceWithoutBaseUrl.get(
        `/botmgmt-service/BotIntegration/getTriggers?$skip=0&$top=1000`
      );
      if (Array.isArray(response?.data?.data)) {
        setTriggers(response?.data?.data)
      }
      return [];
    } catch (err) {
       return [];
    }
  };
  useEffect(()=>{
    getOffers()
    getTriggerRecords()
  },[selectedTemplate])

  useEffect(() => {
   const updatedTableData = tableData.map(item => {
      const matchingBI = triggers
          .flatMap(trigger => trigger.botInputs) 
          .flatMap(botInput => botInput?.BI)
          .find(biItem => biItem?.id === item?.id);
      if (matchingBI) {
          return {
              ...item,
              informationRetrievalPreference: matchingBI.informationRetrievalPreference,
              botDescription: matchingBI.botDescription
          };
      }
      return item;
    });
    setFilter(updatedTableData);
  }, [tableData]);
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setFieldValue(e.target.value);
    const filteredData = tableData.filter((item) =>
      item.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilter(filteredData.length > 0 ? filteredData : []);
  };
  const pineconeIngestion=async ()=>{
    const formatFieldType = (type) => {
      return type.toUpperCase().replace(/\s+/g, '_');
    };
    const requiredFields=selectedTemplate[0]?.fields.filter(item=>item?.type_based_attributes?.required) || selectedTemplate[0]?.fields.filter((item) => item?.requiredForBot);
    const updatedFields = requiredFields.map((field) => {
      const updatedDescription = descriptionData.find(desc => desc.label === field.label);
        return {
          ...field,
          botDescription: updatedDescription ? updatedDescription.botDescription : field.botDescription
        };
    });
    const fieldsData = updatedFields.filter((item) => {
      if (item?.permissions?.expression && Array.isArray(item?.permissions?.expression.and)) {
        const conditions = item?.permissions.expression.and;
        if (conditions.find((item) => item.or.length > 0)) {
          return null;
        }
        return item;
      }
      return item;
    })
      .map((element) => {
        let infoSource = element?.informationRetrievalPreference;
        if (infoSource === 'May Ask Leading Questions') {
          infoSource = 'UserInput';
        } else if (infoSource === 'Fetch from Fields') {
          infoSource = 'Inference';
        } else if (infoSource === 'Fetch From Conversation') {
          infoSource = 'SystemGeneration';
        }
        return {
        field_name: element.label,
        description: element.botDescription,
        mandatory: !!element?.type_based_attributes?.required,
        type: formatFieldType(element?.type),
        valid_options: Array.isArray(element?.type_based_attributes?.list) ? element?.type_based_attributes?.list.map((item) => item.queue_name) : (Array.isArray(element?.type_based_attributes?.collection?.staticValues) ? element?.type_based_attributes?.collection?.staticValues.map((item) => item.value) : []),
          example: 'example',
          info_source: infoSource,
        }
      });
     const createBulkPublishPayload = (records) => {
        return {
            ticket_forms: records.map(record => {
                const metadata = record.metadata;
                const flowName = metadata.find(meta => meta.name === "flow")?.value;
                const offersData = metadata.find(meta => meta.name === "offers");
                const selectedTriggerField=offers.find(item=>item.name===offersData.value[0]?.name)
                if (!selectedTriggerField) {
                    return null;
                }
                const triggerIntent = `${selectedTemplate[0]?.name}-${selectedTriggerField?.id}`;
                const formattedTriggerIntent = triggerIntent.toLowerCase().replace(/\s+/g, '');
                const offerFieldValues=offers.find(item=>item.name===selectedTriggerField?.name)?.field_attributes
                const requiredFields=selectedTemplate[0]?.fields.filter(item=>item?.type_based_attributes?.required)
                let offerValues
                if(Array.isArray(offerFieldValues) && offerFieldValues.length>0){
                  offerValues = offerFieldValues.reduce((result, item) => {
                    result[item.field.label] = item.value;
                    return result;
                    },
                    {offerId: selectedTriggerField?.id}
                );
                }
                else{
                  offerValues = requiredFields.reduce((result, item) => {
                    result[item.label] = item?.default_value || '';
                    return result;
                    },
                    {offerId: selectedTriggerField?.id}
                );
                }
                const audience= Array.isArray(selectedTriggerField?.audiences) && selectedTriggerField?.audiences.length > 0 ? selectedTriggerField?.audiences : ['all']
               if(selectedTriggerField){
                  return {
                      trigger_intent: formattedTriggerIntent || "",
                      title: selectedTemplate[0]?.name || "",
                      description: offersData.value[0]?.context || "",
                      trigger_meta: {
                          answer: formattedTriggerIntent || "",
                          trigger_name: `sc_${flowName}` || "",
                          max_questions: 1,
                          description: offersData.value[0]?.context || "",
                          type: "flow",
                          timestamp: new Date().toISOString().split(".")[0].replace("T", " "),
                          question: record.questions || [],
                          fields: fieldsData,
                          offer_defaults: offerValues,
                          audience:audience
                      },
                      tenantid: tenantId || ''
                  };
                }
                else null
            }).filter(Boolean)
        };
    };
    const bulkPublishPayload = createBulkPublishPayload(recordsToBeUpdate);
     const getTriggerResponse = async () => {
      try {
        const response = recordsToBeUpdate.find((item) => item.metadata.find((meta) => meta.name === 'offers')?.value[0].name === offers.find((ele)=>ele.name)?.name);
        return response;
      } catch (err) {
        console.log('error in Qna response', err);
      }
    };
    const triggerResponse = await getTriggerResponse();
    
    const updateQnaRecord = async () => {
      const teamValue=triggerResponse && triggerResponse?.metadata.find((item) => item.name == 'team')?.value
       const publish = {
          publish: true,
          ids: [triggerResponse?.id],
          type: 'flow',
          workspace: null,
          updatedBy: tenantId,
          serviceCatalog: true,
          pinecone: bulkPublishPayload,
          workspace: `{"team":"${teamValue}"}`
        }
      const payload={questions:triggerResponse?.questions,metadata:triggerResponse?.metadata,publishData:publish}
        try {
          const response = await axiosInstanceWithoutBaseUrl.put(
            `/botmgmt-service/qna/update/${triggerResponse?.id}`, payload);
          if (response) {
            return [];
          }
          return [];
        } catch (err) {
          return [];
        }
      };
      await updateQnaRecord();
  }

  const onSaveDescription = async () => {
    const fields = selectedTemplate[0]?.fields;
    const convertedFields = fields.map((item) => {
      const { requiredForBot, ...rest } = item;
      return {
        ...rest,
        type: item.type.replace(/ /g, '_').toUpperCase()
      };
    });
    const updatedData = descriptionData.map((item) => ({
      ...item,
      requiredForBot: true,
      botDescription: item?.botDescription
    }));
    const requiredForBotFields = convertedFields.map((item) => {
      if (updatedData.find((element) => element?.id === item?.id)) {
        return {
          ...item,
          requiredForBot: true
        };
      }
      return item;
    });
    const descriptionValues = requiredForBotFields.map((item) => {
      const descriptionValue = updatedData.find((element) => element?.id === item?.id);
      if (descriptionValue && descriptionValue !== undefined) {
        return {
          ...item,
          botDescription: descriptionValue?.botDescription,
          crossReferencedFields: descriptionValue?.crossReferencedFields,
          informationRetrievalPreference: descriptionValue?.informationRetrievalPreference || 'May Ask Leading Questions'
        };
      }
      return item;
    });
    const values = descriptionValues.map((item) => {
      if (deletedFields) {
        if (item.id === deletedFields.id) {
          return {
            ...item,
            requiredForBot: false
          };
        }

        return item;
      }

      return item;
    });
    const BI = values.filter(item=>item.requiredForBot).map((element)=>{
      return{
      ...element,
      id:element.id,
      type:element.type,
      required:element.type_based_attributes.required,
      botDescription:element.botDescription,
      informationRetrievalPreference:element.informationRetrievalPreference,
      } 
    })
    const payload={
      id:triggers.filter(element=>element.itemType===selectedTemplate[0]?.id).map(item=>item.id),
      botInputs:triggers.filter(element=>element.itemType===selectedTemplate[0]?.id).find(item=>item?.botInputs)?.botInputs.map(botInput=>{
        return{
          ...botInput,
          BI:BI,
          SI:botInput.SI
        }
      })
    }
    try {
          const response = await axiosInstanceWithoutBaseUrl.put(
            `/botmgmt-service/BotIntegration/updateTrigger`, payload);
          if (response) {
            setDeletedFields([]);
            getTriggerRecords();
            showToastMessage({
              title: 'Success',
              description: FIELDS_TOAST.SUCCESS,
              status: 'success',
            });
          }
        } catch (err) {
          setDeletedFields([]);
          showToastMessage({
            title: 'Error',
            description: FIELDS_TOAST.ERROR,
            status: 'error',
          });
          return [];
        }
  };
  const handleReset = () => {
    setFilter(tableData);
    setFieldValue('');
  };
  // const onAddDropdownField = () => {
    // const isValidDependent=dropdownFields.find((item) => item?.id.toString() === dropdownValue)
    // const selectedField = dropdownFields.find((item) => item?.id.toString() === dropdownValue);
    // const isExisting = descriptionData.filter((item) => item?.id === selectedField?.id).length > 0;
    // selectedField !== undefined && !isExisting && setDescriptionData([...descriptionData, selectedField]);
    // setDropdownValue('');
    // setIsDropdownVisible(false);
  // };
  const onAddDropdownField = () => {
    const isDependent=dropdownFields.some(item=>{
      if(item.id===dropdownValue && item.type_based_attributes?.collection?.collection_type==='DEPENDENT') return true
      else return false
    })
    if(isDependent===true){
      const selectedField = dropdownFields.find((item) => item?.id.toString() === dropdownValue);
      const hierarchyMapItems = selectedTemplate[0]?.field_hierarchy_map|| [];
  
      let selectedHierarchy = null;
      let selectedIndex = null;
  
      for (const hierarchy of hierarchyMapItems) {
          const hierarchyMap = hierarchy.map;
          const index = hierarchyMap.indexOf(dropdownValue);
          if (index !== -1) {
              selectedHierarchy = hierarchy; 
              selectedIndex = index; 
              break;
          }
      }
  
      if (selectedIndex >= 0 && selectedHierarchy) {
        const hierarchyMap = selectedHierarchy.map || [];
        const parentFieldId = hierarchyMap[selectedIndex ===0 ? 0 :(selectedIndex - 1)];
        const isParentPresentInDropdown = dropdownFields.find(item=>item.id===parentFieldId)
        const isParentPresent = descriptionData.some((item) => item.id === parentFieldId);
        if(isParentPresentInDropdown){
          if (!isParentPresent && parentFieldId!==hierarchyMap[0]) {
            showToastMessage({
              title: 'Error',
              description: FIELDS_TOAST.PARENT_ERROR,
              status: 'error',
            });
            return;
          }
          else{
            const isExisting = descriptionData.some((item) => item?.id === selectedField?.id);
            selectedField !== undefined && !isExisting && setDescriptionData([...descriptionData, selectedField]);
            setDropdownValue('');
            setIsDropdownVisible(false);
          }
        }
        else{
          const isExisting = descriptionData.some((item) => item?.id === selectedField?.id);
          selectedField !== undefined && !isExisting && setDescriptionData([...descriptionData, selectedField]);
          setDropdownValue('');
          setIsDropdownVisible(false);
        }
      }
    }
    else{
      const isValidDependent=dropdownFields.find((item) => item?.id.toString() === dropdownValue)
      const selectedField = dropdownFields.find((item) => item?.id.toString() === dropdownValue);
      const isExisting = descriptionData.filter((item) => item?.id === selectedField?.id).length > 0;
      selectedField !== undefined && !isExisting && setDescriptionData([...descriptionData, selectedField]);
      setDropdownValue('');
      setIsDropdownVisible(false);
    }
  };
  const descriptionHeaderRenderer = (text) => (
        <Popover placement="bottom-start" offset={[0, 10]} textTransform="none" isOpen={descriptionPopover} onOpen={() => setDescriptionPopover(true)} onClose={() => setDescriptionPopover(false)} closeOnBlur={false}>
          <PopoverTrigger>
            <Box display="flex" alignItems="center">
            <Text me="5px" color="#111827" fontSize="14px">{text}</Text>
            <BsInfoCircle color="#94A3B8" onMouseEnter={() => setDescriptionPopover(true)} onMouseLeave={() => setDescriptionPopover(false)} />
            </Box>
          </PopoverTrigger>
          <Portal>
            <PopoverContent zIndex="popover" position="absolute" onMouseEnter={() => setDescriptionPopover(true)} onMouseLeave={() => setDescriptionPopover(false)}>
              <PopoverHeader display="flex" gap="10px" p="10px" borderBottom="1px solid #E2E8F0" alignItems="center">
                <BsInfoCircle color="#E79B04" />
                <Text>Description For Bot:</Text>
              </PopoverHeader>
              <PopoverBody padding="10px" className="popover-body">
                <Text whiteSpace="normal" fontWeight="400" fontSize="14px" color="#111827">This field should contain a detailed explanation of the field's purpose and what kind of information it should contain.</Text>
                <Text whiteSpace="normal" fontWeight="400" fontSize="14px" mt="10px" color="#6B7280" className="popover-example">Eg. The email address of the customer, used for communication and account recovery.</Text>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
  );
  const infoHeaderRenderer = (text) => (
        <Popover placement="bottom-start" offset={[0, 10]} textTransform="none" isOpen={infoPopover} onOpen={() => setInfoPopover(true)} onClose={() => setInfoPopover(false)} closeOnBlur={false}>
          <PopoverTrigger>
            <Box display="flex" alignItems="center">
            <Text me="5px" color="#111827" fontSize="14px">{text}</Text>
            <BsInfoCircle color="#94A3B8" onMouseEnter={() => setInfoPopover(true)} onMouseLeave={() => setInfoPopover(false)} />
            </Box>
          </PopoverTrigger>
          <Portal>
            <PopoverContent zIndex="popover" position="absolute" onMouseEnter={() => setInfoPopover(true)} onMouseLeave={() => setInfoPopover(false)}>
              <PopoverHeader display="flex" gap="10px" p="10px" borderBottom="1px solid #E2E8F0" alignItems="center">
                <BsInfoCircle color="#E79B04" />
                <Text>Information Retrieval Preference</Text>
              </PopoverHeader>
              <PopoverBody padding="10px" className="popover-body">
                <Text whiteSpace="normal" fontWeight="400" fontSize="14px" color="#111827">Pick one of the following strategies to enable bot to fetch information for this field - 1) May ask leading questions - bot would ask this information explicitly 2) Fetch from conversation - bot won't ask a direct question, but would try to use conversation to fill this field 3) Fetch from Field - bot won't ask direct question and use the reference fields to fill this field.</Text>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
  );
  const columnsData = columns.map((item) => {
    if (item.label === 'Description for Bot') {
      return { ...item, label: descriptionHeaderRenderer(item?.label) };
    }
      if (item.label === 'Information Retrieval Preference') {
      return { ...item, label: infoHeaderRenderer(item?.label) };
    }
    return item;
  });
  return (
   <Box className="fields-section">
      <Box className="search-section" display="flex" gap="10px" justifyContent="end" my="2">
          <InputGroup w="500px">
            <Input placeholder="Search" value={fieldValue} onChange={handleChange} />
            <InputRightElement><IoSearch /></InputRightElement>
          </InputGroup>
          {tableData && <Button onClick={handleReset}>Reset</Button>}
          {tableData && <Button onClick={onSaveDescription}>Save</Button>}
      </Box>
      <Box mt="15px">
          <CustomTable
            height="calc(100vh - 18rem)"
            columns={columnsData}
            tableData={filter}
            onRowClick={() => {}}
            botIntegrationTable="true"
            isFetching={isFetching}
          />
      </Box>
      {
        dropdownFields && dropdownFields.length > 0 &&
        dropdownFields.filter((item) => !filter.some((element) => element?.id === item?.id)).length > 0
        && (
        <Box display="flex" gap="20px" justifyContent="flex-end" mt="10px" fontSize="14px">
            {isDropdownVisible &&<Select placeholder="Select field" color="#6B7280" width="370px" onChange={(e) => setDropdownValue(e.target.value)} maxWidth="370px">
              {dropdownFields.map((item) => {
                if (!filter.some((element) => element?.id === item?.id)) {
                  return (<option value={item?.id} key={item?.id}>{item?.label}</option>);
                }
              })}
            </Select>}
            {!dropdownValue ? 
            <Button variant="outline" onClick={()=>{setIsDropdownVisible(true);}} disabled={dropdownFields?.length==0} >
                <AddIcon color='#6B7280' fontSize='14px'/>
                <Text as='span' ms="10px" color='#6B7280' fontSize='14px' fontWeight="500">Add field</Text>
            </Button>
            :
            <Button backgroundColor="#2563EB" onClick={onAddDropdownField}>
                <Text as='span' color="#fff" >Add</Text>
            </Button>
            }
        </Box>
        )}
   </Box>
  );
};

export default FieldsTableViewWithSearch;
