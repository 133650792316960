import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { camelCase, cloneDeep, startCase } from 'lodash';

import FilterEditDrawer from './FilterEditDrawer';
import TableLayout from './TableLayout';
import RoleMapping from './RoleMapping';
import CustomTabs from '../../components/Tabs/Tabs';

import useKeycloak from '../../utils/hooks/useKeyCloak';

import {
  DELETE_FILTER_DEFAULT,
  FALLBACK_OPERATORS,
  FORM_MAP,
  columns,
  convertSelectedFilters,
  enrichSelectedFilters,
  getDefaultFilters,
  getTransformedMetaFields,
} from './filters.constants';
import {
  deleteFilter,
  getFilters,
  updateFilter,
  createFilter,
  getMetaFields,
  toggleDefault,
  getFilterRoleMapping,
  createFilterRoleMapping,
  updateFilterRoleMapping,
  deleteFilterRoleMapping,
  getTemplates,
  getOffers,
  bulkUpdateFilter
} from './filters.service';
import {
  associateBy,
  BOOLEAN_OPTIONS,
  createMap,
  generateOptions,
  getOperatorIdMapForAllOperators,
} from './CustomDynamicSelect/customDynamicSelect.helpers';

import styles from './filters.module.scss';

const Filters = () => {
  const [keycloak] = useKeycloak();

  const [metaConfig, setMetaConfig] = useState({
    metaFields: [],
    filterData: {},
  });
  const [filters, setFilters] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [filterRoleMapping, setFilterRoleMapping] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [offers, setOffers] = useState([]);
  const [openFilterRoleMappingDrawer, setOpenFilterRoleMappingDrawer] =
    useState(false);
  const [isFiltersTab, setIsFiltersTab] = useState(0);

  const currentEditData = useRef(null);

  const defaultFilters = useMemo(() => getDefaultFilters(), []);
  const defaultFiltersIdMap = useMemo(
    () =>
      associateBy({
        options: [...defaultFilters, DELETE_FILTER_DEFAULT],
        key: 'id',
      }),
    [defaultFilters]
  );
  const metaFieldsIdMap = useMemo(
    () =>
      associateBy({
        options: metaConfig.metaFields,
        key: 'meta_field_id',
      }),
    [metaConfig.metaFields]
  );

  useEffect(()=>{
    getTemplates().then(res => setTemplates(res));
    getOffers().then(res => setOffers(res));
  }, [])

  const fields = useMemo(() => {
    const metaFields = getTransformedMetaFields({
      metaData: metaConfig.metaFields,
      metaFilterData: metaConfig.filterData,
      templates,
      offers
    });

    return [...defaultFilters, DELETE_FILTER_DEFAULT, ...metaFields];
  }, [defaultFilters, metaConfig]);

  // To create a map for the operators
  const operatorIdMap = useMemo(() => {
    if (!Array.isArray(fields)) {
      return getOperatorIdMapForAllOperators();
    }

    const temp = [];
    fields.forEach((f) => {
      if (Array.isArray(f?.operators)) {
        temp.push(...f.operators);
      }
    }, []);

    return getOperatorIdMapForAllOperators({ operators: [...temp.flat(), ...FALLBACK_OPERATORS] });
  }, [fields]);

  // To create a map for the left options
  const leftOptionMap = useMemo(() => {
    const leftOptions = generateOptions({
      options: fields,
    });
    return createMap({
      options: leftOptions,
    });
  }, [fields]);

  // To create a map for the right options
  const rightOptionMap = useMemo(() => {
    const map = {};

    BOOLEAN_OPTIONS.forEach((b) => {
      map[b.value] = b.label;
    });

    if (Array.isArray(fields)) {
      fields.forEach((f) => {
        if (Array.isArray(f?.defaultOptions)) {
          f.defaultOptions.forEach((op) => {
            map[op.value] = op.label;
          });
        }

        const optionMap = f?.optionMap || {};
        if (typeof optionMap === 'object') {
          const opMap = Object.values(optionMap)?.flat();
          if (Array.isArray(opMap)) {
            opMap.forEach((op) => {
              if (op) {
                map[op.value] = op.label;
              }
            });
          }
        }
      });
    }
    return map;
  }, [fields]);

  const onEditClick = useCallback(
    (rowData = {}) => {
      // currentEditData.current = rowData;
      currentEditData.current = {
        id: rowData?.id,
        [FORM_MAP.NAME]: rowData?.[FORM_MAP.NAME] || '',
        [FORM_MAP.CONDITIONS]: enrichSelectedFilters({
          filters: rowData?.[FORM_MAP.CONDITIONS] || [],
          defaultFiltersIdMap,
          metaFieldsIdMap,
        }),
        [FORM_MAP.DESCRIPTION]: rowData?.[FORM_MAP.DESCRIPTION] || '',
      };
      setEditMode(true);
    },
    [defaultFiltersIdMap, metaFieldsIdMap]
  );

  const onCreateButtonClick = useCallback(() => {
    onEditClick();
  }, [onEditClick]);

  const onDeleteData = useCallback((rowData) => {
    const tempId = rowData?.id;
    deleteFilter(tempId).then((res) => {
      if (res) {
        setFilters((prev) => prev.filter((f) => f?.id !== tempId));
      }
    });
  }, []);

  const onEditOrSave = useCallback((filterData) => {
    const data = cloneDeep(filterData);
    const tempId = data?.id;
    delete data.id;

    const conditions = data?.[FORM_MAP.CONDITIONS];
    data[FORM_MAP.CONDITIONS] = convertSelectedFilters(conditions);
    data.name = data[FORM_MAP.NAME]?.replace(/\s+/g, ' ').trim();
    if (!data?.[FORM_MAP.DESCRIPTION]) {
      delete data[FORM_MAP.DESCRIPTION];
    }

    if (tempId) {
      updateFilter(tempId, data, {}).then((res) => {
        if (res) {
          setFilters((prev) =>
            prev.map((f) => {
              if (f?.id === tempId) {
                return res;
              }

              return f;
            })
          );

          setEditMode(false);
        }
      });
    } else {
      createFilter(data).then((res) => {
        if (res) {
          setFilters((prev) => [...prev, res]);
          setEditMode(false);
        }
      });
    }
  }, []);

  const onEditOrSaveForRoleMappings = useCallback((filterData) => {
    const data = cloneDeep(filterData);
    const tempId = data?.id;
    delete data.id;

    if (tempId) {
      updateFilterRoleMapping(tempId, data, {}).then((res) => {
        if (res) {
          setFilterRoleMapping((prev) =>
            prev.map((f) => {
              if (f?.id === tempId) {
                return res;
              }

              return f;
            })
          );

          setOpenFilterRoleMappingDrawer(false);
        }
      });
    } else {
      createFilterRoleMapping(data).then((res) => {
        if (res) {
          setFilterRoleMapping((prev) => [...prev, res]);
          setOpenFilterRoleMappingDrawer(false);
        }
      });
    }
  }, []);

  const onDeleteRoleMappings = useCallback((rowData) => {
    const tempId = rowData?.id;
    deleteFilterRoleMapping(tempId).then((res) => {
      if (res) {
        setFilterRoleMapping((prev) => prev.filter((f) => f?.id !== tempId));
      }
    });
  }, []);

  const onToggleDefault = useCallback(
    ({ id, isDefault }) => {
      const currentDefault = filters.find((f) => f?.[FORM_MAP.IS_DEFAULT]);
      let currentDefaultId;
      if (currentDefault) {
        currentDefaultId = currentDefault?.id;
      }

      toggleDefault({
        id,
        currentDefaultFilterId: currentDefaultId,
        isDefault,
      }).then((res) => {
        if (res) {
          setFilters((prev) =>
            prev.map((f) => {
              if (f?.id === id) {
                return { ...f, [FORM_MAP.IS_DEFAULT]: isDefault };
              }
              if (currentDefaultId && f?.id === currentDefaultId) {
                return { ...f, [FORM_MAP.IS_DEFAULT]: false };
              }
              return f;
            })
          );
          // Fetch the latest filters after toggling
          getFilters().then((latestFilters) => {
            setFilters(latestFilters);
          });
        }
      });
    },
    [filters]
  );

  const roles = useMemo(() => {
    const availableRoles = keycloak?.realmAccess?.roles;
    if (!Array.isArray(availableRoles)) {
      return [];
    }

    return availableRoles.map((item) => ({
      value: item,
      label: startCase(camelCase(item)),
    }));
  }, [keycloak]);

  useEffect(() => {
    getFilters().then((res) => {
      setFilters(res);
    });

    getFilterRoleMapping().then((res) => {
      setFilterRoleMapping(res);
    });

    getMetaFields().then((res) => {
      setMetaConfig(res);
    });
  }, []);

  const tabs = useMemo(() => {
    const temp = [
      {
        id: 'filters',
        title: 'Filters',
        tabType: 'FILTERS',
      },
      {
        id: 'roleMapping',
        title: 'Role Mapping',
        tabType: 'ROLE_MAPPING',
      },
    ];

    return temp;
  }, []);

  // sortable drag & drop configs start
  const [isFilterSortable, setIsFilterSortable] = useState(false);
  const [reOrderedList, setReOrderList] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const onFilterOrderSave = useCallback(() => {
    setIsloading(true);
    const payload = reOrderedList.map((item) => {
      const { position, properties, ...rest } = item;
      return {
        ...rest,
        properties: {
          ...properties,
          position,
        }
      };
    });
    bulkUpdateFilter(payload).then((res) => {
      if (res) {
        setIsloading(false);
      }
    });
  //  API Call required to put the updated index in respective filter table
  }, [reOrderedList]);
  const sortableConfig = {
    isSortable: isFilterSortable,
    showReorder: Array.isArray(filters) && filters?.length > 0,
    onToggleSortable: setIsFilterSortable,
    onOrderChange: setReOrderList,
    onOrderSave: onFilterOrderSave,
    btnTitle: { toggleOff: 'Reorder', toggleOn: 'Save Order', isLoading }
  };
    // sortable drag & drop configs end

  const colDefs = useMemo(() => {
    const cols = columns({
      onEdit: onEditClick,
      onDelete: onDeleteData,
      operatorIdMap,
      leftOptionMap,
      rightOptionMap,
      onToggleDefault,
    });

    return cols;
  }, [
    onEditClick,
    onDeleteData,
    operatorIdMap,
    leftOptionMap,
    rightOptionMap,
    onToggleDefault,
  ]);

  const filtersTab = (
    <div>
      <FilterEditDrawer
        data={currentEditData.current || {}}
        onSave={onEditOrSave}
        fields={fields}
        open={editMode}
        setOpen={setEditMode}
        key={editMode.toString()}
      />

      <TableLayout
        tableData={filters}
        title="Filters"
        addBtnText="Add Filter"
        sortableConfig={sortableConfig}
        columns={colDefs}
        onCreateButtonClick={onCreateButtonClick}
        tableHight='calc(100vh - 18rem)'
      />
    </div>
  );

  const roleMappingsTab = (
    <RoleMapping
      filters={filters}
      roles={roles}
      filterRoleMappings={filterRoleMapping}
      onSave={onEditOrSaveForRoleMappings}
      onDelete={onDeleteRoleMappings}
      isOpen={openFilterRoleMappingDrawer}
      setIsOpen={setOpenFilterRoleMappingDrawer}
    />
  );

  return (
    <div className="px-3">
      <CustomTabs
        tabsData={tabs.map((tab) => ({
          ...tab,
          content: tab.tabType === 'FILTERS' ? filtersTab : roleMappingsTab,
        }))}
        onChange={(tabIndex) => {
          setIsFiltersTab(tabIndex);
        }}
        variant="unstyled"
        specialTab
        // defaultTabIndex={isFiltersTab}
        index={isFiltersTab}
        tabRadius="100px"
        containerClassName={styles.filters__tab}
      />
    </div>
  );
};

export default Filters;
