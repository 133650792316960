export const LAYOUT_VIEW_LIST = [
  // {
  //   id: 'INBOX',
  //   label: 'Inbox',
  //   type: 'VIEW',
  //   linked_layout: null,
  // },
  // {
  //   id: 'IPAD_1',
  //   label: 'iPad 1',
  //   type: 'VIEW',
  //   linked_layout: null,
  // },
  {
    id: 'print',
    label: 'Print',
    type: 'VIEW',
    linked_layout: null,
  },
  {
    id: 'deferred',
    label: 'Deferred',
    type: 'VIEW',
    linked_layout: null,
  },
  {
    id: 'create',
    label: 'Create',
    type: 'VIEW',
    linked_layout: null,
  },
  {
    id: 'detail',
    label: 'Detail',
    type: 'VIEW',
    linked_layout: null,
  },
  // {
  //   id: 'search',
  //   label: 'Search',
  //   type: 'VIEW',
  //   linked_layout: null,
  // },
  // {
  //   id: 'result',
  //   label: 'Result',
  //   type: 'VIEW',
  //   linked_layout: null,
  // },
  {
    id: 'card',
    label: 'Inbox',
    type: 'VIEW',
    linked_layout: null,
  },
];

export const updateArray = (firstArray, secondArray) => {
  if (!Array.isArray(firstArray) || !Array.isArray(secondArray)) {
    throw new Error('Both inputs must be arrays');
  }
  return firstArray.map((item) => {
    const match = secondArray.find((secondItem) => secondItem.view_id === item.id);

    if (match) {
      return {
        ...item,
        linked_layout: match?.deferredLinkLayout?.[0]?.linked_layout || match.layout_id || null,
      };
    }
    return item;
  });
};
export const filterArrayByViewId = (firstArray, secondArray) =>
  firstArray.filter((item) =>
    secondArray.some((data) => data.id === item.view_id)
  );

export const findElementName = (jsonArray, searchId, keyValue) => {
  const foundElement = jsonArray.find((element) => element.id === searchId || element.name === searchId);
  return foundElement ? foundElement[keyValue] || '' : '';
};
