/* eslint-disable import/no-cycle */
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ArrowBackIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import { isEmpty } from 'lodash';
import ShowEditStatus from '../ShowEditStatus';
import { isEmtpty } from '../../TicketTemplateBuilder/utils/helper';
import ShowEditEdge from '../ShowEditEdge';
import StatusCategory from '../StatusCategory';
import { ACTION_LABELS, LABELS, TITLE_LABELS } from '../../../utils/enums/labels';

const WorkFlowSideBarContent = ({
  openMenu,
  setOpenMenu,
  manageCategory,
  openTransitionModalInEditStatus,
}) => {
  const { selectedWorkFlowStatus, selectedWorkFlowTransition } = useSelector(
    (state) => state.workFlowMain
  );
  const showTitleOfDrawer = () => {
    if (!isEmpty(selectedWorkFlowStatus)) {
      return LABELS.TITLE.STATUS;
    }
    if (!isEmpty(selectedWorkFlowTransition)) {
      return 'Transition';
    }
    if (
      manageCategory &&
      isEmpty(selectedWorkFlowStatus) &&
      isEmpty(selectedWorkFlowTransition)
    ) {
      return (
        <>
          {TITLE_LABELS.CATEGORY}
          {/* <QuestionOutlineIcon ml="5px" /> */}
        </>
      );
    }
    return null;
  };

  return (
    <Drawer
      size="md"
      placement="right"
      onClose={() => {
        if (
          isEmtpty(selectedWorkFlowStatus) ||
          isEmtpty(selectedWorkFlowTransition)
        ) {
          setOpenMenu(false);
        } else {
          setOpenMenu(!openMenu);
        }
      }}
      isOpen={openMenu}
      zIndex="1"
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => {
                if (
                  isEmtpty(selectedWorkFlowStatus) ||
                  isEmtpty(selectedWorkFlowTransition)
                ) {
                  setOpenMenu(false);
                } else {
                  setOpenMenu(!openMenu);
                }
              }}
            />
            <Text fontSize="16px">{showTitleOfDrawer()}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {openMenu && !isEmtpty(selectedWorkFlowStatus) && (
            <ShowEditStatus
              closeSideBar={setOpenMenu}
              openTransitionModalInEditStatus={openTransitionModalInEditStatus}
            />
          )}
          {openMenu && !isEmtpty(selectedWorkFlowTransition) && (
            <ShowEditEdge closeSideBar={setOpenMenu} />
          )}
          {openMenu &&
            manageCategory &&
            isEmtpty(selectedWorkFlowStatus) &&
            isEmtpty(selectedWorkFlowTransition) && <StatusCategory />}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
WorkFlowSideBarContent.propTypes = {
  openMenu: PropTypes.bool,
  setOpenMenu: PropTypes.func,
  manageCategory: PropTypes.bool.isRequired,
  openTransitionModalInEditStatus: PropTypes.func.isRequired,
};
WorkFlowSideBarContent.defaultProps = {
  openMenu: true,
  setOpenMenu: () => {},
};
export default WorkFlowSideBarContent;
