import React, { useState } from 'react';

import {
  Button,
  useColorModeValue,
} from '@chakra-ui/react';

import PropTypes, { func } from 'prop-types';
import { ReactSVG } from 'react-svg';

const CustomButton = ({
  id,
  buttonColor,
  variant,
  buttonText,
  onClick,
  size,
  borderColor,
  _hover,
  leftIcon,
  rightIcon,
  isDisabled=false,
  svg,
  svghover,
  classname,
  minHeight,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const borderValue = useColorModeValue('#e2e8f0', '#3F444E');
  return (
    <Button
      data-testid={id}
      size={size}
      colorScheme={buttonColor}
      variant={variant}
      onClick={onClick}
      isDisabled={isDisabled}
      fontSize="sm"
      fontWeight="400"
      className={classname}
      borderColor={borderColor || borderValue}
      _hover={_hover}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      minHeight={minHeight}
      {...props}
    >
      {svg && (
        <ReactSVG
          src={isHovered ? svghover : svg}
          style={{ marginRight: buttonText ? '10px' : '0px' }}
        />
      )}
      {leftIcon}
      {buttonText}
      {rightIcon}
    </Button>
  );
};

CustomButton.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string.isRequired,
  buttonColor: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string,
  borderColor: PropTypes.string,
  _hover: PropTypes.object,
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  svg: PropTypes.string,
  svghover: PropTypes.string,
  classname: PropTypes.string
};

CustomButton.defaultProps = {
  buttonText: 'Click',
  onClick: func,
  buttonColor: '#2563EB',
  id: '',
  size: 'sm',
  borderColor: '',
  _hover: {
    background: '#2563EB',
    color: 'white',
  },
  leftIcon: null,
  rightIcon: null,
  svg: null,
  svghover: null,
  classname: ''
};

export default CustomButton;
