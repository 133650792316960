/* eslint-disable react/prop-types */
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { COLORS } from '../../utils/enums/colors';

const TextBadge = ({ message }) => (
  <Flex
    alignItems="center"
    bg={useColorModeValue(COLORS.WRAP_BG_LIGHT, COLORS.WRAP_BG_LIGHT)}
    borderRadius="5px"
    fontWeight={500}
    padding="4px 8px"
    color={COLORS.WRAP_COLOR_LIGHT}
  >
    <Text wordBreak="break-word" fontSize="14px" color="#2165EB" fontWeight="400" lineHeight="normal">{message}</Text>
  </Flex>
);

export default TextBadge;
