import React from 'react';

import { InputGroup, InputRightElement } from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';

import PropTypes from 'prop-types';

import CustomInput from '../../../components/InputBox/Input';

const Input = ({ value, onChange }) => (
  <InputGroup width="250px" data-testid="InputGroup">
    <CustomInput
      placeholder="Search..."
      type="text"
      h="32px"
      style={{height:"32px"}}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      id="InputCIDivCInput"
    />
    {(
      <InputRightElement
        style={{
          // top: '50%',
          cursor: 'pointer',
          // transform: 'translateY(-46%)',
          display:"flex",
          alignItems:"center",
          justifyContent:"end",
          height:"100%",
          paddingRight:"10px",
          // right: standardHeight,
        }}
        // eslint-disable-next-line react/no-children-prop
        children={value ? <CloseIcon color="#94A3B8" /> : <SearchIcon color="#94A3B8" />}
        onClick={() => onChange('')}
      />
    )}
  </InputGroup>
);

Input.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  // standardHeight: PropTypes.string.isRequired,
};

Input.defaultProps = {
  value: '',
};

export default Input;
