import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormLabel, Wrap, useColorModeValue } from '@chakra-ui/react';
import _cloneDeep from 'lodash/cloneDeep';
import _remove from 'lodash/remove';
import _isEmpty from 'lodash/isEmpty';
import styles from '../CreateMailServer/mailServer.module.scss';
import CustomButton from '../../components/Button/SimpleButton';
import CustomWrapItemWithRemoveAction from '../CreateMailServer/CustomWrapItemWithRemoveAction';
import CustomSelectBox from '../../components/SelectBox/Select';
import { getWholeOption } from '../TicketTemplateWorkflow/helper';
import { COLORS } from '../../utils/enums/colors';

const SelectBoxWithAddButton = ({
  label,
  values,
  onChange,
  buttonLabel,
  inputWidth,
  option,
  isLabelShow,
  restrictLengthToBeOne,
}) => {
  const [expressionList, setExpressionsList] = useState(
    !_isEmpty(values) ? values : []
  );
  const [innerState, setInnerState] = useState('');
  const addExpression = () => {
    const newExpressions = _cloneDeep(expressionList);
    if (!newExpressions.some((obj) => obj === innerState)) {
      newExpressions.push(innerState);
      setExpressionsList(newExpressions);
      onChange(newExpressions);
    }
    setInnerState('');
  };
  const onAddExpressionInList = () => {
    if (innerState) {
      addExpression();
    }
  };
  const removeExpression = (expression) => {
    const newExpressions = _cloneDeep(expressionList);
    _remove(newExpressions, (ex) => ex === expression);
    setExpressionsList(newExpressions);
    onChange(newExpressions);
  };

  return (
    <Box>
      {isLabelShow && <FormLabel className="SCLabel">{label}</FormLabel>}
      <div className={styles.flexFormBox}>
        <Box minW={inputWidth}>
          {/* <CustomInput
            value={innerState || ''}
            onChange={(e) => {
              setInnerState(e.target.value);
            }}
            height="38px"
          /> */}
          <CustomSelectBox
            options={option || []}
            value={getWholeOption(innerState, option || [])}
            onChange={(e) => {
              setInnerState(e.value);
            }}
            isDisabled={restrictLengthToBeOne && expressionList.length >= 1}
          />
        </Box>
        <CustomButton
          variant="outline"
          classname="calculatedHeight"
          buttonText={buttonLabel}
          onClick={() => {
            onAddExpressionInList();
          }}
          color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
          isDisabled={restrictLengthToBeOne && expressionList.length >= 1}
        />
      </div>
      {!_isEmpty(expressionList) && (
        <Wrap className={styles.right__left_wrap}>
          {expressionList.map((exp) => (
            <CustomWrapItemWithRemoveAction
              wrapTitle={getWholeOption(exp, option).label || ''}
              onRemoved={() => {
                removeExpression(exp);
              }}
            />
          ))}
        </Wrap>
      )}
    </Box>
  );
};

SelectBoxWithAddButton.propTypes = {
  label: PropTypes.string,
  values: PropTypes.array,
  onChange: PropTypes.func,
  buttonLabel: PropTypes.string.isRequired,
  inputWidth: PropTypes.string,
  option: PropTypes.array.isRequired,
  isLabelShow: PropTypes.bool,
};
SelectBoxWithAddButton.defaultProps = {
  values: [],
  onChange: () => {},
  inputWidth: '86%',
  isLabelShow: false,
  label: '',
};
export default SelectBoxWithAddButton;
