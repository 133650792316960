// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qCbM6q9Je3h2d0obRmI2>ul{align-items:center}.Sw15InX1OqzY7ZpwfC2o{width:12.5rem !important;min-height:38px !important}`, "",{"version":3,"sources":["webpack://./src/components/dynamicExpressionWithTags/dynamicExpressionWithTags.module.scss"],"names":[],"mappings":"AAyBA,yBACI,kBAAA,CAEJ,sBACE,wBAAA,CACA,0BAAA","sourcesContent":["$breakpoint-xs: 320px;\n$breakpoint-sm: 576px;\n$breakpoint-md: 768px;\n$breakpoint-lg: 992px;\n$breakpoint-xl: 1200px;\n@mixin breakpoint($breakpoint) {\n    @if $breakpoint == xs {\n      @media (max-width: 319px) { @content; }\n    }\n    @else if $breakpoint == sm {\n      @media (min-width: 320px) and (max-width: 575px) { @content; }\n    }\n    @else if $breakpoint == md {\n      @media (min-width: 576px) and (max-width: 767px) { @content; }\n    }\n    @else if $breakpoint == lg {\n      @media (min-width: 768px) and (max-width: 991px) { @content; }\n    }\n    @else if $breakpoint == xl {\n      @media (min-width: 992px) and (max-width: 1199px) { @content; }\n    }\n    @else if $breakpoint == xxl {\n      @media (min-width: 1200px) { @content; }\n    }\n  }\n.expression__tag__container > ul {\n    align-items: center;\n  }\n.inputBox{\n  width: 12.5rem !important;\n  min-height: 38px !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expression__tag__container": `qCbM6q9Je3h2d0obRmI2`,
	"inputBox": `Sw15InX1OqzY7ZpwfC2o`
};
export default ___CSS_LOADER_EXPORT___;
