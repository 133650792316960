import React, { useEffect, useState } from 'react';

import {
  Box,
  Flex,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import CustomInput from '../../components/InputBox/Input';
import CustomTable from '../../components/Table/Table';
import CustomButton from '../../components/Button/SimpleButton';
import { COLORS } from '../../utils/enums/colors';
import NoRecords from '../../components/Table/NoRecords';
import { PrimaryCustomButton } from '../../components/Button/PrimarySecondaryButton';

const QuickActionTableViewWithSearch = ({
  placeholder,
  columns,
  tableData,
  onRowClick,
  searchKey,
  addButtonClick,
  showNoRecord,
}) => {
  const [filteredData, setFilteredData] = useState([...tableData]);
  const [searchInputValue, setSearchInputValue] = useState('');

  const filterData = debounce((e) => {
    const filteredFields = tableData.filter((field) =>
      field[searchKey].toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData([...filteredFields]);
  }, 500);

  useEffect(() => {
    setFilteredData([...tableData]);
  }, [tableData]);
  const handleClearInput = () => {
    setSearchInputValue('');
    setFilteredData([...tableData]);
  };
  return (
    <Box paddingY="16px" data-testid="LinkedMetaFieldTableViewContainerBox">
      {showNoRecord && isEmpty(tableData) ? (
        <Box height="calc(100vh - 15rem)" overflow="auto">
          <NoRecords
            title="You have not added quick action"
            subTitle="Click 'Create New' to get started"
            buttonText="Create New"
            onAdd={addButtonClick}
          />
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            data-testid="LinkedMetaFieldTableViewContainerBoxFlex"
          >
            <Text fontSize="16px" fontWeight="600" whiteSpace="nowrap">
              Quick Action
            </Text>
            <Flex
              gap="15px"
              flexWrap="wrap"
              data-testid="LinkedMetaFieldTableViewContainerFlex"
              width="100%"
              justifyContent="end"
              alignItems="center"
            >
              <Box width="25%">
                <InputGroup size="l">
                  <CustomInput
                    placeholder={placeholder}
                    type="text"
                    size="sm"
                    value={searchInputValue}
                    onChange={(e) => {
                      setSearchInputValue(e.target.value);
                      filterData(e);
                    }}
                  />
                  {searchInputValue ? (
                    <InputRightElement
                      style={{right:"15px", top:'12px', cursor: 'pointer' }}
                      // eslint-disable-next-line react/no-children-prop
                      children={<CloseIcon color="#94A3B8" />}
                      size="l"
                      onClick={handleClearInput}
                    />
                  ) : (
                    <InputRightElement
                      style={{ right:"15px",top:"12px", cursor: 'pointer' }}
                      // eslint-disable-next-line react/no-children-prop
                      children={<SearchIcon color="#94A3B8" />}
                      size="l"
                    />
                  )}
                </InputGroup>
              </Box>
              <PrimaryCustomButton
                buttonText="Add New Quick Action"
                // variant="solid"
                // style={{ height: '32px',
                //   backgroundColor: COLORS.LIGHT,
                //   color: COLORS.WHITE,
                //   borderColor: '#2563EB'
                // }}
                // color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                onClick={addButtonClick}
              />
            </Flex>
          </Box>

          <Box
            my="16px"
            data-testid="LinkedMetaFieldTableViewTableContainerBox"
          >
            <CustomTable
              height="calc(100vh - 21rem)"
              columns={columns}
              tableData={filteredData}
              onRowClick={onRowClick}
              title="Quick Action"
            />
          </Box>
        </>
      )}
    </Box>
  );
};

QuickActionTableViewWithSearch.propTypes = {
  placeholder: PropTypes.string,
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  searchKey: PropTypes.string,
  addButtonClick: PropTypes.func.isRequired,
  showNoRecord: PropTypes.bool,
};

QuickActionTableViewWithSearch.defaultProps = {
  placeholder: 'Search',
  onRowClick: undefined,
  searchKey: 'label',
  showNoRecord: false,
};

export default QuickActionTableViewWithSearch;
