import { Box, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';
import {
  calculateDepth,
  getDefaultFieldMapping,
  getDefaultFieldOption,
  getParentValueInHierarchy,
  makeApiCallForHierarchyList,
  transformHierarchyToArray,
} from './utils';
import CustomButton from '../../components/Button/SimpleButton';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import HierarchyMapper from './HierarchyMapper';
import styles from './mapHierarchy.module.scss';
import { COLORS } from '../../utils/enums/colors';
import Loader from '../DynamicRenderer/Dashboard/Loader';
import { patchDataForTemplateWithPath } from '../TicketTemplateBuilder/utils/templateAPIUtils';

const DEFAULT_FIELD_OPTION = getDefaultFieldOption();

const MapHierarchy = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();
  const { selectedTemplate, userRoles } = useSelector((state) => ({
    selectedTemplate: state?.viewState?.selectedTemplate ?? {},
    userRoles: state?.userInfoRole?.data?.data?.metaData?.roles ?? [],
  }));

  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [hierarchies, setHierarchies] = useState([]);
  const [transformedHiearchies, setTransformedHiearchies] = useState({});
  const [hierarchiesById, setHiearchiesById] = useState({});
  const [showLoader, setShowLoader] = useState({});
  const [isLoadingHierarchySet, setisLoadingHierarchySet] = useState(false);
  const [hierarchyMapState, setHierarchyMapState] = useState(
    selectedTemplate?.field_hierarchy_map || []
  );
  const hierarchyMapStateInDB = selectedTemplate?.field_hierarchy_map || [];

  useEffect(() => {
    setHierarchyMapState(selectedTemplate?.field_hierarchy_map || []);
  }, [selectedTemplate]);
  const dependentFields = useMemo(() => {
    if (!isEmpty(selectedTemplate?.fields)) {
      const filterField = selectedTemplate?.fields?.filter(
        (item) =>
          item.type === 'DROPDOWN' &&
          item.type_based_attributes.collection.collection_type === 'DEPENDENT'
      );
      return [...(filterField || [])].map((item) => ({
        value: item.id,
        label: item.label,
      }));
    }
    return [];
  }, [selectedTemplate]);

  const userHasAdminAccessMemo = useMemo(() => {
    const adminRoles = ['asc_admin', 'tenant_admin', 'ticket_admin'];
    return userRoles.some((role) => adminRoles.includes(role));
  }, [userRoles]);

  const fetchDataForHierarchyOption = async () => {
    setIsLoading(true);
    const result = await makeApiCallForHierarchyList();
    const option = Array.isArray(result)
      ? result.map((item) => ({
        value: item.id,
        label: item.name,
        depth: calculateDepth(item.hierarchy),
      }))
      : [];
    const transformedHierarchy = {};
    const hiearchiesByIdMap = {};
    result?.forEach((hierarchyObject) => {
      transformedHierarchy[hierarchyObject.id] = transformHierarchyToArray(
        hierarchyObject.hierarchy
      );
      hiearchiesByIdMap[hierarchyObject.id] = hierarchyObject;
    });
    setHierarchyOptions(option);
    setHierarchies(result || []);
    setTransformedHiearchies(transformedHierarchy);
    setHiearchiesById(hiearchiesByIdMap);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDataForHierarchyOption();
  }, []);

  const handleAddHierarchySet = () => {
    const newHierarchyMapState = cloneDeep(hierarchyMapState);
    newHierarchyMapState.push(getDefaultFieldMapping());
    setHierarchyMapState(newHierarchyMapState);
  };

  const handleDeleteHierarchySet = async (index) => {
    setisLoadingHierarchySet(true);
    setShowLoader({ ...showLoader, [index]: true });

    const hiearchySet = cloneDeep(hierarchyMapState[index]);

    const setExists = hierarchyMapStateInDB.some(
      (item) => item.id === hiearchySet.id
    );

    if (setExists) {
      const res = await patchDataForTemplateWithPath({
        id: selectedTemplate?.id,
        data: {
          path: 'field_hierarchy_map',
          operation: 'delete',
          id: hiearchySet.id,
        },
        defaultErrorMessage: 'Failed to delete hierarchy map',
        successMessage: 'Successfully deleted hierarchy map',
      });

      if (!res.error) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );

        setHierarchyMapState(res?.response?.data.field_hierarchy_map || []);
      }
    } else {
      setHierarchyMapState((prevState) => {
        const newState = cloneDeep(prevState);
        return newState.filter((mapping) => mapping.id !== prevState[index].id);
      });
    }

    setisLoadingHierarchySet(false);
    setShowLoader({ ...showLoader, [index]: false });
  };

  const validateFields = (map, fieldOptions, fields) => {
    const getFieldById = (id) => fields.find((field) => field.id === id);

    const isConditionsBlank = (conditions) => (
      !Array.isArray(conditions) ||
        conditions.length === 0 ||
        conditions.every(
          (condition) => condition.or && condition.or.length === 0
        )
    );

     const sanitizeConditions = (obj) => {
      if (Array.isArray(obj)) {
        return obj.map(sanitizeConditions);
      }
      if (typeof obj === 'object' && obj !== null) {
        const { id, ...rest } = obj;
        return Object.fromEntries(
          Object.entries(rest).map(([key, value]) => [key, sanitizeConditions(value)])
        );
      }
      return obj;
    };

  
        // Compare child and parent conditions
      const compareConditions = (childConditions, parentConditions) => {
        const sanitizedChild = sanitizeConditions(childConditions);
        const sanitizedParent = sanitizeConditions(parentConditions);
        if (isConditionsBlank(sanitizedChild) && isConditionsBlank(sanitizedParent)) {
          return true;
        }
        if (!sanitizedChild || !sanitizedParent) return false;
        return sanitizedChild.every((childCondition) =>
          sanitizedParent.some(
            (parentCondition) =>
              JSON.stringify(childCondition) === JSON.stringify(parentCondition)
          )
        );
      };
    
    const validateRecursively = (childId, parentIndex) => {
      if (parentIndex < 0) return true;
      const childField = getFieldById(childId);
      const parentField = getFieldById(map[parentIndex]);
      // eslint-disable-next-line max-len
      if (!childField || !parentField) return false;
  
      const childRequired = childField?.type_based_attributes?.required;
      const parentRequired = parentField?.type_based_attributes?.required;

      // Check for required mismatch
     if (childRequired !== parentRequired && parentRequired) {
      return { error: `Mismatch in required conditions for dependent fields : ${parentField.label} and ${childField.label}`, fieldId: childId };
    }
      // Validate enableExpression conditions
      if (
        (childField?.permissions?.enableExpression?.and &&
          !compareConditions(
            childField?.permissions?.enableExpression?.and || [],
            parentField?.permissions?.enableExpression?.and || []
          )
        ) ||
        (childField?.permissions?.expression?.and &&
          !compareConditions(
            childField.permissions.expression.and,
            parentField?.permissions?.expression?.and || []
          )
        )
      ) {
        return { error: `Mismatch in visibility conditions for dependent fields : ${parentField.label} and ${childField.label}`, fieldId: childId };
      }

      // Validate editor roles
      if (
        (childField?.permissions?.editors?.roles &&
          !compareConditions(
            childField.permissions.editors.roles,
            parentField?.permissions?.editors?.roles || []
          )
        ) ||
        (childField?.permissions?.viewers?.roles &&
          !compareConditions(
            childField.permissions.viewers.roles,
            parentField?.permissions?.viewers?.roles || []
          )
        )
      ) {
        return { error: `Mismatch in role conditions for dependent fields : ${parentField.label} and ${childField.label}`, fieldId: childId };
      }

      // Validate requiredExpression conditions
      if (
        !compareConditions(
          childField?.permissions?.requiredExpression?.and || [],
          parentField?.permissions?.requiredExpression?.and || []
        )
      ) {
        return { error: `Mismatch in required conditions for dependent fields : ${parentField.label} and ${childField.label}`, fieldId: childId };
      }
      return validateRecursively(childId, parentIndex - 1);
    };

    const errors = [];
    map.forEach((childId, index) => {
    const result = validateRecursively(childId, index - 1);
    if (result?.error) {
      errors.push(result);
    }
  });
  return {
    isValid: errors.length === 0,
    error: errors.length > 0 ? errors : null,
  };
};

  const handleSaveHierarchySet = async (index) => {
    setisLoadingHierarchySet(true);
    setShowLoader({ ...showLoader, [index]: true });

    const hiearchySet = cloneDeep(hierarchyMapState[index]);
    const operation = hierarchyMapStateInDB.some(
      (item) => item.id === hiearchySet.id
    )
      ? 'update'
      : 'create';

    const isValidResponse = validateFields(hiearchySet?.map || [], hiearchySet?.fieldOptions || [], selectedTemplate?.fields || []);
    if (isValidResponse?.isValid) {
      const res = await patchDataForTemplateWithPath({
        id: selectedTemplate?.id,
        data: {
          path: 'field_hierarchy_map',
          operation,
          id: operation === 'update' ? hiearchySet.id : undefined,
          data: hiearchySet,
        },
        defaultErrorMessage: `Failed to ${
          operation === 'create' ? 'save' : 'update'
        } hierarchy map`,
        successMessage: `Successfully ${
          operation === 'create' ? 'saved' : 'updated'
        } hierarchy map`,
      });

      if (!res.error) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
        setHierarchyMapState(res?.response?.data.field_hierarchy_map || []);
      } else if (res.error.includes('Same hierarchy cannot be mapped multiple times')) {
        setHierarchyMapState(hierarchyMapStateInDB || []);
      }
    } else {
      if (isValidResponse?.error) {
        isValidResponse.error.map(({ error: errorMessage }) => {
          toast({
            title: 'Access rights and restriction mismatching',
            description: `${errorMessage}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
        });
      }
    }
    setisLoadingHierarchySet(false);
    setShowLoader({ ...showLoader, [index]: false });
  };

  const handleSelectHierarchy = (index, value) => {
    setHierarchyMapState((prevState) => {
      const newState = cloneDeep(prevState);
      const prevFieldMap = newState[index]?.map || [];
      const prevFieldOptions = newState[index]?.fieldOptions;
      const newFieldOptions = {};
      const selectedHierarchy = hierarchyOptions.find(
        (item) => item.value === value
      );
      prevFieldMap.forEach((fieldId) => {
        newFieldOptions[fieldId] = {
          ...DEFAULT_FIELD_OPTION,
          showAllValues: prevFieldOptions?.[fieldId]?.showAllValues || false,
        };
      });

      let newMap = [...prevFieldMap];
      newState[index] = {
        heirachyListId: value,
        id: newState[index]?.id,
      };

      const maxLength = selectedHierarchy?.depth || 0;

      if (maxLength < prevFieldMap.length) {
        const idsToRemove = prevFieldMap.slice(maxLength);
        newMap = prevFieldMap.slice(0, maxLength);
        idsToRemove.forEach((id) => {
          delete newFieldOptions[id];
        });
      }
      newState[index].fieldOptions = newFieldOptions;
      newState[index].map = newMap;

      return newState;
    });
  };

  const handleAddRowInHierarchySet = (index) => {
    setHierarchyMapState((prevState) => {
      const newState = cloneDeep(prevState);
      newState[index].map.push('');
      return newState;
    });
  };

  const handleDeleteRowInHierarchySet = (index, rowIndex) => {
    setHierarchyMapState((prevState) => {
      const newState = cloneDeep(prevState);
      const fieldId = newState[index]?.map[rowIndex];
      newState[index].map = newState[index].map.filter(
        (_, i) => i !== rowIndex
      );
      delete newState[index].fieldOptions?.[fieldId];
      return newState;
    });
  };

  const handleFieldChangeInHierarchySet = (index, rowIndex, value) => {
    setHierarchyMapState((prevState) => {
      const newMapState = cloneDeep(prevState);
      const previousFieldId = newMapState[index].map[rowIndex];

      // Set the new value
      newMapState[index].map[rowIndex] = value;

      // Prepare the field options
      if (!newMapState[index].fieldOptions) {
        newMapState[index].fieldOptions = {
          ...DEFAULT_FIELD_OPTION,
        };
      }

      // Copy field options from previous ID if available
      newMapState[index].fieldOptions[value] = newMapState[index].fieldOptions[
        previousFieldId
      ] || { ...DEFAULT_FIELD_OPTION };

      // Remove field options for previous ID
      delete newMapState[index].fieldOptions[previousFieldId];

      return newMapState;
    });
  };

  const handleCheckboxChangeInHierarchySet = (index, rowIndex) => {
    setHierarchyMapState((prevState) => {
      const newState = cloneDeep(prevState);
      const fieldId = newState[index].map[rowIndex];

      if (!newState[index].fieldOptions) {
        newState[index].fieldOptions = {};
      }

      newState[index].fieldOptions[fieldId] = {
        showAllValues: !newState[index].fieldOptions[fieldId]?.showAllValues,
        selectedValue: '',
      };

      Object.keys(newState[index].fieldOptions).forEach((key) => {
        newState[index].fieldOptions[key].selectedValue = '';
      });

      return newState;
    });
  };

  const handleDefaultValueChangeInHierarchySet = (index, rowIndex, value) => {
    setHierarchyMapState((prevState) => {
      const newMapState = cloneDeep(prevState);
      const fieldId = newMapState[index].map[rowIndex];

      if (!newMapState[index].fieldOptions) {
        newMapState[index].fieldOptions = { ...DEFAULT_FIELD_OPTION };
      }

      newMapState[index].fieldOptions[fieldId] = {
        showAllValues:
          newMapState[index].fieldOptions[fieldId]?.showAllValues || false,
        selectedValue: value,
      };

      // Remove all default values for fields in lower levels
      if (rowIndex < newMapState[index].map.length - 1) {
        for (let i = rowIndex + 1; i < newMapState[index].map.length; i += 1) {
          const currentLevelFieldId = newMapState[index].map[i];
          if (
            newMapState[index].fieldOptions &&
            newMapState[index].fieldOptions[currentLevelFieldId]
          ) {
            newMapState[index].fieldOptions[currentLevelFieldId].selectedValue =
              null;
          }
        }
      }

      // Backfill the default values for upper levels
      if (rowIndex > 0) {
        for (let i = rowIndex - 1; i >= 0; i -= 1) {
          const currentLevelFieldId = newMapState[index].map[i];
          const lowerLevelFieldId = newMapState[index].map[i + 1];
          const lowerLevelDefaultValue =
            newMapState[index].fieldOptions[lowerLevelFieldId]?.selectedValue ||
            null;
          if (
            newMapState[index].fieldOptions &&
            newMapState[index].fieldOptions[currentLevelFieldId] &&
            lowerLevelDefaultValue
          ) {
            newMapState[index].fieldOptions[currentLevelFieldId].selectedValue =
              getParentValueInHierarchy(
                lowerLevelDefaultValue,
                hierarchiesById[newMapState[index].heirachyListId].hierarchy
              );
          }
        }
      }

      return newMapState;
    });
  };

  return (
    <Box height="calc(100vh - 15rem)" overflow="auto">
      <Box
        py="19px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        maxW="66%"
      >
        <Text fontSize="16px" fontWeight="600">
          Map Hierarchy
        </Text>
      </Box>
      {!isLoading ? (
        hierarchyMapState.map((item, index) => (
          <HierarchyMapper
            key={item.id}
            index={index}
            hierarchies={hierarchies}
            transformedHierarchies={transformedHiearchies}
            hierarchyOptions={hierarchyOptions}
            hierarchyMapState={hierarchyMapState}
            dependentFields={dependentFields}
            showLoader={!!showLoader[index]}
            isLoadingHierarchySet={isLoadingHierarchySet}
            isAdminUser={userHasAdminAccessMemo}
            onSaveHierarchy={handleSaveHierarchySet}
            onDeleteHierarchy={handleDeleteHierarchySet}
            onSelectHierarchy={handleSelectHierarchy}
            onAddRow={handleAddRowInHierarchySet}
            onDeleteRow={handleDeleteRowInHierarchySet}
            onFieldChange={handleFieldChangeInHierarchySet}
            onCheckboxChange={handleCheckboxChangeInHierarchySet}
            onDefaultValueChange={handleDefaultValueChangeInHierarchySet}
          />
        ))
      ) : (
        <Loader />
      )}
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <CustomButton
          buttonText="Map Another Hierarchy"
          bgColor={COLORS.LIGHT}
          color={COLORS.WHITE}
          isDisabled={
            isLoading ||
            isLoadingHierarchySet ||
            hierarchyMapState.length >= hierarchyOptions.length
          }
          onClick={handleAddHierarchySet}
          variant="outline"
          className={styles.hierachyMapperTitleActionButtonPrimary}
        />
      </Box>
    </Box>
  );
};

export default MapHierarchy;
