/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */
import React from 'react';
import { EditIcon } from '@chakra-ui/icons';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import TableColumnText from '../../components/Text/TableColumnText';

export const COLUMNS = ({ onEdit, DEPENDENT_FIELD_DATA }) => [
  {
    name: 'Dependent Field',
    col_name: 'dependent_field_name',
    type: 'string',
    cellRenderer: ({ rowData }) => (
      <TableColumnText text={rowData?.dependent_field_name} />
    ),
  },
  {
    name: 'Depends on',
    col_name: '',
    type: 'string',
    cellRenderer: ({ rowData }) => (
      <div>{`depends on >`}</div>
    ),
  },
  {
    name: 'Field',
    col_name: 'field_name',
    type: 'string',
    cellRenderer: ({ rowData }) => (
      <TableColumnText text={rowData?.field_name} />
    ),
  },
  {
    name: 'ACTIONS',
    label: 'actions',
    col_name: 'Actions',
    cellRenderer: ({ rowData }) => (
      <div className="flex gap-[10px] justify-center">
        <EditIcon
         color="#94A3B8"
          className="cursor-pointer"
          onClick={() =>
            onEdit(
              _find(
                DEPENDENT_FIELD_DATA,
                (data) => rowData.dependent_field_id === data.dependent_field_id
              )
            )
          }
        />
      </div>
    ),
  },
];

export const DEPENDENT_FIELD_KEY_MAP = {
  DEPENDENT_FIELD_ID: 'dependent_field_id',
  RELATION: 'relation',
  DEPENDENCIES: 'dependencies',
  ID: 'id',
  DYNAMIC: 'dynamic',
  VALUES: 'values',
};

export const mapDependentFieldData = (fieldsData, dependentData) => {
  const dependentFieldData = [];
  _forEach(dependentData, (value) => {
    const dependentFieldValue = _find(
      fieldsData,
      (field) => value.dependent_field_id === field.id
    );
    const fieldValue = _find(
      fieldsData,
      (field) => value?.dependencies?.id === field.id
    );
    const tempData = {};
    if (!_isEmpty(dependentFieldValue) && !_isEmpty(fieldValue)) {
      tempData.dependent_field_name = dependentFieldValue.label;
      tempData.field_name = fieldValue.label;
      tempData.dependent_field_id = dependentFieldValue.id;
      tempData.field_id = fieldValue.id;
    }
    dependentFieldData.push(tempData);
  });
  return dependentFieldData;
};

export const DRAWER_TITLE = 'Add New Dependent Field';
export const DRAWER_TITLE_EDIT = 'Edit Dependent Field';


export const INITIAL_DEPENDENT_FIELD_DATA = {
  [DEPENDENT_FIELD_KEY_MAP.DEPENDENT_FIELD_ID]: '',
  [DEPENDENT_FIELD_KEY_MAP.RELATION]: 'OR',
  [DEPENDENT_FIELD_KEY_MAP.DEPENDENCIES]: {
    id: '',
    dynamic: false,
    values: {},
  },
};
