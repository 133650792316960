import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Box,
  Flex,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';

import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import CustomInput from '../../components/InputBox/Input';
import CustomTable from '../../components/Table/Table';
import CustomButton from '../../components/Button/SimpleButton';

import { COLORS } from '../../utils/enums/colors';

const TableLayout = ({
  tableData,
  title,
  addBtnText,
  columns,
  tableHight,
  onCreateButtonClick,
  customSearchFn,
  sortableConfig
}) => {
  const [filteredData, setFilteredData] = useState([...tableData]);
  const [searchInputValue, setSearchInputValue] = useState('');

  const currentFilterString = useRef('');

  // To filter the data based on search input
  const filterData = useCallback(
    debounce((e) => {
      let filteredFields;

      if (typeof customSearchFn === 'function') {
        filteredFields = customSearchFn(tableData, e);
      } else {
        filteredFields = tableData.filter((field) =>
          field?.name?.toLowerCase()?.includes(e.toLowerCase())
        );
      }
      setFilteredData([...filteredFields]);
    }, 500),
    [tableData, customSearchFn]
  );

// To clear the search input
const handleClearInput =  useCallback(()=> {
  setSearchInputValue('');
  setFilteredData([...tableData]);
  currentFilterString.current=""
},[tableData])

  const handleChangeInput = useCallback(
    (e) => {
      setSearchInputValue(e.target.value);
      currentFilterString.current = e.target.value;
      filterData(e.target.value);
    },
    [filterData]
  );

  useEffect(() => {
    filterData(currentFilterString.current);
  }, [filterData]);

  return (
    <Box px="12px" pt="10px" paddingInlineEnd="1px" data-testid="FiltersTableViewContainerBox">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        data-testid="FiltersTableViewContainerBoxFlex"
      >
        <Text fontSize="16px" fontWeight="600" lineHeight="normal" pt="20px" whiteSpace="nowrap">
          {title}
        </Text>

        <Flex
          gap="15px"
          flexWrap="wrap"
          data-testid="FiltersTableViewContainerFlex"
          width="100%"
          justifyContent="end"
        >
          <Box flex=".3" data-testid="FiltersFieldTableViewInnerBox">
            <InputGroup size="l">
              <CustomInput
                placeholder="Search"
                type="text"
                size="sm"
                value={searchInputValue}
                onChange={handleChangeInput}
              />
              {searchInputValue ? (
                <InputRightElement
                  style={{ right: 12, cursor: 'pointer' }}
                  // eslint-disable-next-line react/no-children-prop
                  children={<CloseIcon color="gray.300" />}
                  size="l"
                  onClick={handleClearInput}
                  className="calculatedHeight"
                />
              ) : (
                <InputRightElement
                  style={{ right: 12, cursor: 'pointer' }}
                  // eslint-disable-next-line react/no-children-prop
                  children={<SearchIcon color="gray.300" />}
                  size="l"
                  className="calculatedHeight"
                />
              )}
            </InputGroup>
          </Box>

          {sortableConfig?.showReorder && (
          <CustomButton
            classname="calculatedHeight"
            buttonText={
              sortableConfig?.isSortable ?
                sortableConfig?.btnTitle?.toggleOn :
                sortableConfig?.btnTitle?.toggleOff
            }
            isLoading={sortableConfig?.btnTitle?.isLoading}
            isDisabled={sortableConfig?.btnTitle?.isLoading}
            onClick={() => {
              if (sortableConfig?.isSortable) {
                sortableConfig?.onOrderSave();
                setTimeout(() => {
                  sortableConfig?.onToggleSortable(!sortableConfig?.isSortable);
                }, 1000);
              } else sortableConfig?.onToggleSortable(!sortableConfig?.isSortable);
            }}
            variant="solid"
            style={{ backgroundColor: COLORS.LIGHT,
              color: COLORS.WHITE,
              borderColor: '#2563EB' }}
            color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
          />
          )}
          <CustomButton
            classname="calculatedHeight"
            buttonText={addBtnText}
            variant="solid"
            style={{ backgroundColor: COLORS.LIGHT,
              // height: 32,
              color: COLORS.WHITE,
              borderColor: '#2563EB' }}
            color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
            onClick={onCreateButtonClick}
          />
        </Flex>
      </Box>

      <Box my="5px" data-testid="FiltersTableViewTableContainerBox">
        <CustomTable
          columns={columns}
          height={tableHight}
          tableData={filteredData}
          title={title}
          sortableConfig={sortableConfig}
        />
      </Box>
    </Box>
  );
};

TableLayout.propTypes = {
  tableData: PropTypes.array.isRequired,
  onCreateButtonClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  tableHight: PropTypes.string,
  addBtnText: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  customSearchFn: PropTypes.func,
  sortableConfig:
    PropTypes.shape({
      isSortable: PropTypes.bool.isRequired,
      showReorder: PropTypes.bool.isRequired,
      onToggleSortable: PropTypes.func.isRequired,
      onOrderChange: PropTypes.func.isRequired,
      onOrderSave: PropTypes.func.isRequired,
      btnTitle:
        PropTypes.shape({
          toggleOff: PropTypes.string.isRequired,
          toggleOn: PropTypes.string.isRequired,
          isLoading: PropTypes.bool.isRequired
        }).isRequired
    })
};

TableLayout.defaultProps = {
  customSearchFn: undefined,
  sortableConfig:
    {
      isSortable: false,
      showReorder: false,
      onToggleSortable: () => {},
      onOrderChange: () => {},
      onOrderSave: () => {},
      btnTitle:
        {
          toggleOff: 'Reorder',
          toggleOn: 'Save Order',
          isLoading: false
        },
    },
};

export default TableLayout;
