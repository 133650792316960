export default {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
    height: 38,
    'border-radius': '5px',
    border: '1px solid #D1D5DB',
    borderRadius: "5px"
  },

  '&multiLine': {
    control: {
      fontFamily: 'Inter',
    },
    highlighter: {
      padding: 9,
      border: '1px solid ',
      maxHeight: 100
    },
    input: {
      padding: 9,
      border: '1px solid #D1D5DB',
      'border-radius': '5px',
      maxHeight: 100,
      overflow: 'auto',
      ':focus': {
        border: '1px solid #D1D5DB',
      }
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 9,
      maxHeight: 38,
      border: '1px solid #D1D5DB',
    },
  },

  suggestions: {
    top:'26px',
    right:'inherit',
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      height: 130,
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: 'grey white',
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#deebff',
      },
    },
  },
};
export const darkMentionDefaultStyle = {
  control: {
    backgroundColor: '#4F5765',
    fontSize: 14,
    fontWeight: 'normal',
    height: 38,
  },

  '&multiLine': {
    control: {
      fontFamily: 'Inter',
      height: 100,
    },
    highlighter: {
      padding: 9,
      maxHeight: 100,
      border: '1px solid',
      '&focused': {
        border: '1px solid #3F444E',
      }
    },
    input: {
      padding: 9,
      border: '1px solid #3F444E',
      maxHeight: 100,
      overflow: 'auto',
      '&focused': {
        border: '1px solid #3F444E',
      }
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: '#4F5765',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      height: 130,
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: 'grey white',
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#394353',
      },
    },
  },
};
