import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { cloneDeep, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import EditFieldDefault from './EditFieldDefault';

import {
  FORM_MAP,
  OFFER_TYPES,
  columns,
  enrichDataForEdit,
} from './fieldDefaults.utils';
import {
  createFieldDefault,
  deleteFieldDefault,
  updateFieldDefault,
  bulkUpdateOffer
} from './fieldDefaults.service';
import {
  getDataForIndeterminateCheckbox,
  getIntent,
} from '../CreateEditIntentBuilder/intent.service';
import { getAudiences } from '../MasterAudience/audience.services';
import {
  createMap,
} from '../TenantFilters/CustomDynamicSelect/customDynamicSelect.helpers';
import {
  getAudience,
  getIntentGroups,
  keys,
  setData,
} from '../../redux/FieldDefaults';
import { deleteAttachment } from '../../components/AttachmentUpload/utils';
import axiosInstance from '../../utils/axios/axios';
import TableViewWithSearch from '../../components/Table/TableWithSearch';
import { deleteTriggerConfirmation, deleteTriggersForOffers } from '../TemplateCardListView/helpers';
import WarningTriggerModel from '../DynamicRenderer/WarningTriggerModel';
import { useDisclosure } from '@chakra-ui/react';

const MasterFieldDefaults = () => {
  const dispatch = useDispatch();

  const [fieldDefaults, setFieldDefaults] = useState([]);
  const [isOfferListLoading, setIsOfferListLoading] = useState(false);
  const audiences = useSelector(getAudience);
  const intentGroups = useSelector(getIntentGroups);
  const { selectedTemplate } = useSelector((state) => state.viewState || {});

  const [loading, setLoading] = useState(false);
  const [fieldDefaultEditDrawer, setFieldDefaultEditDrawer] = useState(false);

  const selectedFieldDefault = useRef({});
  const isuseEffectTriggered = useRef(false);
  const [triggers, setTriggers] = useState([])
  const getTriggerRecords = async () => {
    try {
      const response = await axiosInstanceWithoutBaseUrl.get(
        `/botmgmt-service/BotIntegration/getTriggers?$skip=0&$top=1000`
      );
      if (Array.isArray(response?.data?.data)) {
        setTriggers(response?.data?.data)
      }
      return [];
    } catch (err) {
      return [];
    }
  };
  useEffect(()=>{
    getTriggerRecords()
  },[])

  const loadAndSetFieldDefaults = () => {
    setIsOfferListLoading(true);
    axiosInstance
      .get(`fieldDefaults/list?$select=*&$filter=item_type eq '${selectedTemplate.id}' and offer_type ne 'email'&$orderby=id asc`)
      .then((res) => {
        const fieldDefaultRows = res?.data?.rows || [];
        const defaultOffers = fieldDefaultRows.filter(
          (item) => item.offer_type === OFFER_TYPES.TEMPLATE
        );
        const otherOffers = fieldDefaultRows.filter(
          (item) => item.offer_type !== OFFER_TYPES.TEMPLATE
        );

        setFieldDefaults([...defaultOffers, ...otherOffers]);
        setIsOfferListLoading(false);
        setIsOfferListLoading(false);
      })
      .catch(() => {
        setFieldDefaults([]);
        setIsOfferListLoading(false);
        setIsOfferListLoading(false);
      });
  };

  useEffect(() => {
    if (!selectedTemplate) {
      return;
    }

    loadAndSetFieldDefaults();
  }, [selectedTemplate]);

  const onEditClick = useCallback((data = {}) => {
    selectedFieldDefault.current = enrichDataForEdit(
      data, selectedTemplate.id, selectedTemplate.name
    );
    setFieldDefaultEditDrawer(true);
  }, [selectedTemplate]);

  const onCreateClick = useCallback(() => {
    onEditClick({
      [FORM_MAP.OFFER_TYPE]: OFFER_TYPES.OFFER,
    });
  }, [onEditClick]);

  const onDelete = useCallback((data) => {
    deleteFieldDefault(data?.id).then((res) => {
      if (res) {
        // dispatch(deleteData({ key: keys.FIELD_DEFAULTS, id: data?.id }));
        loadAndSetFieldDefaults();
        if (data?.[FORM_MAP.THUMBNAIL]) {
          deleteAttachment(data?.[FORM_MAP.THUMBNAIL]);
        }
      }
    });
  }, []);
  const deleteTrigger=async (data,triggers)=>{
    let triggerResponse
    triggerResponse = triggers.find((item)=>item.triggerName==data.name && item.itemType==data.item_type)
    if (triggerResponse!==undefined) {
      const triggerIntent = `${triggerResponse?.associateFlow}-${data?.id}`;
      const deletePayload={
        id:triggerResponse?.id,
        triggerIntent:triggerIntent,
      }
      if(triggerResponse?.status==='Published'){
        deletePayload.isPublish=true
      }
      try {
        await axiosInstanceWithoutBaseUrl.put(`botmgmt-service/BotIntegration/deleteTrigger`,deletePayload)
        return [];
      }
      catch(err){
       return []
      }
    } 
    else {
      return []
    }
  }


  const [triggersLoader,setTriggersLoader]=useState(false) 
  const [deleteModelState,setDeleteModelState]=useState({model:false,value:null,isDeleted:false})
  const { isOpen,onOpen,onClose } = useDisclosure()

  useEffect(() => {
    if (deleteModelState.value && deleteModelState.isDeleted) {
      let OfferData = deleteModelState.value.data
      if(deleteModelState.value.collectedData) deleteTriggerConfirmation(deleteModelState.value.collectedData)
      setDeleteModelState({model:false,value:null,isDeleted:false}) 
      onDelete(OfferData)
    }
  }, [deleteModelState]);

  const onCloseModel = () =>{
    setDeleteModelState({model:false,value:null,isDeleted:false}) 
    setTriggersLoader(false)
    onClose
  }

  const handleDeleteModel = () =>{
    setDeleteModelState({model:true,value:deleteModelState.value,isDeleted:true})
  }

  const showWarning = useCallback(async(getData)=>{
      setTriggersLoader(true)
      const collectedData = await deleteTriggersForOffers({offerId:getData.id , offerName:getData.name,selectedTemplate:selectedTemplate?.name,selectedTemplateId:selectedTemplate?.id})
      setTriggersLoader(false)
      if(collectedData && collectedData?.length !== 0){
        setDeleteModelState({model:true,value:{collectedData,data:getData}})
        return
      }else{
        setDeleteModelState({model:false,value:null,isDeleted:false}) 
      }
      if(!setDeleteModelState.model){
        onDelete(getData)
      }
  })

  const intentOptions = useMemo(() => {
    if (!Array.isArray(intentGroups?.intent_groups)) {
      return [];
    }

    const ed = getDataForIndeterminateCheckbox(intentGroups?.intent_groups);
    return ed;
  }, [intentGroups]);

  const audienceOptions = useMemo(() => {
    if (!Array.isArray(audiences)) {
      return [];
    }

    const tempAudience = audiences.map((a) => ({
      label: a?.name,
      value: a?.id,
    }));

    return tempAudience;
  }, [audiences]);

  const audienceMap = useMemo(
    () =>
      createMap({
        options: audienceOptions,
      }),
    [audienceOptions]
  );

  const colDefs = useMemo(() => {
    const cols = columns({
      onEditClick,
      onDelete,
      audienceMap,
      showWarning,
    });

    return cols;
  }, [onEditClick, onDelete, audienceMap]);

  const onCloseEditMode = useCallback(() => {
    setFieldDefaultEditDrawer(false);
  }, []);

  const onSave = useCallback(
    (data) => {
      const temp = cloneDeep(data);
      const id = temp?.id;
      delete temp.id;

      setLoading(true);
      const promise = id
        ? updateFieldDefault(temp, id)
        : createFieldDefault(temp);
      promise
        .then((res) => {
          if (res) {
            loadAndSetFieldDefaults();
            onCloseEditMode();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [onCloseEditMode]
  );

  useEffect(() => {
    if (isuseEffectTriggered.current) {
      return;
    }

    isuseEffectTriggered.current = true;

    if (isEmpty(intentGroups)) {
      getIntent().then((res) => {
        if (res) {
          dispatch(setData({ key: keys.INTENT_GROUPS, data: res }));
        }
      });
    }

    if (isEmpty(audiences)) {
      getAudiences().then((res) => {
        dispatch(setData({ key: keys.AUDIENCE, data: res }));
      });
    }
  }, [intentGroups, audiences]);

  // sortable drag & drop configs start
  const [isFilterSortable, setIsFilterSortable] = useState(false);
  const [reOrderedList, setReOrderList] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const onOfferOrderSave = useCallback(() => {
    setIsloading(true);
    const payload = reOrderedList.map((item) => {
      const { position, properties, ...rest } = item;
      return {
        ...rest,
        properties: {
          ...properties,
          position,
        }
      };
    });
    bulkUpdateOffer(payload).then((res) => {
      if (res) {
        setIsloading(false);
      }
    });
  //  API Call required to put the updated index in respective filter table
  }, [reOrderedList]);
  const sortableConfig = {
    isSortable: isFilterSortable,
    showReorder: false,
    onToggleSortable: setIsFilterSortable,
    onOrderChange: setReOrderList,
    onOrderSave: onOfferOrderSave,
    btnTitle: { toggleOff: 'Reorder', toggleOn: 'Save Order', isLoading },
  };
  // sortable drag & drop configs end

  return (
    <div style={{ paddingTop: '15px' }} className="pt-5">
      {fieldDefaultEditDrawer ? (
        <EditFieldDefault
          currentFieldDefault={selectedFieldDefault.current}
          onCancel={onCloseEditMode}
          OfferList={fieldDefaults.filter(({ id }) => id !== selectedFieldDefault.current?.id)}
          onSave={onSave}
          isLoading={loading}
          audienceMap={audienceMap}
          audience={audienceOptions}
          selectedTemplate={selectedTemplate}
          intentOptions={intentOptions}
        />
      ) : (
        <TableViewWithSearch
          placeholder="Search for offer"
          buttonText="Add New Offer"
          buttonVariant="solid"
          isTableLoading={isOfferListLoading}
          onCreateNew={onCreateClick}
          columns={colDefs}
          sortableConfig={sortableConfig}
          tableData={fieldDefaults || []}
          title="Offers"
        />
      )}
      <WarningTriggerModel  isOpen={triggersLoader || deleteModelState.model } triggersLoader={triggersLoader}  onClose={onCloseModel} handleDeleteModel={handleDeleteModel}/>
    </div>
  );
};

export default MasterFieldDefaults;
