import React from 'react';
import { Box, Checkbox, useColorModeValue, Text } from '@chakra-ui/react';
import { COLORS } from '../../../utils/enums/colors';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { getWholeOption, getWholeOptionHTML } from '../../TicketTemplateWorkflow/helper';
import QuillEditor from '../../../components/QuillEditor';
import {
  EMAIL_QUILL_TOOLBAR,
  EMAIL_TEMPLATE_KEY_MAP,
  parseAPIToCustomMention,
  parseAPIToRichTextMention,
  SEND_AS_OPTIONS,
} from '../../CommunicationTemplates/utills';
import CustomMention from '../../../components/Mention';
import { getStatusObject } from '../../DynamicRenderer/AddEditSignatureDrawer/utils';
import HTMLPreviewModal from '../../../components/PreviewModal/HTMLPreviewModal';
import { NOTES, convertMentionToHTML } from '../../../components/Mention/helpers';
import CustomInput from '../../../components/InputBox/Input';

const renderEmailForm = (
  actionToPerform,
  handleChange,
  handleChanges,
  actionToPerformSelectedIndex,
  handleActionErrorData,
  actionErrorData,
  getDataForEmail,
  openPreviewModal,
  isModalOpen,
  closePreviewModal,
  mentionObj,
  linkedRelationItemId,
  selectedTemplate,
  actorFieldOptions,
  queueFieldOptions,
  showExecuteFrequency,
  executeFrequencyOptions
) => {
  const options = [
    { label: ' ', value: 'tenant_default_data' },
    { label: 'Queue Or Actor', value: 'queue_or_actor' },
    { label: 'Others', value: 'others' },
  ];

  const forAllTicketsMentionSuggestionList = [
    {id: 'tickets.count', display: 'Tickets count'}
  ]

  return (
    <>
      <Box marginTop="10px" data-testid="DynamicRendererSendMailBoxFromAlias">
        <CustomSelectBox
          label="From Alias"
          options={options}
          value={getWholeOption(actionToPerform?.from?.alias, options)}
          onChange={(e) => {
            handleChange(
              e.value,
              `action_to_perform[${actionToPerformSelectedIndex}].from.alias`
            );
            handleActionErrorData('from.alias');
          }}
          isError={!!actionErrorData?.from}
          errorMessage={actionErrorData?.from || ''}
          isMandatory={false}
          id="DynamicRenderer6DivCInput"
          linkedItemId={linkedRelationItemId}
        />
      </Box>
      {actionToPerform?.from?.alias === 'queue_or_actor' && (
        <>
          <Box
            marginTop="10px"
            data-testid="DynamicRendererSendMailBoxFromAlias"
          >
            <CustomSelectBox
              label="Actor"
              options={actorFieldOptions}
              value={getWholeOption(actionToPerform?.from?.actor, actorFieldOptions)}
              onChange={(e) => {
                handleChange(
                  e.value,
                  `action_to_perform[${actionToPerformSelectedIndex}].from.actor`
                );
                handleActionErrorData('from.actor');
              }}
              isError={!!actionErrorData?.from?.actor}
              errorMessage={actionErrorData?.from?.actor || ''}
              isMandatory={false}
              id="DynamicRenderer6DivCInput"
              linkedItemId={linkedRelationItemId}
            />
          </Box>
          <Box
            marginTop="10px"
            data-testid="DynamicRendererSendMailBoxFromAlias"
          >
            <CustomSelectBox
              label="Queue"
              options={queueFieldOptions}
              value={getWholeOption(actionToPerform?.from?.queue, queueFieldOptions)}
              onChange={(e) => {
                handleChange(
                  e.value,
                  `action_to_perform[${actionToPerformSelectedIndex}].from.queue`
                );
                handleActionErrorData('from.queue');
              }}
              isError={!!actionErrorData?.from?.queue}
              errorMessage={actionErrorData?.from?.queue || ''}
              isMandatory={false}
              id="DynamicRenderer6DivCInput"
              linkedItemId={linkedRelationItemId}
              mentions={true}
            />
          </Box>
        </>
      )}
      {actionToPerform?.from?.alias === 'others' && (
        <Box
          marginTop="10px"
          data-testid="DynamicRendererSendMailBoxFromOthers"
        >
          <CustomInput
            label="Others"
            value={actionToPerform?.from?.other}
            onChange={(e) => {
              handleChange(
                e.target.value,
                `action_to_perform[${actionToPerformSelectedIndex}].from.other`
              );
              handleActionErrorData('from.other');
            }}
            isError={!!actionErrorData?.from?.others}
            errorMessage={actionErrorData?.from?.others || ''}
            isMandatory={false}
            id="DynamicRenderer6DivCInput"
            linkedItemId={linkedRelationItemId}
          />
        </Box>
      )}
      {showExecuteFrequency && (
        <Box marginTop="10px" data-testid="">
          <CustomSelectBox
            label="Execute Frequency"
            options={executeFrequencyOptions}
            value={executeFrequencyOptions.find(
              op => op.value === (actionToPerform.forAllTickets || false)
            )}
            onChange={(e) => {
              handleChange(
                e.value,
                `action_to_perform[${actionToPerformSelectedIndex}].forAllTickets`
              );
              handleActionErrorData('forAllTickets');
            }}
            isError={!!actionErrorData?.forAllTickets}
            errorMessage={actionErrorData?.forAllTickets || ''}
            isMandatory={false}
            id="DynamicRenderer6DivCInput"
            linkedItemId={linkedRelationItemId}
          />
        </Box>
      )}
      <Box marginTop="10px" data-testid="DynamicRendererSendMailBox">
        <CustomMention
          label="To"
          value={actionToPerform.to}
          onChange={(e) => {
            handleChange(
              e,
              `action_to_perform[${actionToPerformSelectedIndex}].to`
            );
            handleActionErrorData('to');
          }}
          isError={!!actionErrorData?.to}
          // errorMessage={actionErrorData?.to || ''}
          isMandatory={true}
          id="DynamicRenderer6DivCInput"
          linkedItemId={linkedRelationItemId}
          showQueueAndActorEmailOnly
        />
        {actionErrorData?.to && (
    <Text color="red.500" fontSize="12px" marginTop="4px">
      {actionErrorData.to}
    </Text>
      )}
      </Box>
      <Box marginTop="10px" data-testid="DynamicRendererSendMailBoxCc">
        <CustomMention
          label="Cc"
          value={actionToPerform.cc}
          onChange={(e) => {
            handleChange(
              e,
              `action_to_perform[${actionToPerformSelectedIndex}].cc`
            );
            handleActionErrorData('cc');
          }}
          isError={!!actionErrorData?.cc}
          errorMessage={actionErrorData?.cc || ''}
          isMandatory={false}
          id="DynamicRenderer6DivCInput"
          linkedItemId={linkedRelationItemId}
          showQueueAndActorEmailOnly
        />
      </Box>
      <Box marginTop="10px" data-testid="DynamicRendererSendMailBoxBcc">
        <CustomMention
          label="Bcc"
          value={actionToPerform.bcc}
          onChange={(e) => {
            handleChange(
              e,
              `action_to_perform[${actionToPerformSelectedIndex}].bcc`
            );
            handleActionErrorData('bcc');
          }}
          isError={!!actionErrorData?.bcc}
          errorMessage={actionErrorData?.bcc || ''}
          isMandatory={false}
          id="DynamicRenderer6DivCInput"
          linkedItemId={linkedRelationItemId}
          showQueueAndActorEmailOnly
        />
      </Box>
      <Box marginTop="10px" data-testid="DynamicRendererSendMailSujectBox">
        <CustomMention
          label="Subject"
          value={actionToPerform.subject}
          onChange={(e) => {
            handleChange(
              e,
              `action_to_perform[${actionToPerformSelectedIndex}].subject`
            );
            handleActionErrorData('subject');
          }}
          isError={!!actionErrorData?.subject}
          // errorMessage={actionErrorData?.subject || ''}
          isMandatory={true}
          id="DynamicRenderer7DivCInput"
          linkedItemId={linkedRelationItemId}
          mentions
          suggestionList={actionToPerform.forAllTickets ? forAllTicketsMentionSuggestionList : null}
        />
         {actionErrorData?.subject && (
    <Text color="red.500" fontSize="12px" marginTop="4px">
      {actionErrorData.subject}
    </Text>
      )}
      </Box>
      <Box marginTop="10px" data-testid="DynamicRendererSendMailV2Box">
        <CustomSelectBox
          options={getDataForEmail()}
          value={getWholeOption(
            actionToPerform.emailTemplateId,
            getDataForEmail(),
          )}
          onChange={(e) => {
            debugger
            const events = [
              {
                value:
                  e?.send_as === 'html'
                    ? parseAPIToCustomMention(e?.emailBody)
                    : parseAPIToRichTextMention(e?.emailBody),
                path: `action_to_perform[${actionToPerformSelectedIndex}].template`,
              },
              {
                value:e?.value,
                path: `action_to_perform[${actionToPerformSelectedIndex}].emailTemplateId`,
              },
              {
                value:
                  e?.send_as === 'html'
                    ? parseAPIToCustomMention(e?.emailSignature)
                    : parseAPIToRichTextMention(e?.emailSignature),
                path: `action_to_perform[${actionToPerformSelectedIndex}].signature`,
              },
              {
                value: e?.subject,
                path: `action_to_perform[${actionToPerformSelectedIndex}].subject`,
              },
              {
                value: e?.send_as,
                path: `action_to_perform[${actionToPerformSelectedIndex}].send_as`,
              },
            ];
            handleChanges(events);
            handleActionErrorData('template');
          }}
          label="Email Template"
          //isMandatory={true}
          isClearable={true}// Enable the clear option here
          isError={!!actionErrorData?.template}
          errorMessage={actionErrorData?.template || ''}
          aria-label="emailTemplate"
        />
      </Box>
      <Box mt="10px">
        <CustomSelectBox
          value={getStatusObject(
            actionToPerform[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] || 'text',
            SEND_AS_OPTIONS
          )}
          options={SEND_AS_OPTIONS}
          onChange={(e) => {
            handleChange(
              e.value,
              `action_to_perform[${actionToPerformSelectedIndex}].send_as`
            );
            handleActionErrorData('send_as');
          }}
          label="Send as"
          aria-label="send-as"
          isDisabled={actionToPerform.template || actionToPerform.signature}
        />
      </Box>
      {actionToPerform.send_as === 'html' ? (
        <Box mt="20px" w="100%">
          <CustomMention
            label="Email body"
            value={actionToPerform.template || ''}
            onChange={(value) => {
              handleChange(
                value,
                `action_to_perform[${actionToPerformSelectedIndex}].template`
              );
              handleActionErrorData('template');
            }}
            placeholder="Email Body"
            height="260px"
            showHardCodMention={[NOTES]}
            isMulti
            multiLine
            suggestionList={actionToPerform.forAllTickets ? forAllTicketsMentionSuggestionList : null}
          />
          {/* {actionErrorData?.template ? (
            <span className="field-error">{actionErrorData?.template}</span>
          ) : null} */}
        </Box>
      ) : (
        <Box
          marginTop="10px"
          data-testid="DynamicRendererSendMailV2TextAreaBox"
        >
          <QuillEditor
            editorvalue={actionToPerform.template?.replace(/\n/g, '<br>')}
            toolBarModules={EMAIL_QUILL_TOOLBAR}
            format=""
            // customEditorContainerStyles={{ height: '120px' }}
            handleOnChange={(value) => {
              handleChange(
                value,
                `action_to_perform[${actionToPerformSelectedIndex}].template`
              );
              handleActionErrorData('template');
            }}
            label="Email Body"
            testId="AddOrEditEmailTemplateDrawerQuillEditor"
            mentionObj={mentionObj}
            showClearButton
          />
          {/* {actionErrorData?.template ? (
            <span className="field-error">{actionErrorData?.template}</span>
          ) : null} */}
        </Box>
      )}

      <Box marginRight="10px" data-testid="DynamicRendererSendMailBtnBox">
        <CustomButton
          buttonText="Preview"
          variant="outline"
          colorScheme="customBlue"
          style={{
            height: 30,
            marginTop: '8px',
          }}
          color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
          onClick={openPreviewModal}
          id="DynamicRendererSendMailPreviewBtnText"
        />
        <HTMLPreviewModal
          open={isModalOpen}
          closePreviewModal={closePreviewModal}
          title="Email Preview"
          content={
            isModalOpen
              ? [
                {
                  label: 'Email Body',
                  html:
                      actionToPerform.send_as === 'html'
                        ? convertMentionToHTML(
                          actionToPerform.template,
                          selectedTemplate
                        )
                        : actionToPerform.template,
                },
                {
                  label: 'Email Signature',
                  html:
                      actionToPerform.send_as === 'html'
                        ? convertMentionToHTML(
                          actionToPerform.signature,
                          selectedTemplate
                        )
                        : actionToPerform.signature,
                },
              ]
              : []
          }
        />
      </Box>
      {actionToPerform.send_as === 'html' ? (
        <Box mt="20px" w="100%">
          <CustomMention
            label="Email Signature"
            value={actionToPerform.signature || ''}
            onChange={(value) => {
              handleChange(
                value,
                `action_to_perform[${actionToPerformSelectedIndex}].signature`
              );
              handleActionErrorData('signature');
            }}
            placeholder="Email Signature"
            height="260px"
            isMulti
            multiLine
          />
        </Box>
      ) : (
        <Box mt="20px" w="100%">
          <QuillEditor
            editorvalue={actionToPerform.signature}
            toolBarModules={EMAIL_QUILL_TOOLBAR}
            format=""
            // customEditorContainerStyles={{ height: '120px' }}
            handleOnChange={(value) => {
              handleChange(
                value,
                `action_to_perform[${actionToPerformSelectedIndex}].signature`
              );
              handleActionErrorData('signature');
            }}
            label="Email Signature"
            placeholder="Email Signature"
            testId="AddOrEditEmailTemplateDrawerQuillEditor"
            mentionObj={mentionObj}
            showClearButton
          />
        </Box>
      )}
    </>
  );
};

export default renderEmailForm;
