import React from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import CustomButton from './SimpleButton';
import { BLACK, WHITE } from '../../utils/enums/colors';

const sharedPropTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

const sharedDefaultProps = {
  id: '',
  isLoading: false,
  isDisabled: false,
};

export const PrimaryCustomButton = (props) => {
  const { onClick, isDisabled, buttonText, isLoading, id } = props;
  return (
<CustomButton
  classname="calculatedHeight"
  pl="25px"
  pr="25px"
  variant="solid"
  buttonColor="customBlue"
  buttonText={buttonText}
  isDisabled={isDisabled}
  onClick={onClick}
  size="md"
  isLoading={isLoading}
  id={id}
  {...props}
/>
  );
};

PrimaryCustomButton.propTypes = sharedPropTypes;
PrimaryCustomButton.defaultProps = sharedDefaultProps;

export const SecondaryCustomButton = (props) => {
  const { onClick, isDisabled, buttonText, isLoading, id,minHeight } = props;
  return (
<CustomButton
  color={useColorModeValue(BLACK, WHITE)}
  pl="25px"
  pr="25px"
  onClick={onClick}
  buttonText={buttonText}
  isDisabled={isDisabled}
  _hover={{
    background: 'white',
    color: 'black',
    border: '1px solid #f2f3f3',
  }}
  variant="outline"
  isLoading={isLoading}
  className="calculatedHeight"
  minHeight={minHeight}
  id={id}
  {...props}
/>
  );
};

SecondaryCustomButton.propTypes = sharedPropTypes;
SecondaryCustomButton.defaultProps = sharedDefaultProps;

export const DangerCustomButton = (props) => {
  const { onClick, isDisabled, buttonText, isLoading, id } = props;
  return (
<CustomButton
  id={id}
  variant="solid"
  pl="5px"
  pr="5px"
  buttonColor="red"
  isDisabled={isDisabled}
  buttonText={buttonText}
  onClick={onClick}
  _hover={{
    backgroundColor: 'red',
    color: 'white',
  }}
  size="lg"
  minWidth="115px"
  isLoading={isLoading}
  classname="calculatedHeight"
  {...props}
/>
  );
};

DangerCustomButton.propTypes = sharedPropTypes;
DangerCustomButton.defaultProps = sharedDefaultProps;
